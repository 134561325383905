import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { verifyLogin } from "./actions";
import { Redirect } from "react-router-dom";
import { CodeInput } from "react-rainbow-components";
import ButtonPrimary from "shared/ButtonPrimary";
import cx from "classnames";
import styles from "./VerifyLogin.module.scss";

const VerifyLogin = () => {
  const email = useSelector(s => s.auth.email);
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [error, setError] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [redirect, setRedirect] = useState();

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);
    dispatch(
      verifyLogin({
        email: email,
        code: code
      })
    ).then(
      () => setRedirect("/"),
      err => {
        setError(err);
        setIsSubmitting(false);
      }
    );
  }, [dispatch, email, code]);

  useEffect(() => {
    if (code.length === 6) {
      handleSubmit();
    }
  }, [code, handleSubmit]);

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: redirect
        }}
      />
    );
  }

  if (!email) {
    setRedirect("/");
  }

  return (
    <div>
      <h1 className="gkit-h1">Enter verification code.</h1>
      <p className="gkit-p-17 mt-4">
        A 6 digit code has been sent to {email}, please enter this below.
      </p>
      <CodeInput
        className="mt-4"
        id="login-code"
        value={code}
        hideLabel
        length={6}
        onChange={setCode}
        style={{ textAlign: "left", marginLeft: "-6px", marginRight: "-6px" }}
        error={error ? <p className={styles.error}>{error}</p> : null}
      />
      <div className="d-flex flex-column px-5">
        <ButtonPrimary
          type="submit"
          className="w-100 mt-4"
          isLoading={isSubmitting}
          disabled={code.length !== 6}
          onClick={handleSubmit}
        >
          Continue
        </ButtonPrimary>
        <a className={cx(styles.resend, "mt-4 align-self-center")} href="/">
          Resend verification code email
        </a>
      </div>
    </div>
  );
};

export default VerifyLogin;
