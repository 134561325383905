import Bean from "assets/icons/bean-coloured.svg";
import Pod from "assets/icons/pod-coloured.svg";
import Ground from "assets/icons/ground-coloured.svg";
import { bulkSKUs } from "shared/utility";

const imageMap = {
  "Compostable Nespresso® Pods": Pod,
  "Ground Coffee": Ground,
  "Whole Bean Coffee": Bean
};

class ConfigModel {
  constructor(config) {
    this.variantIdMap = {};
    this.skuMap = {};
    this.formats = this.getFormat(config.formats, {
      countryCode: config.country_code,
      currency: config.currency
    });
  }

  getFormat(formats, extraProps) {
    const format = {};
    formats.forEach((eachFormat, index) => {
      format[index] = this.structureFormat(eachFormat, index, extraProps);
    });
    return format;
  }

  structureFormat(format, index, extraProps) {
    return {
      popular: index === 0,
      blendExplanation: String(format.name).includes("Pod") ? "pod" : "bean", // used to pick the right "read more blend type"
      id: index,
      name: format.name,
      image: format.image || imageMap[format.name] || "",
      sizes: format.sizes.reduce((a, size, index) => {
        a[index] = this.structureSizes(size, index, format, extraProps);
        return a;
      }, {})
    };
  }

  structureSizes(size, index, format, extraProps) {
    let mixnmatch = null;

    const giftSizes = size.presets.filter(
      preset => bulkSKUs.indexOf(preset.item?.sku) === -1
    );

    const presets = size.presets.reduce((a, preset, index) => {
      // check if variants and options exist, then if yes it is implied to be a mix and match
      if (preset.variants && preset.options) {
        mixnmatch = {
          options: preset.options,
          variantTitleMap: preset.variants.reduce((c, d) => {
            c[d.options.sort().join("-")] = {
              product_id: d.product_id,
              variant_id: d.variant_id
            };
            return c;
          }, {}),
          variantIdMap: preset.variants.reduce((e, f) => {
            e[f.variant_id] = f.options;
            return e;
          }, {}),
          skuMap: preset.variants.reduce((e, f) => {
            e[f.sku] = f.options;
            return e;
          }, {})
        };
        // add to variantIdMap
        preset.variants.forEach(eachVariant => {
          if (!eachVariant.options.every((val, i, arr) => val === arr[0])) {
            const variantSummary = {
              format,
              size,
              item: {
                ...eachVariant,
                blendExplanation: String(format.name).includes("Pod")
                  ? "pod"
                  : "bean"
              }
            };

            this.variantIdMap[eachVariant.variant_id] = variantSummary;
            this.skuMap[eachVariant.sku] = variantSummary;
          }
        });
      } else {
        // add to variantIdMap
        a.push({
          ...preset,
          id: preset.item.variant_id,
          popular: index === 0
        });
        const variantSummary = {
          format,
          size,
          item: {
            blendExplanation: String(format.name).includes("Pod")
              ? "pod"
              : "bean",
            ...preset.item,
            title: preset.title
          }
        };
        this.variantIdMap[preset.item.variant_id] = variantSummary;
        this.skuMap[preset.item.sku] = variantSummary;
      }
      return a;
    }, []);

    return {
      id: index,
      presets,
      mixnmatch,
      popular: index === 0,
      metadata: {
        description: size.description,
        image: size.image,
        price: size.price,
        subtitle: size.subtitle,
        title: size.title,
        ...extraProps
      },
      gifts: giftSizes
    };
  }
}

export default ConfigModel;
