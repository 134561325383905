import { LOGOUT } from "app/auth/actionTypes";
import { handleActions } from "redux-actions";
import {
  CLAIM_REWARD_SUCCESS,
  CREATE_LOYALTY_ACCOUNT_SUCCESS,
  FETCH_LOYALTY_SUCCESS
} from "./actionTypes";

export const initialState = {
  account: null,
  rewards: null,
  thirdPartyRewards: null
};

export default handleActions(
  {
    [LOGOUT]: () => initialState,
    [FETCH_LOYALTY_SUCCESS]: (state, { payload }) => ({
      ...state,
      account: payload.account || false,
      rewards: payload.rewards || []
    }),
    [CREATE_LOYALTY_ACCOUNT_SUCCESS]: (state, { payload }) => ({
      ...state,
      account: payload
    }),
    [CLAIM_REWARD_SUCCESS]: (state, { payload }) => ({
      ...state,
      account: {
        ...state.account,
        points: payload.points_remaining.earned
      }
    })
  },
  initialState
);
