import React from "react";

import styles from "./ToggleSwitch.module.scss";

const ToggleSwitch = ({ name, title, subtitle, className = "", ...props }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.labelContainer}>
        <p className="gkit-p-17 gkit-bold m-0 mb-2">{title}</p>
        <p className="gkit-p-15 gkit-light-1 m-0">{subtitle}</p>
      </div>
      <div className={styles.switchContainer}>
        <input
          type="checkbox"
          {...props}
          id={name}
        />
        <label htmlFor={name}>Toggle</label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
