import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button, { ButtonIcon } from "./Button";
import TrashIcon from "assets/icons/trash.svg";
import { updateAddress, deleteAddress } from "app/account/actions";
import UpdateAddressModal from "./modals/UpdateAddressModal";
import DeleteModal from "./modals/DeleteModal";
import ChangeAddressModal from "./modals/ChangeAddressModal";
import {
  updateSubscriptionAddress,
  updateSubscriptionBillingAddress
} from "app/subscriptions/actions";
import CreateAddressModal from "./modals/CreateAddressModal";
import { stackedAddress } from "./utility";

const Address = ({
  subscriptionId,
  address,
  allowDelete = false,
  allowChange = false,
  showDefault = false,
  allowEdit = true,
  sameAsShipping = false,
  isBilling = false
}) => {
  const [modal, setModal] = useState(null);
  const dispatch = useDispatch();
  const {
    auth: { user },
    config: { countries }
  } = useSelector(s => s);

  const addressDisplay = useMemo(
    () => stackedAddress({ countries: countries, ...address }),
    [address, countries]
  );

  return (
    <>
      <div className="d-flex">
        <div className="me-4">
          <p className="gkit-p-17 m-0">
            {sameAsShipping ? "Same as delivery address" : addressDisplay}
          </p>
        </div>
        {address.default && showDefault && (
          <div>
            <span className="gkit-badge badge gkit-secondary-bordered float-right">
              Default
            </span>
          </div>
        )}
      </div>
      {allowEdit && (
        <div className="d-flex mt-3 mb-4 flex-column flex-md-row">
          <Button
            btnType="tertiary"
            className="my-1 my-md-0 me-md-3"
            onClick={() => setModal("UpdateAddressModal")}
          >
            Edit address
          </Button>
          {!address.default && showDefault && (
            <Button
              btnType="tertiary"
              className="my-1 my-md-0 me-md-3"
              onClick={() =>
                dispatch(updateAddress(address.id, { default: true }))
              }
            >
              Make default
            </Button>
          )}
          {allowChange && (
            <Button
              btnType="tertiary"
              className="me-3 my-1 my-md-0"
              onClick={() => setModal("ChangeAddressModal")}
            >
              Change address
            </Button>
          )}
          {allowDelete && (
            <ButtonIcon
              icon={TrashIcon}
              onClick={() => setModal("DeleteModal")}
            />
          )}
        </div>
      )}
      {modal === "UpdateAddressModal" && (
        <UpdateAddressModal
          isOpen={modal === "UpdateAddressModal"}
          address={address}
          subscriptionId={subscriptionId}
          handleClose={() => setModal(null)}
          isBilling={isBilling}
        />
      )}
      {allowChange && (
        <ChangeAddressModal
          isOpen={modal === "ChangeAddressModal"}
          handleClose={() => setModal(null)}
          handleAction={payload =>
            isBilling
              ? dispatch(
                  updateSubscriptionBillingAddress(subscriptionId, payload)
                )
              : dispatch(updateSubscriptionAddress(subscriptionId, payload))
          }
          handleAddNewAddress={() => setModal("CreateAddressModal")}
          isBilling={isBilling}
          countryCode={address.country_code}
        />
      )}
      {allowDelete && modal === "DeleteModal" && (
        <DeleteModal
          isOpen={modal === "DeleteModal"}
          item="Address"
          handleClose={() => setModal(null)}
          handleAction={() => deleteAddress(address.id)}
        />
      )}
      {modal === "CreateAddressModal" && (
        <CreateAddressModal
          isOpen={modal === "CreateAddressModal"}
          handleClose={() => setModal(null)}
          {...user}
          onAddressAdded={payload => {
            dispatch(
              isBilling
                ? updateSubscriptionBillingAddress(subscriptionId, payload)
                : updateSubscriptionAddress(subscriptionId, payload)
            ).then(() => setModal(null));
          }}
          isBilling={isBilling}
          countryCode={address.country_code}
        />
      )}
    </>
  );
};

export default Address;
