export const FETCH_LOYALTY = "FETCH_LOYALTY";
export const FETCH_LOYALTY_SUCCESS = "FETCH_LOYALTY_SUCCESS";
export const FETCH_LOYALTY_FAILURE = "FETCH_LOYALTY_FAILURE";

export const FETCH_LOYALTY_REWARD = "FETCH_LOYALTY_REWARD";
export const FETCH_LOYALTY_REWARD_SUCCESS = "FETCH_LOYALTY_REWARD_SUCCESS";
export const FETCH_LOYALTY_REWARD_FAILURE = "FETCH_LOYALTY_REWARD_FAILURE";

export const FETCH_THIRD_PARTY_REWARDS = "FETCH_THIRD_PARTY_REWARDS";
export const FETCH_THIRD_PARTY_REWARDS_SUCCESS =
  "FETCH_THIRD_PARTY_REWARDS_SUCCESS";
export const FETCH_THIRD_PARTY_REWARDS_FAILURE =
  "FETCH_THIRD_PARTY_REWARDS_FAILURE";

export const CREATE_LOYALTY_ACCOUNT = "CREATE_LOYALTY_ACCOUNT";
export const CREATE_LOYALTY_ACCOUNT_SUCCESS = "CREATE_LOYALTY_ACCOUNT_SUCCESS";
export const CREATE_LOYALTY_ACCOUNT_FAILURE = "CREATE_LOYALTY_ACCOUNT_FAILURE";

export const CLAIM_REWARD = "CLAIM_REWARD";
export const CLAIM_REWARD_SUCCESS = "CLAIM_REWARD_SUCCESS";
export const CLAIM_REWARD_FAILURE = "CLAIM_REWARD_FAILURE";
