import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import cx from "classnames";

const GenericModal = ({ className, children, onCleanup, onClose, show }) => {
  const [showInternally, setShowInternally] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    if (show) {
      const preSetClassName = document.body.className;
      const preSetOverflow = document.body.style.overflow;

      document.body.classList.add("modal-open");
      document.body.style.overflow = "hidden";

      return () => {
        document.body.className = preSetClassName;
        document.body.style.overflow = preSetOverflow;
      };
    }
  }, [show]);

  useEffect(() => {
    if (show && !showInternally) {
      setShowInternally(true);
    } else if (showInternally && !show) {
      setTimeout(() => {
        setShowInternally(false);
        onCleanup?.();
      }, 500);
    }
  }, [onCleanup, show, showInternally]);

  if (!(show || showInternally)) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <div
        className={cx("modal fade", show && showInternally && "show")}
        onClick={e => {
          if (e.target === modalRef.current) {
            onClose();
            console.log("Backdrop Clicked");
          }
        }}
        tabIndex="-1"
        ref={modalRef}
        role="dialog"
        style={show || showInternally ? { display: "block" } : undefined}
      >
        <div className={cx("modal-dialog", className)}>
          <div className="modal-content">{children}</div>
        </div>
      </div>

      <div
        className={cx("modal-backdrop fade", show && showInternally && "show")}
      />
    </>,
    document.getElementById("modal-root")
  );
};

export default GenericModal;
