import {
  SET_PAYMENT_PROVIDERS,
  SET_PLAN_CONFIG,
  SET_COUNTRIES
} from "./actionTypes";
import { handleActions } from "redux-actions";
import ConfigModel from "./ConfigModel.js";

const initialState = {
  paymentProviders: null,
  planConfigs: {},
  countries: null
};

export default handleActions(
  {
    [SET_PAYMENT_PROVIDERS]: (state, { payload }) => {
      return {
        ...state,
        paymentProviders: payload
      };
    },
    [SET_PLAN_CONFIG]: (state, { payload }) => {
      const model = new ConfigModel(payload);
      return {
        ...state,
        planConfigs: {
          ...state.planConfigs,
          [payload.country_code]: {
            products: payload,
            formats: model.formats,
            variantIdMap: model.variantIdMap,
            skuMap: model.skuMap
          }
        }
      };
    },
    [SET_COUNTRIES]: (state, { payload }) => {
      return {
        ...state,
        countries: payload
      };
    }
  },
  initialState
);
