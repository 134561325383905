import React from "react";
import { Input } from "react-rainbow-components";
import { useField } from "formik";

const InputField = ({ className = "", children, isInvalid, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Input
      labelAlignment="left"
      className={`react-rainbow-components ${className}`}
      error={isInvalid || (meta && meta.touched && meta.error)}
      {...field}
      {...props}
    >
      {children}
    </Input>
  );
};

export default InputField;
