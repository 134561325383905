import React, { Fragment, useState, useMemo } from "react";
import ChevronDown from "assets/icons/chevron-down.svg";
import ChevronUp from "assets/icons/chevron-up.svg";
import EmptyCard from "assets/icons/empty-card.svg";
import EmptyCalendar from "assets/icons/empty-calendar.svg";
import ShippingInfo from "./ShippingInfo";
import moment from "moment";
import { CreditCardImages } from "./ListPaymentOptions";
import CardBlank from "assets/icons/payment-blank.svg";
import InvoiceIcon from "assets/icons/invoice.svg";
import { priceFormatter } from "./utility";

const LastDeliveryInfo = ({ orderDate, payment, countryCode }) => {
  const [opened, setOpened] = useState(false);

  const paymentTitle = useMemo(() => {
    if (!payment || !payment.payment_method) {
      return null;
    }
    switch (payment.payment_method.type) {
      case "stripe":
        return "Card used";
      case "paypal":
        return "PayPal payment";
      case "apple":
        return "Apple payment";
      default:
        return payment.payment_method.type;
    }
  }, [payment]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <p className="gkit-p-17 fw-bold mb-0">Your last delivery</p>
        <img
          src={opened ? ChevronUp : ChevronDown}
          alt={"Last delivery chevron"}
          width={18}
          className="cursor-pointer"
          onClick={() => setOpened(!opened)}
        />
      </div>
      {opened && (
        <div className="bg-white border rounded mt-3 px-3">
          <ShippingInfo
            icon={EmptyCalendar}
            title="Order date"
            info={moment(orderDate).format("DD/MM/YY")}
          />
          {payment && (
            <Fragment>
              <ShippingInfo
                icon={EmptyCard}
                title={paymentTitle}
                info={
                  payment.payment_method.card ? (
                    <div className="d-flex">
                      <img
                        className="me-2 d-none d-md-block"
                        src={
                          CreditCardImages.Regular[
                            payment.payment_method.card.brand
                          ] || CardBlank
                        }
                        alt="payment card type"
                      />
                      <span>•••• {payment.payment_method.card.last4}</span>
                    </div>
                  ) : (
                    ""
                  )
                }
              />
              <ShippingInfo
                icon={InvoiceIcon}
                title="Amount charged"
                info={priceFormatter({
                  price: payment.captured?.amount,
                  countryCode: countryCode,
                  currency: payment.captured?.currency
                })}
              />
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default LastDeliveryInfo;
