import {
  SET_PREFERENCES,
  SET_ADDRESSES,
  UPDATE_ADDRESSES_STATE,
  ADD_ADDRESS_TO_STATE,
  DELETE_ADDRESS_FROM_STATE,
  SET_PAYMENT_METHODS,
  UPDATE_PAYMENT_METHODS_STATE,
  ADD_PAYMENT_METHOD_TO_STATE,
  DELETE_PAYMENT_METHOD_FROM_STATE,
  SET_REFERRAL_DATA,
  SET_PENDING_GIFT,
  SET_GIFT_DATA,
  CLAIM_GIFT,
  REDEEM_GIFT
} from "./actionTypes";
import { handleActions } from "redux-actions";

export const initialState = {
  marketing: {},
  addresses: null,
  paymentMethods: null,
  referralData: null,
  gifts: null,
  pendingGift: null
};

export default handleActions(
  {
    [SET_PREFERENCES]: (state, { payload }) => {
      return {
        ...state,
        marketing: payload
      };
    },
    [SET_ADDRESSES]: (state, { payload }) => {
      return {
        ...state,
        addresses: payload
      };
    },
    [ADD_ADDRESS_TO_STATE]: (state, { payload }) => {
      return {
        ...state,
        addresses: [...state.addresses, payload]
      };
    },
    [UPDATE_ADDRESSES_STATE]: (state, { payload }) => {
      const addressIdx = state.addresses.findIndex(
        (address) => address.id === payload.id
      );
      let newAddresses = [...state.addresses];
      newAddresses.splice(addressIdx, 1, payload);

      if (
        payload.default &&
        newAddresses.length > 1 &&
        !state.addresses[addressIdx].default
      ) {
        // find the one with default and set default to false
        const defaultIdx = state.addresses.findIndex(
          (address) => address.default === true
        );
        newAddresses = [...newAddresses];
        newAddresses.splice(defaultIdx, 1, {
          ...state.addresses[defaultIdx],
          default: false
        });
      }

      return {
        ...state,
        addresses: newAddresses
      };
    },
    [DELETE_ADDRESS_FROM_STATE]: (state, { payload }) => {
      const addressIdx = state.addresses.findIndex(
        (address) => address.id === payload
      );
      const newAddresses = [...state.addresses];
      newAddresses.splice(addressIdx, 1);

      return {
        ...state,
        addresses: newAddresses
      };
    },
    [SET_PAYMENT_METHODS]: (state, { payload }) => {
      return {
        ...state,
        paymentMethods: payload
      };
    },
    [UPDATE_PAYMENT_METHODS_STATE]: (state, { payload }) => ({
      ...state,
      paymentMethods: state.paymentMethods.map((paymentMethod) =>
        paymentMethod.id === payload.id ? payload : paymentMethod
      )
    }),
    [ADD_PAYMENT_METHOD_TO_STATE]: (state, { payload }) => {
      return {
        ...state,
        paymentMethods: [...state.paymentMethods, payload]
      };
    },
    [DELETE_PAYMENT_METHOD_FROM_STATE]: (state, { payload }) => {
      const paymentMethodIdx = state.paymentMethods.findIndex(
        (paymentMethod) => paymentMethod.id === payload
      );
      const newPaymentMethods = [...state.paymentMethods];
      newPaymentMethods.splice(paymentMethodIdx, 1);

      return {
        ...state,
        paymentMethods: newPaymentMethods
      };
    },
    [SET_REFERRAL_DATA]: (state, { payload }) => ({
      ...state,
      referralData: payload
    }),
    [SET_PENDING_GIFT]: (state, { payload }) => ({
      ...state,
      pendingGift: payload
    }),
    [SET_GIFT_DATA]: (state, { payload }) => ({
      ...state,
      gifts: payload
    }),
    [CLAIM_GIFT]: (state, { payload }) => ({
      ...state,
      gifts: [...state.gifts, payload]
    }),
    [REDEEM_GIFT]: (state, { payload }) => {
      const giftIdx = state.gifts.findIndex((gift) => gift.id === payload);
      const newGifts = [...state.gifts];
      newGifts.splice(giftIdx, 1);

      return {
        ...state,
        gifts: newGifts
      };
    }
  },
  initialState
);
