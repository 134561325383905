import React from "react";
import { useField } from "formik";
import cx from "classnames";

import styles from "./InputFieldLine.module.scss";

export default function InputFieldLine({ className, name, ...props }) {
  const [field, meta] = useField(name);

  return (
    <div className={cx(styles.container, className)}>
      <input className={styles.field} {...field} {...props} />
      {meta?.touched && meta?.error ? (
        <p className={cx(styles.errorText, "mt-2")}>{meta.error}</p>
      ) : null}
    </div>
  );
}
