import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { login } from "./actions";
import { Link, Redirect } from "react-router-dom";
import ErrorHandling from "shared/ErrorHandling";

import styles from "./Login.module.scss";
import InputFieldLine from "shared/Formik/InputFieldLine";
import cx from "classnames";
import ButtonPrimary from "shared/ButtonPrimary";
import ButtonSecondary from "shared/ButtonSecondary";

const Login = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [isSubmitting, setSubmitting] = useState(null);
  const email = useSelector(s => s.auth.email);
  const [redirect, setRedirect] = useState();

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: redirect
        }}
      />
    );
  }

  function handleLogin(type, payload) {
    if (isSubmitting) {
      return null;
    }

    setSubmitting(type);
    dispatch(login(payload)).then(
      data => {
        setRedirect(data ? "/" : "/auth/verify-login");
      },
      err => {
        setError(err);
        setSubmitting(null);
      }
    );
  }

  return (
    <div className="d-flex flex-column">
      <h1 className="gkit-h1">Welcome back.</h1>

      <Formik
        initialValues={{
          email: email ? email : "",
          password: ""
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Invalid email format")
            .required("Email address is required"),
          password: Yup.string().required("Password is required")
        })}
        onSubmit={values => {
          handleLogin("legacy", values);
        }}
      >
        {({ values, isValid, dirty }) => {
          return (
            <Form className="mt-4 px-md-3">
              <InputFieldLine name="email" type="email" placeholder="Email *" />
              <InputFieldLine
                name="password"
                type="password"
                placeholder="Password *"
                className="mt-4"
              />
              <ErrorHandling error={error} />
              <div className={styles.buttonContainer}>
                <ButtonPrimary
                  className={cx("d-block w-100")}
                  disabled={!dirty || !isValid}
                  isLoading={isSubmitting === "legacy"}
                  type="submit"
                >
                  Log in
                </ButtonPrimary>
                <ButtonSecondary
                  className={cx("mt-3 d-block w-100")}
                  isLoading={isSubmitting === "passwordless"}
                  onClick={() => {
                    handleLogin("passwordless", { email: values.email });
                  }}
                >
                  Login without password
                </ButtonSecondary>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className="mt-3 align-self-center">
        <Link className={styles.forgotPassword} to="/auth/forgot-password">
          Forgot password?
        </Link>
      </div>
    </div>
  );
};

export default Login;
