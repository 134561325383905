import React from "react";
import OrderHistoryItem from "./OrderHistoryItem";

const OrderHistoryTable = ({ orders }) => {
  return (
    <div className="table-responsive d-none d-sm-block">
      <table className="table">
        <thead className="border-0 w-100">
          <tr className="border-0">
            <th scope="col" className="border-0 ps-0 col-2">
              <p className="gkit-p-15 gkit-bold mb-0">Order</p>
            </th>
            <th scope="col" className="border-0 col-6">
              <p className="gkit-p-15 gkit-bold mb-0">Products</p>
            </th>
            <th scope="col" className="border-0 text-right col-2">
              <p className="gkit-p-15 gkit-bold mb-0">Total</p>
            </th>
            <th scope="col" className="border-0 text-center col-2">
              <p className="gkit-p-15 gkit-bold mb-0">Invoice</p>
            </th>
          </tr>
        </thead>
        <tbody className="w-100">
          {orders.map((order) => (
            <OrderHistoryItem {...order} key={order.order_number} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderHistoryTable;
