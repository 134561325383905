import React, { useState, useEffect } from "react";
import moment from "moment";

import { useSelector, useDispatch } from "react-redux";

import ProductSelector from "./ProductSelector";
import ConfigureSubscription from "./ConfigureSubscription";

import { addAddress, startGiftSubscription } from "app/account/actions";
import SelectDeliveryAddress from "./SelectDeliveryAddress";
import ConfirmNewSubscription from "./ConfirmNewSubscription";
import CreateAddress from "./CreateAddress";

import Modal from "shared/Modal";

const GiftSubscriptionFlow = ({
  id: subscriptionId,
  country_code,
  setRedeemingGift
}) => {
  const [modal, setModal] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [subscription, setSubscription] = useState({
    variant_id: ""
  });
  const [completeError, setCompleteError] = useState(false);
  const [prevModal, setPrevModal] = useState(null);

  const [settings, setSettings] = useState({});
  const [successState, setSuccessState] = useState(null);

  const {
    auth: { user }
  } = useSelector((s) => s);
  const dispatch = useDispatch();

  useEffect(() => {
    setModal("ProductSelector");
    setOpen(true);
  }, [subscriptionId]);

  if (!subscriptionId) {
    return null;
  }

  function handleWizardClose() {
    setOpen(false);
    setTimeout(() => {
      setRedeemingGift(null);
      setModal(null);
      setSubscription({ variant_id: "" });
      setSuccessState(null);
      setPrevModal(null);
    }, 1000);
  }

  function handleGiftSubscriptionStart() {
    return new Promise(function (resolve, reject) {
      dispatch(
        startGiftSubscription(subscriptionId, {
          ...subscription,
          period: "week",
          start_date: moment(subscription.start_date).format("YYYY-MM-DD")
        })
      ).then(
        () => {
          setSuccessState({
            title: "Successfully started your new gift subscription",
            subtitle: "You will receive a confirmation email shortly"
          });
          resolve();
        },
        (err) => {
          setCompleteError(err);
          resolve();
        }
      );
    });
  }

  function handleGoBack() {
    const current = modal;
    setModal(prevModal);
    setPrevModal(current);
  }

  // These modals are only dismissable by close button.
  // We don't want users to accidentally click outside the modal and cancel their subscription build

  const sharedSettings = {
    subscription: subscription,
    setSettings: setSettings,
    handleWizardClose: handleWizardClose,
    handleGoBack: handleGoBack,
    prevModal: prevModal,
    countryCode: country_code
  };

  return (
    <Modal
      dismissable={false}
      isOpen={isOpen}
      title={settings.title}
      subtitle={settings.subtitle}
      size={settings.size}
      handleClose={handleWizardClose}
      footer={!successState && settings.footer}
      successState={successState}
    >
      {modal === "ProductSelector" && (
        <ProductSelector
          handleAction={(payload) => {
            setSubscription({
              ...subscription,
              variant_id: payload
            });
            setModal("ConfigureSubscription");
            setPrevModal("ProductSelector");
          }}
          {...sharedSettings}
        />
      )}
      {modal === "ConfigureSubscription" && (
        <ConfigureSubscription
          handleAction={(payload) => {
            setSubscription({
              ...subscription,
              ...payload,
              ...payload.interval
            });
            setModal("SelectDeliveryAddress");
            setPrevModal("ConfigureSubscription");
          }}
          {...sharedSettings}
        />
      )}
      {modal === "SelectDeliveryAddress" && (
        <SelectDeliveryAddress
          handleAddNewAddress={() => {
            setModal("Address");
            setPrevModal("SelectDeliveryAddress");
          }}
          handleAction={(payload) => {
            setSubscription({ ...subscription, ...payload });
            setModal("ConfirmNewSubscription");
            setPrevModal("SelectDeliveryAddress");
          }}
          {...sharedSettings}
        />
      )}
      {modal === "Address" && (
        <CreateAddress
          {...user}
          onAddressAdded={(payload) =>
            dispatch(addAddress(payload)).then((data) => {
              setSubscription({
                ...subscription,
                ...payload,
                address_id: data.id
              });
              setModal("ConfirmNewSubscription");
              setPrevModal("SelectDeliveryAddress");
            })
          }
          {...sharedSettings}
        />
      )}
      {modal === "ConfirmNewSubscription" && (
        <ConfirmNewSubscription
          subscription={subscription}
          handleProductEdit={() => {
            setModal("ProductSelector");
            setPrevModal("ConfirmNewSubscription");
          }}
          handleFrequencyEdit={() => {
            setModal("ConfigureSubscription");
            setPrevModal("ConfirmNewSubscription");
          }}
          handleAddressEdit={() => {
            setModal("SelectDeliveryAddress");
            setPrevModal("ConfirmNewSubscription");
          }}
          handleAction={handleGiftSubscriptionStart}
          completeError={completeError}
          {...sharedSettings}
        />
      )}
    </Modal>
  );
};

export default GiftSubscriptionFlow;
