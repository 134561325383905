import React, { useMemo, useState } from "react";
import ComplexOptionSelect from "./ComplexOptionSelect";
import SimpleOptionSelect from "./SimpleOptionSelect";

const VariantSelectModalContent = ({
  initialSelectedValues = {},
  onClose,
  onVariantSelect,
  reward
}) => {
  const [selectedValues, setSelectedValues] = useState(initialSelectedValues);

  const selectedVariant = useMemo(() => {
    if (!reward?.product) {
      return null;
    }

    if (reward.product.options.length > Object.keys(selectedValues).length) {
      return null;
    }

    const optionStr = Object.keys(selectedValues)
      .sort()
      .map(idx => selectedValues[idx])
      .join("_");

    const variant = reward.product.variants.find(
      ({ options }) => options.join("_") === optionStr
    );

    return variant || null;
  }, [reward, selectedValues]);

  return (
    <>
      <div className="modal-header">
        <h4 className="body-20 fw-bold">{reward.product.selection_title}</h4>
        <button
          aria-label="Close"
          className="btn-close"
          onClick={onClose}
          type="button"
        />
      </div>
      <div className="modal-body">
        {reward.product.options.map((option, idx) =>
          typeof option.values[0] === "string" ? (
            <SimpleOptionSelect
              className={idx > 0 && "mt-3"}
              key={option.name}
              option={option}
              onSelect={value =>
                setSelectedValues({
                  ...selectedValues,
                  [idx]: value
                })
              }
              selectedValue={selectedValues[idx]}
              showLabel={reward.product.options.length > 1}
            />
          ) : (
            <ComplexOptionSelect
              className={idx > 0 && "mt-3"}
              key={option.name}
              option={option}
              onSelect={value =>
                setSelectedValues({
                  ...selectedValues,
                  [idx]: value
                })
              }
              selectedValue={selectedValues[idx]}
              showLabel={reward.product.options.length > 1}
            />
          )
        )}
        <button
          className="gkit-btn gkit-btn-md gkit-primary mt-3"
          disabled={!selectedVariant}
          onClick={() => onVariantSelect(selectedVariant)}
        >
          Continue
        </button>
      </div>
    </>
  );
};

export default VariantSelectModalContent;
