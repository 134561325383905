import Modal from "shared/Modal";
import Button from "shared/Button";
import { useState } from "react";
import ErrorHandling from "shared/ErrorHandling";

const RemoveDiscountModal = ({ subId, isOpen, handleClose, handleAction }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleRemove = async () => {
    setError(null);
    setIsLoading(true);
    await handleAction();

    setTimeout(() => {
      setIsLoading(false);
      handleClose();
    }, 1000);
  };
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <div>
        <p className="gkit-p-17 gkit-light">
          Are you sure you would like to remove [CODE] from this subscription?
        </p>
        <ErrorHandling error={error} />
      </div>
      <div className="d-flex mt-4">
        <Button className="me-3" isLoading={isLoading} onClick={handleRemove}>
          Yes, remove code
        </Button>
        {!isLoading && (
          <Button btnType="tertiary" onClick={handleClose}>
            No, keep code
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default RemoveDiscountModal;
