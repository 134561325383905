import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Button from "shared/Button";

const NotFound = () => {
  return (
    <Fragment>
      <h1 className="gkit-h1">Page not found</h1>

      <p className="gkit-p-20 gkit-light mt-5 mb-2">
        Uh oh, we can't seem to find the page you're looking for.
      </p>
      <p className="gkit-p-20 gkit-light mt-0 mb-2">
        Looks like you entered a URL that doesn't exist on this site, or you
        might have taken the wrong turn somewhere.
      </p>
      <p className="gkit-p-20 gkit-light mt-0">
        If you think something is broken,{" "}
        <a href="mailto:hello@grind.co.uk" className="gkit-bold gkit-light">
          report a problem
        </a>
        .
      </p>
      <div className="mt-5 mb-3">
        <NavLink to="/">
          <Button size="md">Back to Home page</Button>
        </NavLink>
      </div>
    </Fragment>
  );
};

export default NotFound;
