import React, { Fragment, useState } from "react";
import Button from "shared/Button";
import Subscription from "shared/Subscription";

const ArchivedSubscriptions = ({ subscriptions }) => {
  const [showArchived, setShowArchived] = useState(false);
  return (
    <Fragment>
      <Button
        onClick={() => setShowArchived(!showArchived)}
        btnType="secondary"
        className="mt-3 mb-3 mb-md-0"
      >
        {showArchived ? "Hide Past Subscriptions" : "Show Past Subscriptions"}
      </Button>
      {showArchived &&
        subscriptions.map((subscription, idx) => {
          return (
            <Subscription
              subscription={subscription}
              isLast={subscriptions.length === idx + 1}
              key={subscription.id}
            />
          );
        })}
    </Fragment>
  );
};

export default ArchivedSubscriptions;
