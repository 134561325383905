import React, { useState } from "react";
import Card from "shared/Card";

import SurveyImg from "assets/img/survey.png";
import Button from "shared/Button";

import { setCookie, getCookie } from "shared/utility";

const SurveyCard = () => {
  const [surveyCookie, setSurveyCookie] = useState(getCookie("surveyCookie"));

  function handleSurveyOpen() {
    window.open("https://frankie807610.typeform.com/to/TQxvmRAR");
    setSurveyCookie(true);
    setCookie("surveyCookie", "surveyOpened", 60);
  }

  if (surveyCookie) {
    return null;
  }

  return (
    <Card className="mb-3 pt-4 gkit-bg-gray-5">
      <div className="row">
        <div className="col-12 col-xl-9">
          <h3 className="gkit-h3 gkit-sm-p-20 fw-bold">How's your coffee?</h3>
          <p className="gkit-p-17 gkit-sm-p-15 gkit-light">
            Let us know and get 15% off your next subscription order!
          </p>
        </div>
        <div className="d-none d-xl-block col-3 p-0">
          <img src={SurveyImg} alt="Survey" width={100} height={100} />
        </div>
      </div>
      <div className="d-flex flex-column flex-sm-row flex-lg-column flex-xl-row mt-3">
        <Button
          className="me-sm-3 me-lg-0 me-xl-3"
          size="md"
          onClick={handleSurveyOpen}
        >
          Start survey
        </Button>
        <Button
          btnType="secondary"
          size="md"
          className="bg-transparent mt-2 mt-sm-0 mt-lg-2 mt-xl-0"
          onClick={() => {
            setCookie("surveyCookie", "postponed", 40);
            setSurveyCookie(true);
          }}
        >
          Maybe later
        </Button>
      </div>
    </Card>
  );
};

export default SurveyCard;
