import React, { useCallback, useState } from "react";
import cx from "classnames";

import styles from "./GenerateVoucherModal.module.scss";

import IconCopy from "assets/icons/iconCopy.svg";

const CodeSuccessModalContent = ({ discount, onClose }) => {
  const [copyStatus, setCopyStatus] = useState(null);
  const onCopyClick = useCallback(() => {
    navigator.clipboard.writeText(discount.code).then(
      () => {
        setCopyStatus(true);
        setTimeout(() => {
          setCopyStatus(null);
        }, 1000);
      },
      () => {
        setCopyStatus(false);
        setTimeout(() => {
          setCopyStatus(null);
        }, 1000);
      }
    );
  }, [discount.code]);
  return (
    <div className="modal-body d-flex flex-column align-items-center">
      <p className="body-17 color-gray-1 text-center">
        We've also emailed you this code, so you can find it again later.
      </p>

      <button
        disabled={copyStatus !== null}
        className={cx(
          styles.codeContainer,
          "d-flex flex-column align-items-center py-4 w-100 my-4"
        )}
        onClick={onCopyClick}
        type="button"
      >
        <div className="d-flex align-items-center">
          <img alt="Click to copy code" src={IconCopy} />
          <p
            className={cx(
              "body-15 fw-medium color-gray-0 ms-1",
              copyStatus === true && "color-green"
            )}
          >
            {copyStatus === null && "Click to copy code"}
            {copyStatus === true && "Copied successfully"}
            {copyStatus === false && "Copy failed."}
          </p>
        </div>
        <p className={cx(styles.code, "mt-2")}>{discount.code}</p>
      </button>

      <a
        className="gkit-btn gkit-btn-md gkit-primary w-100"
        href={discount.url}
        rel="noopener noreferrer"
        target="_blank"
        type="button"
      >
        Visit website
      </a>
      <button
        className="gkit-btn gkit-btn-md gkit-tertiary w-100 mt-2"
        onClick={onClose}
        type="button"
      >
        Done
      </button>
    </div>
  );
};

export default CodeSuccessModalContent;
