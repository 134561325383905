import React from "react";
import { useSelector } from "react-redux";
import { values as lodashValues } from "lodash";

import OptionCard from "../OptionCard";
import CardGrid from "../CardGrid";
import { Fragment } from "react";

const FormatSection = ({
  nextRef,
  handleSelect,
  format_id,
  countryCode,
  excludeIndex
}) => {
  const { formats } = useSelector(s => s.config.planConfigs[countryCode]);
  const options = lodashValues(formats).filter(
    (_format, idx) => idx !== excludeIndex
  );

  return (
    <Fragment>
      <h3 className="gkit-h3 mt-0 mb-1">Subscription Package</h3>
      <p className="gkit-p-20 gkit-light mt-0">
        Choose your coffee format & blend
      </p>
      <CardGrid highlightPopular>
        {options.map(option => (
          <OptionCard
            active={option.id === format_id}
            key={option.id}
            onClick={() => {
              nextRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start"
              });
              handleSelect({
                format_id: option.id,
                size_id: 0,
                variant_id: "",
                product_id: "",
                mixAndMatchPick: null
              });
            }}
            image={option.image}
            title={option.name}
            popular={option.popular}
          />
        ))}
      </CardGrid>
    </Fragment>
  );
};

export default FormatSection;
