import * as Yup from "yup";

const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

const ShippingAddressSchema = {
  first_name: Yup.string().required("First name is required."),
  last_name: Yup.string().required("Last name is required."),
  address1: Yup.string().min(3).required("An address is required."),
  address2: Yup.string().nullable(),
  zip: Yup.string().required("Postal Code is required."),
  city: Yup.string().required("City is required."),
  phone: Yup.string().matches(phoneRegExp, "Phone number is not valid")
};

export default Yup.object().shape(ShippingAddressSchema);
