import React, { useState } from "react";
import cx from "classnames";

import styles from "./InputRadioSpecial.module.scss";

import { uniqueId } from "lodash";

const InputRadioGroupSpecial = ({ title, className, children, ...props }) => {
  return (
    <InputRadioGroup className={cx(className, styles.group)} {...props}>
      <div className="d-flex flex-row justify-content-start align-items-center overflow-auto">
        {children}
      </div>
      <p className="pl-2 gkit-p-15 gkit-bold mt-1 mb-0 ms-1">{title}</p>
    </InputRadioGroup>
  );
};

const InputRadioSpecial = ({
  overallLength,
  item,
  children,
  className,
  onClick,
  checked,
  ...props
}) => {
  const [referenceId] = useState(uniqueId("radio-special-"));
  return (
    <div
      className={cx("form-check p-0", styles.specialRadio, className)}
      style={{
        flex: 1
      }}
    >
      <input
        type="radio"
        className={cx("form-check-input", "d-none")}
        {...props}
        value={props.id}
        id={referenceId}
        checked={checked}
        onChange={onClick}
      />
      {children && (
        <label
          className={cx("form-check-label w-100 p-1")}
          htmlFor={referenceId}
        >
          <div
            className={cx(
              "d-flex flex-column align-items-center justify-content-center text-center border-2",
              styles.imageBorder
            )}
          >
            <img src={item.icon} alt={item.title} className={cx(styles.icon)} />
            <p className="gkit-p-17 mt-2 mb-0 gkit-bold">{item.value}</p>
          </div>
        </label>
      )}
    </div>
  );
};

const InputRadioGroup = ({ children, hideError, ...props }) => {
  return (
    <fieldset className={cx("form-group", props.className)}>
      {props.label && (
        <label className="d-block" htmlFor={props.id || props.name}>
          {props.label}
        </label>
      )}
      {children}
    </fieldset>
  );
};

export { InputRadioGroupSpecial, InputRadioSpecial };
