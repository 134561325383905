/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import OptionCard from "../OptionCard";
import ToggleSwitch from "shared/ToggleSwitch";
import CardGrid from "../CardGrid";
import Button from "shared/Button";
import ReadMoreAboutBlendsModal from "shared/modals/ReadMoreAboutBlendsModal";
import {
  InputRadioGroupSpecial,
  InputRadioSpecial
} from "shared/InputRadioSpecial";

const BlendSection = ({
  handleSelect,
  format_id,
  size_id,
  variant_id,
  mixAndMatchPick,
  countryCode
}) => {
  const { formats } = useSelector((s) => s.config.planConfigs[countryCode]);
  const [modal, setModal] = useState(false);
  const selectedFormat = formats[format_id];
  const selectedSize = size_id !== "" ? selectedFormat.sizes[size_id] : null;

  useEffect(() => {
    if (
      mixAndMatchPick &&
      Object.keys(mixAndMatchPick).length ===
        selectedSize.mixnmatch.options.length
    ) {
      const selectedBlends = Object.values(mixAndMatchPick).sort().join("-");
      const { variant_id, product_id } =
        selectedSize.mixnmatch.variantTitleMap[selectedBlends];
      handleSelect({
        variant_id: variant_id,
        product_id: product_id
      });
    }
  }, [mixAndMatchPick]);

  const MixAndMatch = ({ children }) => {
    function handleMixMachChoice() {
      if (!mixAndMatchPick) {
        handleSelect({
          mixAndMatchPick: {},
          variant_id: null,
          product_id: null
        });
      } else {
        handleSelect({ mixAndMatchPick: null });
      }
    }
    return (
      <div className="mt-3">
        <ToggleSwitch
          title="Mix & Match"
          subtitle="For the indecisive. Mix-and-match the different blends."
          name="mixAndMatchPick"
          checked={mixAndMatchPick}
          onChange={handleMixMachChoice}
        />
        {children}
      </div>
    );
  };

  return (
    <Fragment>
      <h3 className="gkit-h3 mt-4 mb-1">Pick your favourite blend</h3>
      <p className="gkit-p-20 gkit-light mt-0">
        All our blends, readily available
      </p>
      {!mixAndMatchPick && (
        <CardGrid
          firstOverride={{
            xs: 12,
            md: 6,
            lg: 3
          }}
          override={{
            xs: 4,
            md: 3
          }}
          highlightPopular
        >
          {selectedSize &&
            selectedSize?.presets.map((option) => {
              return (
                <OptionCard
                  active={option.id === variant_id}
                  key={option.id}
                  onClick={() =>
                    handleSelect({
                      variant_id: option.id,
                      product_id: option.item.product_id
                    })
                  }
                  image={option.image}
                  title={option.title}
                  popular={option.popular}
                />
              );
            })}
        </CardGrid>
      )}
      {selectedSize.mixnmatch && (
        <MixAndMatch>
          {mixAndMatchPick &&
            selectedSize.mixnmatch.options.map((pick) => {
              return (
                <InputRadioGroupSpecial
                  name={`mix_and_match[${pick.position}]`}
                  key={pick.position}
                  title={pick.name}
                >
                  {pick.values.map((value, valueIndex) => (
                    <InputRadioSpecial
                      overallLength={pick.values.length}
                      item={value}
                      key={`blend-mix-${pick.position}-${valueIndex}`}
                      name={`mix_and_match[${pick.position}]`}
                      id={value.value}
                      checked={mixAndMatchPick[pick.position] === value.value}
                      onClick={() =>
                        handleSelect({
                          mixAndMatchPick: {
                            ...mixAndMatchPick,
                            [pick.position]: value.value
                          }
                        })
                      }
                    >
                      {value.value}
                    </InputRadioSpecial>
                  ))}
                </InputRadioGroupSpecial>
              );
            })}
        </MixAndMatch>
      )}
      <Button
        className="mt-3 w-100"
        size="lg"
        btnType="tertiary"
        onClick={() => setModal(true)}
      >
        Read more about the blends
      </Button>
      <ReadMoreAboutBlendsModal
        isOpen={modal}
        options={selectedSize && selectedSize.presets}
        handleClose={() => setModal(null)}
      />
    </Fragment>
  );
};

export default BlendSection;
