import React, { useEffect } from "react";
import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import { Application } from "react-rainbow-components";
import { useSelector, useDispatch } from "react-redux";
import { fetchCountries } from "app/config/actions";

const theme = {
  rainbow: {
    palette: {
      brand: "#b0a8a2"
    }
  }
};

const Layout = () => {
  const { countries } = useSelector((s) => s.config);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!countries) {
      dispatch(fetchCountries());
    }
  }, [dispatch, countries]);

  return (
    <Application className="app-root" theme={theme}>
      <Header />
      <Content />
      <Footer />
    </Application>
  );
};

export default Layout;
