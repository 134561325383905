import React from "react";
import cx from "classnames";
import styles from "./PointsProgressBar.module.scss";

const PointsProgressBar = ({ className, progress, total }) => (
  <div className={cx(styles.container, "d-flex", className)}>
    <div
      className={styles.innerBar}
      style={{
        flex: Math.max(0.05, (total - progress) / total)
      }}
    />
  </div>
);

export default PointsProgressBar;
