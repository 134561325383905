import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";

import styles from "./Buttons.module.scss";

export default function ButtonBase({
  children,
  className,
  isLoading,
  to,
  ...props
}) {
  if (to) {
    return (
      <Link
        className={cx(styles.base, isLoading && styles.loading, className)}
        to={to}
        {...props}
      >
        {isLoading ? "loading..." : children}
      </Link>
    );
  } else {
    return (
      <button
        className={cx(styles.base, isLoading && styles.loading, className)}
        {...props}
      >
        {isLoading ? "loading..." : children}
      </button>
    );
  }
}
