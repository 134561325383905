import api from "api";
import { getSubscriptions } from "app/subscriptions/actions";

import { createActions } from "redux-actions";
import {
  CREATE_LOYALTY_ACCOUNT,
  CREATE_LOYALTY_ACCOUNT_SUCCESS,
  CREATE_LOYALTY_ACCOUNT_FAILURE,
  FETCH_LOYALTY,
  FETCH_LOYALTY_SUCCESS,
  FETCH_LOYALTY_FAILURE,
  FETCH_LOYALTY_REWARD,
  FETCH_LOYALTY_REWARD_SUCCESS,
  FETCH_LOYALTY_REWARD_FAILURE,
  FETCH_THIRD_PARTY_REWARDS,
  FETCH_THIRD_PARTY_REWARDS_SUCCESS,
  FETCH_THIRD_PARTY_REWARDS_FAILURE,
  CLAIM_REWARD,
  CLAIM_REWARD_SUCCESS,
  CLAIM_REWARD_FAILURE
} from "./actionTypes";

const {
  createLoyaltyAccount,
  createLoyaltyAccountSuccess,
  createLoyaltyAccountFailure,
  fetchLoyalty,
  fetchLoyaltySuccess,
  fetchLoyaltyFailure,
  fetchLoyaltyReward,
  fetchLoyaltyRewardSuccess,
  fetchLoyaltyRewardFailure,
  fetchThirdPartyRewards,
  //fetchThirdPartyRewardsSuccess, [GRI-343]
  //fetchThirdPartyRewardsFailure, [GRI-343]
  claimReward,
  claimRewardSuccess,
  claimRewardFailure
} = createActions(
  CREATE_LOYALTY_ACCOUNT,
  CREATE_LOYALTY_ACCOUNT_SUCCESS,
  CREATE_LOYALTY_ACCOUNT_FAILURE,
  FETCH_LOYALTY,
  FETCH_LOYALTY_SUCCESS,
  FETCH_LOYALTY_FAILURE,
  FETCH_LOYALTY_REWARD,
  FETCH_LOYALTY_REWARD_SUCCESS,
  FETCH_LOYALTY_REWARD_FAILURE,
  FETCH_THIRD_PARTY_REWARDS,
  FETCH_THIRD_PARTY_REWARDS_SUCCESS,
  FETCH_THIRD_PARTY_REWARDS_FAILURE,
  CLAIM_REWARD,
  CLAIM_REWARD_SUCCESS,
  CLAIM_REWARD_FAILURE
);

export const enrolInRewardScheme = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(createLoyaltyAccount());

    api.put("/account/preferences", { accepts_marketing: true });

    api.post("/loyalty/account").then(
      ({ data }) => {
        dispatch(createLoyaltyAccountSuccess(data));
        resolve(data);
      },
      err => {
        dispatch(createLoyaltyAccountFailure(err));
        reject(err);
      }
    );
  });

export const loadLoyalty = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchLoyalty());

    api.get("/loyalty").then(
      ({ data }) => {
        dispatch(fetchLoyaltySuccess(data));
        resolve(data);
      },
      err => {
        dispatch(fetchLoyaltyFailure(err));
        reject(err);
      }
    );
  });

export const loadLoyaltyReward = rewardId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchLoyaltyReward(rewardId));

    api.get(`/loyalty/rewards/${rewardId}`).then(
      ({ data }) => {
        dispatch(fetchLoyaltyRewardSuccess(data));
        resolve(data);
      },
      err => {
        dispatch(fetchLoyaltyRewardFailure(err));
        reject(err);
      }
    );
  });

export const loadThirdPartyRewards = () => dispatch =>
  new Promise(() => {
    // Re-add resolve/reject
    dispatch(fetchThirdPartyRewards());

    //TODO: Finish [GRI-343]
  });

export const claimLoyaltyReward =
  (rewardId, params = {}) =>
  dispatch =>
    new Promise((resolve, reject) => {
      dispatch(claimReward({ rewardId, params }));

      api.post(`/loyalty/rewards/${rewardId}/claim`, params).then(
        ({ data }) => {
          dispatch(claimRewardSuccess(data));
          resolve(data);

          if (params?.subscription_id) {
            dispatch(getSubscriptions());
          }
        },
        err => {
          dispatch(claimRewardFailure(err));
          reject(err);
        }
      );
    });
