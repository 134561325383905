import React from "react";
import Button from "../Button";
import Modal from "../Modal";
import ErrorIcon from "assets/icons/error.svg";

const ErrorMessageModal = ({ error, handleClose }) => {
  return (
    <Modal
      isOpen={Boolean(error)}
      handleClose={handleClose}
      dismissable
      size="small"
      hideCloseButton={false}
    >
      <div className="p-2 pt-3 text-center">
        <img
          width="48"
          height="48"
          alt="Error"
          src={ErrorIcon}
          className="mb-3"
        />
        <h3 className="gkit-h3 gkit-sm-p-20 fw-bold mb-0">{error}</h3>
        <Button className="mt-4" onClick={handleClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default ErrorMessageModal;
