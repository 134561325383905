import React from "react";

import cx from "classnames";

import styles from "./RewardSection.module.scss";

import IconArrowRight from "assets/icons/iconArrowRight.svg";

const RewardSection = ({
  className,
  currentPoints,
  onRewardClick,
  rewards,
  title
}) => (
  <div className={cx(className)}>
    <h5 className="body-20 fw-bold color-charcoal">{title}</h5>
    <div className={cx(styles.rewardRow, "d-flex flex-wrap mt-2")}>
      {rewards.map((reward, idx) => (
        <button
          disabled={currentPoints < reward.points}
          className={cx(styles.rewardContainer, "p-3")}
          key={reward.id}
          onClick={() => onRewardClick(reward)}
          type="button"
        >
          <div className="d-flex flex-row align-items-center justify-content-between">
            <img
              alt={reward.title}
              className={cx(styles.rewardImage)}
              src={reward.image_url}
            />
            <img alt={reward.title} src={IconArrowRight} />
          </div>
          <p className="body-15 fw-bold text-start">{reward.title}</p>
          <p
            className={cx(
              "caption-13 text-start mt-1",
              currentPoints < reward.points ? "color-negative" : "color-green"
            )}
          >
            {reward.points} point{reward.points !== 1 ? "s" : ""}
          </p>
        </button>
      ))}
    </div>
  </div>
);

export default RewardSection;
