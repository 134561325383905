import React from "react";
import cx from "classnames";

import GrindLogo from "assets/img/placeholder.png";
import AppStoreButton from "assets/img/apple-button.png";
import PlayStoreButton from "assets/img/android-button.png";
import styles from "./MobileRewardsSection.module.scss";

const MobileRewardsSection = ({ className }) => (
  <div className={cx(styles.container, "pb-3", className)}>
    <h3 className="gkit-p-20 fw-bold">In-Store Rewards</h3>
    <div className="d-flex flex-row align-items-start mt-3">
      <img alt="Download the app" className={styles.appIcon} src={GrindLogo} />
      <div className="ms-3">
        <p className="gkit-p-15 fw-regular gkit-light">
          Download the app and get your free coffees in any Grind location.
        </p>

        <div className="mt-2 d-flex flex-row">
          <a
            className={styles.appStoreCta}
            href="https://apps.apple.com/gb/app/grind/id1037989222"
            target="_blank"
            rel="noreferrer"
          >
            <img src={AppStoreButton} alt="Download on App Store" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.grind.app&gl=GB"
            target="_blank"
            rel="noreferrer"
            className={cx(styles.appStoreCta, "ms-2")}
          >
            <img src={PlayStoreButton} alt="Download on App Store" />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default MobileRewardsSection;
