import React from "react";
import cx from "classnames";
import AssetCup from "assets/img/assetRewardsCup.png";

import styles from "./RewardUpsellBanner.module.scss";
import { Link } from "react-router-dom";

const RewardUpsellBanner = ({ className }) => (
  <div className={cx(styles.container, "d-flex align-items-center", className)}>
    <img
      className={styles.cup}
      alt="Grind rewards."
      width={64}
      height={80}
      src={AssetCup}
    />
    <div className="ms-2 flex-grow-1">
      <p className="gkit-p-17 fw-bold text-white">Grind rewards.</p>
      <p className={cx(styles.subtitle, "gkit-p-13 fw-medium mt-0")}>
        Earn points, get free coffee.
      </p>
    </div>
    <Link className="gkit-btn gkit-btn-sm gkit-tertiary px-2" to="/rewards">
      Get started
    </Link>
  </div>
);

export default RewardUpsellBanner;
