import { createActions } from "redux-actions";
import {
  SET_COUNTRIES,
  SET_PAYMENT_PROVIDERS,
  SET_PLAN_CONFIG
} from "./actionTypes";
import api from "api";
import { paypalCheckout, client as braintreeClient } from "braintree-web";

const { setPaymentProviders, setPlanConfig, setCountries } = createActions(
  SET_PAYMENT_PROVIDERS,
  SET_PLAN_CONFIG,
  SET_COUNTRIES
);

const getPaymentProviders = () => (dispatch) => {
  api.get("payment-providers").then(({ data }) => {
    const paymentProviders = {};
    for (const provider of data) {
      paymentProviders[provider.type] = {
        name: provider.name,
        token: provider.token
      };
    }
    braintreeClient
      .create({
        authorization: paymentProviders.paypal.token
      })
      .then((client) =>
        paypalCheckout.create({
          client
        })
      )
      .then((paypalCheckoutInstance) => {
        paypalCheckoutInstance.loadPayPalSDK({ vault: true }).then(() => {
          paymentProviders.paypal["checkoutInstance"] = paypalCheckoutInstance;
          paymentProviders.paypal["loaded"] = true;
          console.log("Paypal SDK Loaded.");
        });
      });
    dispatch(setPaymentProviders(paymentProviders));
  });
};

let innerLoading = [];

const fetchPlanConfig =
  (params = { country_code: "GB", currency: "GBP" }) =>
  (dispatch) => {
    if (!innerLoading.includes(params.country_code)) {
      innerLoading.push(params.country_code);
      new Promise((resolve, reject) => {
        api
          .get("/shop/subscriptions/plan-config", { params })
          .then(({ data }) => {
            dispatch(setPlanConfig({ ...data, ...params }));
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  };

const fetchCountries = () => (dispatch) => {
  new Promise((resolve, reject) => {
    api
      .get("/shop/countries")
      .then(({ data }) => {
        dispatch(setCountries(data));
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { getPaymentProviders, fetchPlanConfig, fetchCountries };
