import React, { useState } from "react";
import CustomDatePicker from "../inputs/CustomDatePicker";
import Modal from "../Modal";
import Button from "../Button";
import moment from "moment";

import { useDispatch } from "react-redux";
import { pauseSubscription } from "app/subscriptions/actions";
import ErrorHandling from "shared/ErrorHandling";

const PauseSubscriptionModal = ({ isOpen, handleClose, subscriptionId }) => {
  const dispatch = useDispatch();
  const minDate = moment().add(1, "day").startOf("day").toDate();
  const maxDate = moment().add(3, "month").startOf("day").toDate();

  const [successState, setSuccessState] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [until, setUntil] = useState(null);
  const [error, setError] = useState(null);

  function handleCloseAndReset() {
    handleClose();
    setTimeout(() => {
      setUntil(null);
      setSuccessState(null);
      setError(null);
    }, 1000);
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleCloseAndReset}
      title="Pause Subscription"
      subtitle="We'll put it on hold until you need it again."
      successState={successState}
    >
      <p className="gkit-p-17 fw-bold mt-0 mb-1">Next order date</p>
      <p className="gkit-p-15 mt-0 gkit-light mb-2">
        You can pause for up to 3 months.
      </p>

      <CustomDatePicker
        name="until"
        value={until}
        onChange={setUntil}
        minDate={minDate}
        maxDate={maxDate}
      />
      <ErrorHandling error={error} />
      <div className="mt-4">
        <Button
          className="me-3"
          type="submit"
          disabled={!until}
          isLoading={isSubmitting}
          onClick={() => {
            setSubmitting(true);
            dispatch(pauseSubscription(subscriptionId, { until: until })).then(
              (data) => {
                setSuccessState({
                  title: "Your subscription has now been paused",
                  subtitle: `Thank you for confirming your subscription details, your subscription has now been paused until: ${moment(
                    data.paused_until,
                    "YYYY-MM-DD"
                  ).format("DD/MM/YYYY")}`
                });
                setSubmitting(false);
              }
            );
          }}
        >
          Update
        </Button>
        <Button
          btnType="tertiary"
          className="mt-2 mt-md-0"
          onClick={handleCloseAndReset}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default PauseSubscriptionModal;
