import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Addresses from "./Addresses";
import PaymentMethods from "./PaymentMethods";
import Notifications from "./Notifications";
import Profile from "./Profile";
import NotFound from "shared/NotFound";

const routes = [
  { exact: true, path: "/", redirect: "/subscriptions" },
  { exact: true, path: "/account", redirect: "/account/addresses" },
  { exact: false, path: "/account/addresses", component: Addresses },
  { exact: false, path: "/account/payment-methods", component: PaymentMethods },
  { exact: false, path: "/account/notifications", component: Notifications },
  { exact: false, path: "/account/profile", component: Profile },
  {
    component: NotFound
  }
];

const Router = () => {
  return (
    <Switch>
      {routes.map(({ redirect, ...props }, index) =>
        redirect ? (
          <Route key={index} {...props}>
            <Redirect to={redirect} />
          </Route>
        ) : (
          <Route key={index} {...props} />
        )
      )}
    </Switch>
  );
};

export default Router;
