import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import InputField from "shared/Formik/InputField";
import Button from "shared/Button";
import ShippingAddressSchema from "shared/validationSchema/ShippingAddressSchema";
import ErrorHandling from "shared/ErrorHandling.js";
import { getCountryName } from "shared/utility";
import ProvinceSelect from "shared/Formik/ProvinceSelect";
import { useSelector } from "react-redux";

const CreateAddress = ({
  setSettings,
  first_name,
  last_name,
  onAddressAdded,
  handleGoBack,
  countryCode
}) => {
  const [error, setError] = useState(null);
  const { countries } = useSelector((s) => s.config);

  function handleCloseAndReset() {
    handleGoBack();
    setTimeout(() => {
      setError(null);
    }, 1000);
  }

  useEffect(() => {
    setSettings({
      title: "Add new delivery address",
      subtitle: "Tell us where you want your orders to be delivered"
    });
  }, [setSettings]);

  return (
    <Formik
      initialValues={{
        first_name: first_name,
        last_name: last_name,
        phone: "",
        address1: "",
        address2: "",
        city: "",
        zip: "",
        country: getCountryName({
          countryCode: countryCode,
          countries: countries
        }),
        province: ""
      }}
      validationSchema={ShippingAddressSchema}
      onSubmit={(values) => {
        const payload = {
          first_name: values.first_name,
          last_name: values.last_name,
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          zip: values.zip,
          country_code: countryCode,
          province_code: values.province.name
        };
        onAddressAdded(payload);
      }}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form className="mt-3">
          <div className="row mb-3">
            <div className="col-12 col-md-6">
              <InputField name="first_name" type="text" label="First name" />
            </div>
            <div className="col-12 col-md-6 mt-3 mt-md-0">
              <InputField name="last_name" type="text" label="Last name" />
            </div>
          </div>
          <InputField name="phone" label="Phone number" className="mb-3" />
          <InputField
            name="address1"
            type="text"
            label="Address Line 1"
            className="mb-3"
          />
          <InputField name="address2" type="text" label="Address Line 2" />
          <div className="row mt-3">
            <div className="col-12 col-md-6">
              <InputField name="city" type="text" label="City" />
            </div>
            <div className="col-12 col-md-6 mt-3 mt-md-0">
              <InputField name="zip" type="text" label="Postcode" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-6">
              <InputField name="country" type="text" label="Country" disabled />
            </div>
            <div className="col-12 col-md-6 mt-3 mt-md-0">
              <ProvinceSelect countryCode={countryCode} />
            </div>
          </div>
          <ErrorHandling error={error} />
          <div className="d-flex mt-4">
            <Button
              type="submit"
              className="me-3"
              isLoading={isSubmitting}
              disabled={!dirty || !isValid}
            >
              Save
            </Button>
            <Button
              btnType="tertiary"
              onClick={handleCloseAndReset}
              type="reset"
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateAddress;
