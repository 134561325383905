import React, { useState } from "react";
import moment from "moment";
import { SingleProduct } from "./OrderHistoryItem";

import Button from "shared/Button";

import { downloadInvoice } from "./actions";

const OrderHistoryMobile = ({
  id,
  order_number,
  created_at,
  products,
  subtotal,
  total
}) => {
  const [downloading, setDownLoading] = useState(false);
  function handleInvoiceDownload() {
    setDownLoading(true);
    downloadInvoice(id).then(() => setDownLoading(false));
  }

  return (
    <div className="d-block d-sm-none gkit-bg-gray-4 rounded flex-column my-3 py-2">
      <div className="d-flex m-0 px-2 justify-content-between">
        <div className="p-2">
          <p className="gkit-p-15 fw-bold mb-1">Order #{order_number}</p>
          <p className="gkit-p-15 m-0">
            {moment(created_at, "YYYY-MM-DD").format("D MMM YYYY")}
          </p>
        </div>
        <div className="p-2">
          <Button
            className="m-auto"
            size="md"
            isLoading={downloading}
            onClick={handleInvoiceDownload}
          >
            Invoice
          </Button>
        </div>
      </div>
      <div className="row m-0 px-2">
        <div className="col-8 p-2">
          {products.map((product, idx) => (
            <SingleProduct {...product} key={idx} />
          ))}
        </div>
        <div className="col-4 p-2">
          <p className="gkit-p-15 gkit-bold m-0 text-end">
            {total.formatted === subtotal.formatted ? (
              total.formatted
            ) : (
              <div className="d-flex flex-column align-items-end">
                <span className="text-success d-flex">{total.formatted}</span>
                <span
                  style={{
                    textDecoration: "line-through",
                    fontWeight: "normal"
                  }}
                  className="d-flex"
                >
                  {subtotal.formatted}
                </span>
              </div>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderHistoryMobile;
