import React from "react";
import cx from "classnames";

import styles from "./AddToSubscriptionModal.module.scss";

import IconTick from "assets/icons/iconGreenCircleTick.svg";

const SubscriptionSuccessModalContent = ({ onClose }) => (
  <>
    <div className="modal-header">
      <button
        aria-label="Close"
        className="btn-close"
        onClick={onClose}
        type="button"
      />
    </div>
    <div className="modal-body d-flex flex-column align-items-center text-center pt-5">
      <img alt="Success" src={IconTick} />
      <h4 className="gkit-h3 mt-3">Reward redeemed!</h4>
      <p className={cx(styles.successBody, "body-15 color-gray-0 mt-2")}>
        Your free reward will be delivered along with your next subscription
        order.
      </p>

      <button
        className="gkit-btn gkit-btn-md gkit-primary mt-5 w-100"
        onClick={onClose}
        type="button"
      >
        Done
      </button>
    </div>
  </>
);

export default SubscriptionSuccessModalContent;
