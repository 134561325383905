import React from "react";

const SubscriptionStatus = ({ status, className = "" }) => {
  const statusColour = {
    active: "green",
    cancelled: "gray-1-5",
    paused: "gray-1",
    failed: "red",
    failing: "red",
    expired: "warning"
  };
  return (
    <span
      className={`gkit-badge rounded gkit-bg-${statusColour[status]} ${
        status === "expired" ? "text-dark" : "text-white"
      } ${className}`}
    >
      {status}
    </span>
  );
};

export default SubscriptionStatus;
