import React from "react";
import cx from "classnames";

import styles from "./MobileRewardsBanner.module.scss";
import RewardUpsellBanner from "./mobileRewardsBanner/RewardUpsellBanner";
import RewardsSummaryBanner from "./mobileRewardsBanner/RewardsSummaryBanner";

const MobileRewardsBanner = ({ loyalty }) => {
  return (
    <div className={cx(styles.container, "d-block d-lg-none")}>
      {loyalty.account ? (
        <RewardsSummaryBanner loyalty={loyalty} />
      ) : (
        <RewardUpsellBanner />
      )}
    </div>
  );
};

export default MobileRewardsBanner;
