import React, { Fragment } from "react";
import Subscription from "shared/Subscription";

const AllSubscriptions = ({ subscriptions }) => {
  return (
    <Fragment>
      {subscriptions.map((subscription, idx) => {
        if (subscription.status !== "failed") {
          return null;
        }
        return (
          <Subscription
            subscription={subscription}
            isLast={subscriptions.length === idx + 1}
            key={subscription.id}
          />
        );
      })}
      {subscriptions.map((subscription, idx) => {
        if (subscription.status !== "failing") {
          return null;
        }
        return (
          <Subscription
            subscription={subscription}
            isLast={subscriptions.length === idx + 1}
            key={subscription.id}
          />
        );
      })}
      {subscriptions.map((subscription, idx) => {
        if (subscription.status !== "active") {
          return null;
        }
        return (
          <Subscription
            subscription={subscription}
            isLast={subscriptions.length === idx + 1}
            key={subscription.id}
          />
        );
      })}
      {subscriptions.map((subscription, idx) => {
        if (subscription.status !== "expired") {
          return null;
        }
        return (
          <Subscription
            subscription={subscription}
            isLast={subscriptions.length === idx + 1}
            key={subscription.id}
          />
        );
      })}
      {subscriptions.map((subscription, idx) => {
        if (subscription.status !== "paused") {
          return null;
        }
        return (
          <Subscription
            subscription={subscription}
            isLast={subscriptions.length === idx + 1}
            key={subscription.id}
          />
        );
      })}
    </Fragment>
  );
};

export default AllSubscriptions;
