import React, { useState, useMemo } from "react";
import { Form, Formik } from "formik";
import Modal from "../Modal";
import InputField from "../Formik/InputField";
import Button from "../Button";
import ShippingAddressSchema from "shared/validationSchema/ShippingAddressSchema";
import ShippingAddressSchemaWithCountry from "shared/validationSchema/ShippingAddressSchemaWithCountry";
import ErrorHandling from "shared/ErrorHandling.js";
import { useSelector } from "react-redux";

import {
  updateAllSubscriptionAddresses,
  addAddress
} from "app/account/actions";

import { useDispatch } from "react-redux";
import SpecialSelect from "shared/Formik/SpecialSelect";
import ProvinceSelect from "shared/Formik/ProvinceSelect";

const CreateAddressModal = ({
  isOpen,
  first_name,
  last_name,
  handleClose,
  onAddressAdded,
  isBilling = false,
  countryCode
}) => {
  const dispatch = useDispatch();
  const [successState, setSuccessState] = useState(null);
  const { countries } = useSelector((s) => s.config);
  const [error, setError] = useState(null);

  function handleCloseAndReset() {
    handleClose();
    setTimeout(() => {
      setError(null);
      setSuccessState(null);
    }, 1000);
  }

  const addressCountry = useMemo(() => {
    if (!countryCode) {
      return null;
    }
    return countries.filter((country) => country.code === countryCode)[0];
  }, [countries, countryCode]);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleCloseAndReset}
      title="Add new address"
      subtitle={`Tell us where you want ${
        isBilling ? "the invoice to be sent" : "your orders to be delivered"
      }`}
      successState={successState}
    >
      <Formik
        initialValues={{
          first_name: first_name,
          last_name: last_name,
          phone: "",
          address1: "",
          address2: "",
          city: "",
          zip: "",
          country: addressCountry
            ? { name: addressCountry.code, label: addressCountry.name }
            : {},
          province: {},
          update_all: false
        }}
        validationSchema={addressCountry ? ShippingAddressSchema : ShippingAddressSchemaWithCountry}
        onSubmit={(values, { setSubmitting }) => {
          const payload = {
            first_name: values.first_name,
            last_name: values.last_name,
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            zip: values.zip,
            country_code: values.country.name,
            province_code: values.province.name,
            phone: values.phone
          };
          if (onAddressAdded) {
            onAddressAdded(payload);
          } else {
            new Promise(async (resolve) => {
              const action = values.update_all
                ? updateAllSubscriptionAddresses
                : addAddress;
              try {
                await dispatch(action(payload));
                onAddressAdded && onAddressAdded(payload);
                setSubmitting(false);
                setSuccessState({
                  title: "Successfully added the new address"
                });
                resolve();
              } catch (e) {
                setSubmitting(false);
                setError(e);
              }
            });
          }
        }}
      >
        {({ values, isSubmitting, isValid, dirty }) => (
          <Form className="mt-3">
            <div className="row mb-3">
              <div className="col-12 col-md-6">
                <InputField name="first_name" type="text" label="First name" />
              </div>
              <div className="col-12 col-md-6 mt-3 mt-md-0">
                <InputField name="last_name" type="text" label="Last name" />
              </div>
            </div>
            <InputField name="phone" label="Phone number" className="mb-3" />
            <InputField
              name="address1"
              type="text"
              label="Address Line 1"
              className="mb-3"
            />
            <InputField name="address2" type="text" label="Address Line 2" />
            <div className="row mt-3">
              <div className="col-12 col-md-6">
                <InputField name="city" type="text" label="City" />
              </div>
              <div className="col-12 col-md-6 mt-3 mt-md-0">
                <InputField name="zip" type="text" label="Postcode" />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-6">
                <SpecialSelect
                  name="country"
                  label="Country"
                  options={countries.map((country) => ({
                    name: country.code,
                    label: country.name
                  }))}
                />
              </div>
              <div className="col-12 col-md-6 mt-3 mt-md-0">
                <ProvinceSelect
                  countryCode={values.country && values.country.name}
                />
              </div>
            </div>
            {/* {!isBilling && (
              <InputField
                type="checkbox"
                className="react-rainbow-checkbox my-4"
                name="update_all"
                label="Update all active subscriptions to be delivered to this address"
              />
            )} */}
            <ErrorHandling error={error} />
            <div className="d-flex mt-4">
              <Button
                type="submit"
                className="me-3"
                isLoading={isSubmitting}
                disabled={!dirty || !isValid}
              >
                Save
              </Button>
              <Button
                btnType="tertiary"
                onClick={handleCloseAndReset}
                type="reset"
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateAddressModal;
