import { combineReducers } from "redux";
import account from "app/account/reducer";
import orderHistory from "app/orderHistory/reducer";
import subscriptions from "app/subscriptions/reducer";
import auth from "app/auth/reducer";
import config from "app/config/reducer";
import loyalty from "app/loyalty/reducer";

export default combineReducers({
  subscriptions,
  auth,
  account,
  config,
  orderHistory,
  loyalty
});
