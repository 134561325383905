import React from "react";
import cx from "classnames";

import styles from "./Badge.module.scss";

// Icons
import SparkIcon from "assets/icons/sparks.svg";

const Badge = ({ className, children }) => (
  <span
    className={cx(
      "d-flex flex-row justify-content-center align-items-center gkit-bg-pink p-2",
      styles.badge,
      className
    )}
  >
    {children}
  </span>
);

const PopularBadge = ({ className }) => (
  <Badge className={cx(className)}>
    <img src={SparkIcon} className={styles.icon} alt="icon of sparks" />
    <p className="gkit-p-13 px-1 m-0">Most Popular</p>
  </Badge>
);

export { PopularBadge };
