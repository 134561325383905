import React, { Fragment, useEffect, useState } from "react";
import Button from "shared/Button";
import { useSelector, useDispatch } from "react-redux";
import Radio from "shared/inputs/Radio";
import { filter } from "lodash";

import { stackedAddress } from "shared/utility";
import Loading from "shared/Loading";

const SelectDeliveryAddress = ({
  subscription,
  handleWizardClose,
  handleGoBack,
  handleAddNewAddress,
  handleAction,
  setSettings,
  countryCode
}) => {
  const existingAddresses = useSelector((s) => s.account.addresses);
  const { countries } = useSelector((s) => s.config);
  // we only want to display the addresses which are in the same country as the gift subscription
  // therefore not all addresses will be (necessarily) available
  const [availableAddresses, setAvailableAddresses] = useState(null);
  const dispatch = useDispatch();
  const [selectedAddressId, setSelectedAddressId] = useState(
    subscription.address_id ? subscription.address_id : null
  );

  useEffect(() => {
    if (existingAddresses.length === 0) {
      handleAddNewAddress();
    }
  }, [existingAddresses, dispatch, handleAddNewAddress]);

  useEffect(() => {
    if (existingAddresses) {
      setAvailableAddresses(
        filter(
          existingAddresses,
          (address) => address.country_code === countryCode
        )
      );
    }
  }, [countryCode, existingAddresses]);

  useEffect(() => {
    setSettings({
      size: "md",
      title: "Delivery Address",
      subtitle: "Tell us where you want your orders to be delivered",
      footer: (
        <div className="d-flex w-100 flex-column flex-md-row justify-content-between px-2">
          <div>
            <Button
              className="me-3"
              onClick={() => handleAction({ address_id: selectedAddressId })}
              disabled={!selectedAddressId}
            >
              Continue
            </Button>
            <Button
              btnType="tertiary"
              className="mt-2 mt-md-0"
              onClick={handleGoBack}
            >
              Back
            </Button>
          </div>
          <Button
            btnType="danger"
            className="mt-2 mt-md-0 bg-white"
            onClick={handleWizardClose}
          >
            Cancel
          </Button>
        </div>
      )
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddressId]);

  if (!availableAddresses) {
    return <Loading />;
  }

  if (availableAddresses && availableAddresses.length === 0) {
    handleAddNewAddress();
  }

  return (
    <Fragment>
      {availableAddresses.map((address) => {
        return (
          <Radio
            key={address.id}
            onChange={() => setSelectedAddressId(address.id)}
            checked={selectedAddressId === address.id}
          >
            <p className="gkit-p-15 m-0">
              {stackedAddress({ countries: countries, ...address })}
            </p>
          </Radio>
        );
      })}
      <Button
        btnType="secondary"
        size="sm"
        className="mt-2 mt-md-0"
        onClick={handleAddNewAddress}
      >
        Add new
      </Button>
    </Fragment>
  );
};

export default SelectDeliveryAddress;
