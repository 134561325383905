import React from "react";

const ShippingInfo = ({ icon, title, subtitle, info }) => {
  return (
    <div className="d-flex justify-content-between my-3">
      <div className="d-flex">
        <img
          src={icon}
          alt={title}
          width={32}
          height={32}
          className="me-3 me-md-4"
        />
        <div className="d-flex flex-column justify-content-center">
          {title && <p className="gkit-p-17 fw-bold mb-0">{title}</p>}
          {subtitle && (
            <p className="gkit-p-15 gkit-light mt-1" style={{ lineHeight: 1 }}>
              {subtitle}
            </p>
          )}
        </div>
      </div>
      {info && (
        <div className="d-flex align-items-center">
          <p className="gkit-p-17 gkit-sm-p-15 m-0">{info}</p>
        </div>
      )}
    </div>
  );
};

export default ShippingInfo;
