import React, { useMemo } from "react";
import SpecialSelect from "./SpecialSelect";
import { getProvincesList } from "shared/utility";
import { useSelector } from "react-redux";

const ProvinceSelect = ({ countryCode }) => {
  const { countries } = useSelector((s) => s.config);
  const provinceList = useMemo(() => {
    if (!countryCode) {
      return null;
    }
    const list = getProvincesList({
      countryCode: countryCode,
      countries: countries
    });
    if (!list || list.length < 1) {
      return null;
    }
    return list.map((province) => ({
      name: province.code,
      label: province.name
    }));
  }, [countryCode, countries]);

  if (!provinceList) {
    return null;
  }

  return (
    <SpecialSelect name="province" label="Province" options={provinceList} />
  );
};

export default ProvinceSelect;
