import api, { extractAPIErrorString } from "api";
import { Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import cx from "classnames";

import Button from "shared/Button";
import ErrorHandling from "shared/ErrorHandling";
import InputField from "shared/Formik/InputField";
import RemoveDiscountModal from "shared/modals/RemoveDiscountModal";
import Separator from "shared/Separator";
import { shortestPrice } from "shared/utility";
import {
  getSubscription,
  updateSubscriptionSuccess
} from "app/subscriptions/actions";

// This section gives the details/description of the discount
const DiscountDetail = ({ discount }) => {
  const discountDescription = useMemo(() => {
    if (!(discount && discount.value_type)) return null;
    if (discount.display_text) return discount.display_text;
    return discount.value_type === "percentage"
      ? `${shortestPrice(discount.value)}% off`
      : `£${shortestPrice(discount.value)} off`;
  }, [discount]);

  const discountIcon =
    discount && discount.value_type === "percentage"
      ? require("assets/icons/discount-percentage.svg").default
      : require("assets/icons/discount-amount.svg").default;

  const discountValue = useMemo(() => {
    if (!discount) return null;
    if (discount.value_type === "percentage") {
      return `${shortestPrice(discount.value)}%`;
    }
    return `- £${shortestPrice(discount.value)}`;
  }, [discount]);

  if (!discount) {
    return null;
  }

  const isSpent = discount.remaining_uses === 0;

  return (
    <div
      className="d-flex gap-3 align-items-start mt-3"
      style={{ maxWidth: 400 }}
    >
      <img src={discountIcon} width={32} height={32} alt="discount icon" />
      <div className="ml-3 d-flex flex-column">
        {discountDescription && (
          <p className="gkit-p-17 gkit-bold mb-0">{discountDescription}</p>
        )}
        {Boolean(discount.remaining_uses) && (
          <p className="gkit-p-13 gkit-light mt-1 mb-0">{`Remaining uses: ${discount.remaining_uses}`}</p>
        )}
        {isSpent && (
          <p className="gkit-p-13 text-danger mt-1 mb-0">{`Discount has run out of uses.`}</p>
        )}
      </div>
      <div
        className={cx(
          "ms-auto gkit-p-17",
          isSpent ? "text-danger" : "text-success"
        )}
      >
        {discountValue}
      </div>
    </div>
  );
};

/**
 * @param {object} discount if the subscription doesn't contain a discount, this will be null
 */

const DiscountSection = ({ discount }) => {
  const { params } = useRouteMatch();
  const [modal, setModal] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const [hasAppliedCode, setHasAppliedCode] = useState(!!discount);
  const subId = useMemo(() => {
    if (!params.id) return null;
    return params.id;
  }, [params]);

  useEffect(() => {
    setHasAppliedCode(!!discount);
  }, [discount]);

  const handleSubmit = ({ code }) => {
    setError(null);
    return api
      .put(`/shop/subscriptions/${subId}/discount`, {
        code
      })
      .then(
        ({ data }) => {
          dispatch(updateSubscriptionSuccess(data));
        },
        err => setError(extractAPIErrorString(err))
      );
  };

  const updateSub = () => {
    return dispatch(getSubscription(subId));
  };

  const handleCodeRemoval = () => {
    setHasAppliedCode(false);
    return updateSub();
  };

  const isSpent = discount?.remaining_uses === 0;

  return (
    <div>
      <Separator />
      <p className="gkit-p-20 fw-bold mb-3">Discount Code</p>
      <Formik
        initialValues={{ code: discount?.code || "" }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form>
            <div className="d-flex gap-3" style={{ maxWidth: 400 }}>
              <InputField
                name="code"
                iconPosition="right"
                className="text-uppercase flex-grow-1"
                disabled={isSubmitting}
                autoComplete="off"
                onChange={e =>
                  setFieldValue("code", String(e.target.value).toUpperCase())
                }
                icon={
                  hasAppliedCode &&
                  discount?.code === values.code &&
                  !isSpent && (
                    <img
                      alt="Successfully Applied Discount"
                      src={require("assets/icons/discount-tick.svg").default}
                    />
                  )
                }
              />
              {(!hasAppliedCode || discount?.code !== values.code) && (
                <Button
                  className="mb-2"
                  type="submit"
                  size="md"
                  disabled={hasAppliedCode && discount?.code === values.code}
                  isLoading={isSubmitting}
                >
                  Apply
                </Button>
              )}
            </div>
            <ErrorHandling error={error} />
            {hasAppliedCode && <DiscountDetail discount={discount} />}
            {/* {hasAppliedCode && (
              <Button
                btnType="danger"
                className="mt-3"
                onClick={() =>
                  api
                    .delete(`/shop/subscriptions/${subId}/discount`)
                    .then(({ data }) => {
                      console.log("DATA: ", data);
                    })
                }
              >
                Remove Discount
              </Button>
            )} */}
          </Form>
        )}
      </Formik>
      <RemoveDiscountModal
        subId={subId}
        isOpen={modal}
        handleAction={handleCodeRemoval}
        handleClose={() => setModal(null)}
      />
    </div>
  );
};

export default DiscountSection;
