import React from "react";
import ButtonPrimary from "shared/ButtonPrimary";

import styles from "./ForgotPassword.module.scss";
import { Link } from "react-router-dom";

const ForgotPasswordSent = () => {
  return (
    <div>
      <h2 className="gkit-h1">Another day, another password.</h2>
      <p className="gkit-p-17">
        A reset password link has been sent to your email address. Make sure to
        check it's not in your spam folder.
      </p>
      <div className={styles.buttonContainer}>
        <ButtonPrimary className="d-block w-100" to="/">
          Log in
        </ButtonPrimary>

        <div className="mt-3 align-self-center">
          <p className={styles.tryAgain}>
            Can't find the email?
            <br />
            <Link className={styles.underline} to="/auth/forgot-password">
              Try resetting again
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordSent;
