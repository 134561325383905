import React, { useCallback, useState } from "react";
import cx from "classnames";
import { useDispatch } from "react-redux";

import { claimLoyaltyReward } from "app/loyalty/actions";

import styles from "./GenerateVoucherModal.module.scss";

import IconAdd from "assets/icons/iconAdd.svg";
import IconSpinner from "assets/icons/iconSpinner.svg";

const GenerateCodeModalContent = ({ onClose, onDiscountGenerated, reward }) => {
  const dispatch = useDispatch();
  const [isGeneratingVoucher, setIsGeneratingVoucher] = useState(false);

  /**
   * Claim the reward, set the internal state to have the generated discount so
   * we can show the 2nd part of this modal flow.
   */
  const onGenerateClick = useCallback(() => {
    if (isGeneratingVoucher) {
      return;
    }

    setIsGeneratingVoucher(true);
    dispatch(claimLoyaltyReward(reward.id))
      .then(({ discount }) => onDiscountGenerated(discount))
      .finally(() => setIsGeneratingVoucher(false));
  }, [dispatch, isGeneratingVoucher, onDiscountGenerated, reward]);

  if (!reward) {
    return null;
  }

  return (
    <div className="modal-body pt-5 d-flex flex-column align-items-center">
      <button
        className={cx(
          styles.pressableArea,
          "d-flex flex-column align-items-center"
        )}
        disabled={isGeneratingVoucher}
        onClick={onGenerateClick}
        type="button"
      >
        <div
          className={cx(
            styles.plusContainer,
            "d-flex flex-column align-items-center justify-content-center"
          )}
        >
          {isGeneratingVoucher ? (
            <img
              alt="Generating Your Code..."
              className={styles.spinner}
              src={IconSpinner}
            />
          ) : (
            <img alt="Generate Code" src={IconAdd} />
          )}
        </div>
        <p className="body-17 fw-bold mt-2 color-charcoal text-center">
          Create new code
        </p>
        <p className="body-13 color-gray-0 text-center mt-2">
          Click here to generate a code and automatically deduct {reward.points}{" "}
          point{reward.points !== 1 ? "s" : ""} from your account - apply the
          code at checkout to get your reward for free.
        </p>
        <p className="body-13 color-green mt-2">
          Spends {reward.points} point{reward.points !== 1 ? "s" : ""}
        </p>
      </button>
      <button
        className="gkit-btn gkit-btn-md gkit-tertiary mt-5 d-block w-100"
        disabled={isGeneratingVoucher}
        onClick={onClose}
        type="button"
      >
        Cancel
      </button>
    </div>
  );
};

export default GenerateCodeModalContent;
