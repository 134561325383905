import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import RewardSection from "./RewardSection";
import cx from "classnames";
import RewardHowToModal from "./RewardHowToModal";
import MobileRewardsSection from "./MobileRewardsSection";
const RewardLanding = ({ onRewardClick }) => {
  const [showHowToModal, setShowHowToModal] = useState(false);

  const { account, rewards } = useSelector(s => s.loyalty);

  const onlineRewards = useMemo(
    () => rewards.filter(reward => reward.type === "online"),
    [rewards]
  );

  return (
    <>
      <div>
        <h2 className="headline-28 color-charcoal">Grind Rewards.</h2>
        <p className="body-17 color-gray-0 mt-3">
          Spend in-store, on our website or through the Grind app to earn points
          towards free coffee. See your balance to the right of the screen.
        </p>

        <button
          className="gkit-btn gkit-btn-md gkit-tertiary mt-3"
          onClick={() => setShowHowToModal(true)}
          type="button"
        >
          How to earn points
        </button>

        {onlineRewards && onlineRewards.length > 0 && (
          <RewardSection
            className={cx("mt-4")}
            currentPoints={account.points_earned}
            onRewardClick={onRewardClick}
            rewards={onlineRewards}
            title="Online Rewards"
          />
        )}

        <MobileRewardsSection className="mt-4" />
      </div>
      <RewardHowToModal
        onClose={() => setShowHowToModal(false)}
        show={showHowToModal}
      />
    </>
  );
};

export default RewardLanding;
