import React from "react";
import Card from "shared/Card";
import Router from "./Router";

const PageContent = () => {
  return (
    <Card className="pb-0 pb-md-4">
      <Router />
    </Card>
  );
};

export default PageContent;
