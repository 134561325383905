import React from "react";
import cx from "classnames";
import styles from "./Footer.module.scss";

import Logo from "assets/img/grind-logo.svg";

import FooterVisa from "assets/icons/footer-visa.svg";
import FooterMastercard from "assets/icons/footer-mastercard.svg";
import FooterAmex from "assets/icons/footer-amex.svg";
import FooterApplePay from "assets/icons/footer-apple-pay.svg";
import FooterPaypalPay from "assets/icons/footer-paypal-pay.svg";

import Instagram from "assets/icons/instagram.svg";
import Twitter from "assets/icons/twitter.svg";
import Facebook from "assets/icons/facebook.svg";
import Spotify from "assets/icons/spotify.svg";

const socialLinks = [
  {
    link: "https://www.instagram.com/grind/",
    icon: Instagram,
    alt: "Instagram"
  },
  {
    link: "https://twitter.com/grind",
    icon: Twitter,
    alt: "Twitter"
  },
  {
    link: "https://facebook.com/grind",
    icon: Facebook,
    alt: "Facebook"
  },
  {
    link: "https://open.spotify.com/user/grindstudio",
    icon: Spotify,
    alt: "Spotify"
  }
];

const paymentOptions = [
  {
    img: FooterAmex,
    alt: "Amex"
  },
  {
    img: FooterApplePay,
    alt: "Apple Pay"
  },
  {
    img: FooterVisa,
    alt: "Visa"
  },
  {
    img: FooterMastercard,
    alt: "Mastercard"
  },
  {
    img: FooterPaypalPay,
    alt: "Paypal"
  }
];

const Footer = () => {
  return (
    <footer className={cx(styles.footer, "footer pt-5 pb-lg-5")}>
      <div className="container">
        <div className="row">
          <div className="col-12 mt-3 mb-5">
            <img width="149" height="30" alt="Grind logo" src={Logo} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 footer-col">
            <h3 className="gkit-h3">Follow us.</h3>
            <p className="social-links">
              {socialLinks.map((socialLink, idx) => (
                <a
                  href={socialLink.link}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.socialLink}
                  key={idx}
                >
                  <span className="sr-only d-none">{socialLink.alt}</span>
                  <img src={socialLink.icon} alt={socialLink.alt} />
                </a>
              ))}
            </p>

            <h3 className="gkit-h3 mt-5 mb-3">Find us.</h3>
            <p className="gkit-p-17 gkit-light">
              Grind
              <br />
              8-10 New North Place
              <br />
              London EC2A 4JA
              <br />
              United Kingdom
            </p>
          </div>

          <div className="col-md-3 footer-col">
            <h3 className="gkit-h3 mt-5 mt-md-0 mb-3">Contact us.</h3>
            <a
              className="gkit-p-17 gkit-light text-decoration-none"
              href="mailto:shop@grind.co.uk"
            >
              shop@grind.co.uk
            </a>

            <h3 className="gkit-h3 mt-5 mb-3">Legal stuff.</h3>

            <a
              className="gkit-p-17 gkit-light text-decoration-none"
              href="https://grind.co.uk/pages/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>

            <a
              className="gkit-p-17 gkit-light text-decoration-none"
              href="https://grind.co.uk/pages/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms &amp; Conditions
            </a>
          </div>

          <div className="col-md-3 footer-col">
            <h3 className="gkit-h3 mt-5 mt-md-0 mb-3">Need help?</h3>
            <a
              className="gkit-p-17 m-0 gkit-light text-decoration-none"
              href="https://grind.co.uk/tools/help"
              target="_blank"
              rel="noreferrer"
            >
              Take a look at our help centre.
            </a>

            <a
              className="gkit-p-17 gkit-light text-decoration-none"
              href="mailto:shop@grind.co.uk"
            >
              Something else? Get in touch at <strong>shop@grind.co.uk</strong>
            </a>
          </div>

          <div className="col-md-3 footer-col">
            <p className="text-center mt-5 mt-md-0 mb-3 mb-md-5">
              {paymentOptions.map(paymentOption => (
                <img
                  src={paymentOption.img}
                  alt={paymentOption.alt}
                  key={paymentOption.alt}
                  className="m-2"
                  width={64}
                  height={40}
                />
              ))}
            </p>
            <p className="gkit-p-15 text-center">
              Copyright © {new Date().getFullYear()}, Grind.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
