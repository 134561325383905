import React, { Fragment, useState } from "react";
import Card from "shared/Card";
import Button from "shared/Button";

import { useSelector } from "react-redux";

import GiftImage from "assets/img/gift.png";
import GiftSubscriptionFlow from "app/gift/GiftSubscriptionFlow";

const GiftSubscriptionCard = ({ className = "" }) => {
  const [redeemingGift, setRedeemingGift] = useState(null);
  const {
    account: { gifts }
  } = useSelector((s) => s);

  if (!gifts || gifts.length === 0) {
    return null;
  }

  return (
    <Fragment>
      {gifts
        .filter((gift) => gift.status === "claimed")
        .map((gift) => {
          return (
            <Card
              className={`mb-3 pt-4 gkit-bg-washed-pink ${className}`}
              key={gift.id}
            >
              <div className="row">
                <div className="col-12 col-xl-9">
                  <div className="d-flex">
                    <h3 className="gkit-h3 gkit-sm-p-20 fw-bold">
                      {gift.iterations} Delivery Gift Subscription Available
                    </h3>
                    <Button
                      size="sm"
                      className="m-auto mt-0 d-block d-md-none w-auto w-unset"
                      onClick={() => setRedeemingGift(gift)}
                    >
                      Redeem
                    </Button>
                  </div>
                  <p className="gkit-p-17 gkit-sm-p-15 gkit-light mt-2 mt-md-3">
                    Redeem your gift and start enjoying Grind coffee.
                  </p>
                  <Button
                    size="md"
                    className="mt-3 d-none d-md-block"
                    onClick={() => setRedeemingGift(gift)}
                  >
                    Redeem
                  </Button>
                </div>
                <div className="d-none d-xl-block col-3 p-0">
                  <img
                    src={GiftImage}
                    alt="Grind gift subscription"
                    width={100}
                    height={100}
                  />
                </div>
              </div>
            </Card>
          );
        })}
      <GiftSubscriptionFlow
        {...redeemingGift}
        setRedeemingGift={setRedeemingGift}
      />
    </Fragment>
  );
};

export default GiftSubscriptionCard;
