import React, { useState } from "react";
import Button from "../Button";
import Modal from "../Modal";
import { Form, Formik } from "formik";
import InputField from "../Formik/InputField";
import * as Yup from "yup";
import ErrorHandling from "shared/ErrorHandling";

const EditBillingPostcodeModal = ({
  isOpen,
  postcode,
  handleAction,
  handleClose
}) => {
  const [successState, setSuccessState] = useState(null);
  const [error, setError] = useState(null);

  function handleCloseAndReset() {
    handleClose();
    setTimeout(() => {
      setError(null);
      setSuccessState(null);
    }, 1000);
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleCloseAndReset}
      title="Edit billing postcode"
      subtitle="Enter the billing address postcode for your new card"
      successState={successState}
    >
      <Formik
        initialValues={{
          zip: postcode
        }}
        validationSchema={Yup.object().shape({
          zip: Yup.string().min(4).required("Postcode is required.")
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleAction({ address: values })
            .then(() =>
              setSuccessState({
                title: "Billing postcode successfully updated"
              })
            )
            .catch((err) => setError(err))
            .finally(() => setSubmitting(false));
        }}
      >
        {({ isValid, isSubmitting }) => (
          <Form className="mt-3">
            <InputField name="zip" type="text" label="Billing postcode" />
            <ErrorHandling error={error} />
            <div className="mt-4">
              <Button
                btnType="primary"
                type="submit"
                disabled={!isValid}
                onClick={handleAction}
                isLoading={isSubmitting}
                className="me-3"
              >
                Update
              </Button>
              <Button
                btnType="tertiary"
                className="mt-2 mt-md-0"
                onClick={handleCloseAndReset}
                type="reset"
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditBillingPostcodeModal;
