import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CreditCardImages } from "shared/ListPaymentOptions";
import CardBlank from "assets/icons/payment-blank.svg";
import Button, { ButtonIcon } from "./Button";
import TrashIcon from "assets/icons/trash.svg";
import { deletePaymentMethod, updatePaymentMethod } from "app/account/actions";
import { updateSubscriptionPaymentMethod } from "app/subscriptions/actions";
import DeleteModal from "./modals/DeleteModal";
import EditBillingPostcodeModal from "./modals/EditBillingPostcodeModal";
import ChangePaymentMethodModal from "./modals/ChangePaymentMethodModal";
import AddNewPaymentMethodModal from "./modals/AddNewPaymentMethodModal";
import { useStripe } from "@stripe/react-stripe-js";

const PaymentCardItem = ({ card }) => (
  <div className="col d-flex flex-row align-items-center">
    <img
      src={CreditCardImages.Regular[card.brand] || CardBlank}
      alt="payment card type"
    />
    <p className="gkit-p-15 mb-0 ms-3">
      {`•••• ${card.last4}`}
      <br />
      {`Expires ${card.exp_month}/${card.exp_year}`}
    </p>
  </div>
);

const PaymentPayPalItem = ({ paymentMethod }) => (
  <div className="col d-flex flex-row align-items-center">
    <img src={CreditCardImages.Regular.PayPal} alt="payment paypal" />
    <p className="gkit-p-15 mb-0 ms-3">
      {paymentMethod.identifier}
      <br />
    </p>
  </div>
);

const PaymentMethod = ({
  paymentMethod,
  subscriptionId = null,
  hasPadding,
  allowEdit = false,
  allowDelete = false,
  allowChange = false
}) => {
  const [modal, setModal] = useState(null);
  const stripe = useStripe();
  const dispatch = useDispatch();

  return (
    <div className={`${hasPadding ? "py-3" : ""}`}>
      <div className="d-flex justify-content-between">
        {paymentMethod.card ? (
          <PaymentCardItem card={paymentMethod.card} />
        ) : (
          <PaymentPayPalItem paymentMethod={paymentMethod} />
        )}
        {allowDelete && (
          <ButtonIcon
            icon={TrashIcon}
            onClick={() => setModal("DeleteModal")}
          />
        )}
      </div>
      {allowEdit && (
        <div className="mt-3">
          {paymentMethod.card && (
            <Button
              btnType="tertiary"
              className="me-md-3 my-1 my-md-0"
              onClick={() => setModal("EditBillingPostcodeModal")}
            >
              Edit billing postcode
            </Button>
          )}
          {allowChange && (
            <Button
              btnType="tertiary"
              className="my-1 my-md-0"
              onClick={() => setModal("ChangePaymentMethodModal")}
            >
              Change payment method
            </Button>
          )}
        </div>
      )}
      {allowEdit && (
        <EditBillingPostcodeModal
          isOpen={modal === "EditBillingPostcodeModal"}
          postcode={paymentMethod.address.zip}
          handleClose={() => setModal(null)}
          handleAction={payload =>
            dispatch(updatePaymentMethod(paymentMethod.id, payload))
          }
        />
      )}
      {allowChange && (
        <ChangePaymentMethodModal
          isOpen={modal === "ChangePaymentMethodModal"}
          paymentMethodId={paymentMethod.id}
          handleClose={() => setModal(null)}
          handleAction={payload =>
            dispatch(
              updateSubscriptionPaymentMethod(subscriptionId, payload, stripe)
            )
          }
          handleAddNew={() => setModal("AddNewPaymentMethodModal")}
        />
      )}
      {allowChange && (
        <AddNewPaymentMethodModal
          isOpen={modal === "AddNewPaymentMethodModal"}
          handleClose={() => setModal(null)}
          subscriptionId={subscriptionId}
        />
      )}
      {allowDelete && (
        <DeleteModal
          isOpen={modal === "DeleteModal"}
          item="Payment Method"
          handleClose={() => setModal(null)}
          handleAction={() => deletePaymentMethod(paymentMethod.id)}
        />
      )}
    </div>
  );
};

export default PaymentMethod;
