import { createStore, applyMiddleware } from "redux";

import logger from "redux-logger";
import thunk from "redux-thunk";

import reducer from "./reducer";
import subscription from "api/subscription";

const { NODE_ENV } = process.env;
const isDev = NODE_ENV === "development";

const middleware = [thunk];
let enhancer;

if (isDev) {
  middleware.push(logger);
}

enhancer = applyMiddleware(...middleware);

const store = createStore(
  reducer,
  // applyMiddleware(thunk, isDev ? logger : (s) => (n) => (a) => n(a))
  enhancer
);

subscription(store);

export default store;
