import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getOrderHistory } from "./actions";
import EmptyOrderState from "shared/EmptyOrderState";

import Loading from "shared/Loading";
import OrderHistoryTable from "./OrderHistoryTable";
import OrderHistoryMobile from "./OrderHistoryMobile";
// import { Lookup } from "react-rainbow-components";

import Separator from "shared/Separator";

const OrderHistory = () => {
  const { orders } = useSelector((s) => s.orderHistory);
  const [isSearching, setSearching] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!orders) {
      dispatch(getOrderHistory());
    }
    setSearching(false)
  }, [dispatch, orders]);

  // TODO: SORT OUT SEARCHING

  // const search = useCallback((searchTerm) => {
  //   setSearching(true);
  //   console.log(searchTerm)
  //   TODO: FIGURE OUT HOW TO SEARCH
  // }, []);

  return (
    <div>
      <p className="gkit-h2 gkit-sm-h3 mb-2">Order history</p>
      <p className="gkit-p-20 gkit-sm-p-17 gkit-light mb-4">
        See all of your previous orders made through your Grind account.
      </p>
      {orders && Object.keys(orders).length === 0 && !isSearching && (
        <EmptyOrderState
          title="No previous orders"
          subtitle="Once you place your first order, you'll be able to view your order history and invoices here."
        />
      )}
      {/* {orders && Object.keys(orders).length !== 0 && (
        <Lookup
          id="lookup-3"
          placeholder="Find"
          size="small"
          debounce
          onSearch={search}
          className="react-rainbow-components mb-4"
        />
      )} */}
      {(!orders || isSearching) && <Loading />}
      {orders &&
        Object.keys(orders).length !== 0 &&
        !isSearching &&
        Object.keys(orders).map((orderGroup, idx) => {
          const groupHeader = orderGroup.split("_");
          return (
            <div key={orderGroup}>
              <p className="gkit-p-20 fw-bold mb-4">
                {groupHeader[0]}{" "}
                {new Date().getFullYear().toString() !== groupHeader[1] &&
                  groupHeader[1]}
              </p>
              <OrderHistoryTable orders={orders[orderGroup]} />
              {orders[orderGroup].map((order) => (
                <OrderHistoryMobile {...order} key={order.order_number} />
              ))}
              {Object.keys(orders).length !== idx + 1 && <Separator />}
            </div>
          );
        })}
    </div>
  );
};

export default OrderHistory;
