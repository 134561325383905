import React, { useState, useMemo } from "react";
import Button from "./Button";
import TruckIcon from "assets/icons/truck.svg";
import moment from "moment";

import ChangeAddressModal from "./modals/ChangeAddressModal";
import SkipDeliveryModal from "./modals/SkipDeliveryModal";

import { useDispatch, useSelector } from "react-redux";

import { updateDeliveryAddress } from "app/subscriptions/actions";

import UnskipDeliveryModal from "./modals/UnskipDeliveryModal";
import { singleLineAddress } from "./utility";
import CreateAddressModal from "./modals/CreateAddressModal";

const ScheduledDelivery = ({
  order_date,
  address,
  id,
  subscriptionId,
  status,
  reloadDeliveries
}) => {
  const {
    auth: { user },
    config: { countries }
  } = useSelector(s => s);

  const subscription = useSelector(s => s.subscriptions.lookup[subscriptionId]);

  const [modal, setModal] = useState();
  const dispatch = useDispatch();

  const addressDisplay = useMemo(
    () => singleLineAddress({ countries: countries, ...address }),
    [address, countries]
  );

  return (
    <div className="d-flex flex-column flex-md-row justify-content-between my-4">
      <div>
        <div className="d-flex align-items-center">
          <img
            src={TruckIcon}
            width={32}
            height={32}
            alt="Scheduled delivery"
            className="me-3"
          />
          <p className="gkit-p-17 fw-bold m-0">
            {moment(order_date, "YYYY-MM-DD").format("D MMMM YYYY")}
          </p>
        </div>
        <p className="gkit-p-15 gkit-light mt-2">{addressDisplay}</p>
      </div>
      {status === "planned" ? (
        <div className="d-flex mt-3 mt-md-0 flex-column flex-md-row">
          <div>
            <Button
              btnType="tertiary"
              className="me-3"
              onClick={() => setModal("ChangeAddressModal")}
            >
              Edit address
            </Button>
          </div>
          {subscription?.gift ? null : (
            <div>
              <Button
                btnType="secondary"
                onClick={() => setModal("SkipDeliveryModal")}
                className="mt-2 mt-md-0"
              >
                Skip delivery
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className="d-flex mt-3 mt-md-0 flex-column flex-md-row">
          <Button
            onClick={() => setModal("UnskipDeliveryModal")}
            btnType="secondary"
            className="m-auto"
          >
            Restore delivery
          </Button>
        </div>
      )}
      <ChangeAddressModal
        isOpen={modal === "ChangeAddressModal"}
        {...address}
        handleClose={() => setModal(null)}
        handleAction={payload =>
          dispatch(updateDeliveryAddress(subscriptionId, id, payload)).then(
            () => {
              setModal(null);
              reloadDeliveries();
            }
          )
        }
        handleAddNewAddress={() => setModal("CreateAddressModal")}
        countryCode={address.country_code}
      />
      <SkipDeliveryModal
        isOpen={modal === "SkipDeliveryModal"}
        subscriptionId={subscriptionId}
        deliveryId={id}
        handleClose={() => setModal(null)}
        updateSchedule={reloadDeliveries}
      />
      <UnskipDeliveryModal
        isOpen={modal === "UnskipDeliveryModal"}
        subscriptionId={subscriptionId}
        deliveryId={id}
        handleClose={() => setModal(null)}
        updateSchedule={reloadDeliveries}
      />
      <CreateAddressModal
        isOpen={modal === "CreateAddressModal"}
        handleClose={() => setModal(null)}
        {...user}
        onAddressAdded={payload => {
          dispatch(updateDeliveryAddress(subscriptionId, id, payload)).then(
            () => {
              setModal(null);
              reloadDeliveries();
            }
          );
        }}
        countryCode={address.country_code}
      />
    </div>
  );
};

export default ScheduledDelivery;
