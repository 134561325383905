import api from "./index";

const apiSubscription = (store) => {
  let hasAccessToken = false;

  const updateAPI = (auth) => {
    let needsUpdate = false;
    const storeHasAccessToken = auth.accessToken ? true : false;
    if (hasAccessToken !== storeHasAccessToken) {
      hasAccessToken = storeHasAccessToken;
      needsUpdate = true;
    }

    if (!needsUpdate) return;

    if (hasAccessToken) {
      api.defaults.headers = {
        Authorization: `Bearer ${auth.accessToken}`
      };
    } else {
      api.defaults.headers = {};
    }
  };

  store.subscribe(() => {
    const { auth } = store.getState();
    updateAPI(auth);
  });
};

export default apiSubscription;
