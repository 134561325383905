import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "../Button";
import Modal from "../Modal";
import moment from "moment";

import { useDispatch } from "react-redux";

import {
  getSubscription,
  getSubscriptionSchedule,
  rescheduleDelivery,
  skipDelivery
} from "app/subscriptions/actions";
import ErrorHandling from "shared/ErrorHandling";
import { useStripe } from "@stripe/react-stripe-js";
import Bugsnag from "@bugsnag/js";

const GetShipmentNowModal = ({ isOpen, handleClose, subscriptionId }) => {
  const [error, setError] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const nextDeliveryDate = moment().add(0, "day").startOf("day").toDate();
  const [successState, setSuccessState] = useState(null);
  const stripe = useStripe();

  const [schedule, setSchedule] = useState(null);

  useEffect(() => {
    if (schedule) {
      return;
    }

    dispatch(getSubscriptionSchedule(subscriptionId)).then(data => {
      setSchedule(data);
    });
  }, [dispatch, schedule, subscriptionId]);

  const skippedShipment = useMemo(() => {
    if (!schedule || !schedule.length) {
      return null;
    }

    return schedule[0].status === "skipped" ? schedule[0] : null;
  }, [schedule]);

  function handleCloseAndReset() {
    handleClose();
    setTimeout(() => {
      setError(null);
      setSuccessState(null);
    }, 1000);
  }

  const onSubmit = useCallback(async () => {
    if (isSubmitting) {
      return;
    }

    setSubmitting(true);

    // If we've got a skipped shipment, unskip it first.
    if (skippedShipment) {
      try {
        await dispatch(skipDelivery(subscriptionId, skippedShipment.id, false));
      } catch (e) {
        Bugsnag.notify(e);
        setSubmitting(false);
        return;
      }
    }

    // Reschedule the delivery
    try {
      await dispatch(
        rescheduleDelivery(
          subscriptionId,
          {
            next_date: nextDeliveryDate
          },
          stripe
        )
      );

      setSuccessState({
        title: "We'll have your coffee on its way to you soon!",
        subtitle:
          "A new order has been processed for you and will be despatched within 1-2 working days."
      });
    } catch (e) {
      // Notify of any error
      Bugsnag.notify(e);
      setError(e);
      // Failing an immediate payment will drop you into failing status
      dispatch(getSubscription(subscriptionId));
    } finally {
      setSubmitting(false);
    }
  }, [
    dispatch,
    isSubmitting,
    nextDeliveryDate,
    skippedShipment,
    stripe,
    subscriptionId
  ]);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleCloseAndReset}
      title="Process next order now"
      subtitle="We will process your next shipment right away"
      successState={successState}
    >
      {skippedShipment ? (
        <div className="alert alert-warning gkit-p-13">
          Your next scheduled order is currently skipped. Proceeding with this
          action will unskip this order and process it immediately.
        </div>
      ) : null}
      <ErrorHandling error={error} />
      <div className="mt-4">
        <Button
          btnType="primary"
          disabled={error}
          isLoading={isSubmitting || !schedule}
          onClick={onSubmit}
          className="me-3"
        >
          Yes, I want it now
        </Button>
        <Button
          btnType="tertiary"
          className="mt-2 mt-md-0"
          onClick={handleCloseAndReset}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default GetShipmentNowModal;
