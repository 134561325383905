/* eslint-disable array-callback-return */
import React, { useState } from "react";
import FrequencySection from "shared/config/FrequencySection";
import Button from "../Button";
import Modal from "../Modal";

import { useDispatch } from "react-redux";

import { updateFrequency } from "app/subscriptions/actions";
import ErrorHandling from "shared/ErrorHandling";
import moment from "moment";

const EditFrequencyModal = ({
  currentFrequency,
  isOpen,
  handleClose,
  subscriptionId
}) => {
  const dispatch = useDispatch();
  const [frequency, setFrequency] = useState(currentFrequency);
  const [showMore, setShowMore] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [successState, setSuccessState] = useState(null);

  function handleCloseAndReset() {
    handleClose();
    setTimeout(() => {
      setError(null);
      setSuccessState(null);
      setShowMore(false);
    }, 1000);
  }

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      handleClose={handleCloseAndReset}
      title="Edit frequency"
      subtitle="How often do you want to receive your coffee deliveries?"
      successState={successState}
    >
      <FrequencySection
        frequency={frequency}
        onFrequencySelect={setFrequency}
        showMore={showMore}
      />
      <div className="row"></div>
      <div className="d-flex flex-column flex-md-row justify-content-between">
        <ErrorHandling error={error} />
        <div className="mt-4 order-2 order-md-1">
          <Button
            btnType="primary"
            isLoading={isSubmitting}
            onClick={() => {
              setSubmitting(true);
              dispatch(
                updateFrequency(subscriptionId, {
                  interval: frequency.interval,
                  period: frequency.period
                })
              )
                .then(data => {
                  setSuccessState({
                    title:
                      "Your delivery frequency has successfully been updated",
                    subtitle: `You will receive your coffee every ${
                      data.interval > 1 ? data.interval : ""
                    } ${data.period}${
                      data.interval > 1 ? "s" : ""
                    } - with your next charge date scheduled for the ${moment(
                      data.next_dispatch
                        ? data.next_dispatch.order_date
                        : data.paused_until
                    ).format("DD/MM/YYYY")}.`
                  });
                })
                .catch(err => setError(err))
                .finally(() => setSubmitting(false));
            }}
            className="me-0 me-md-3"
          >
            Update
          </Button>
          <Button
            btnType="tertiary"
            className="mt-2 mt-md-0"
            onClick={handleCloseAndReset}
          >
            Cancel
          </Button>
        </div>
        <Button
          btnType="secondary"
          className="mt-auto mb-0 me-0 order-1 order-md-2"
          onClick={() => setShowMore(!showMore)}
        >
          {!showMore ? "More options" : "Fewer options"}
        </Button>
      </div>
    </Modal>
  );
};

export default EditFrequencyModal;
