import React from "react";
import { Input } from "react-rainbow-components";

const Radio = ({ description, id, children, onChange, ...props }) => {
  return (
    <div
      className="react-rainbow-components react-rainbow-radio my-4"
      onClick={() => onChange(id)}
    >
      <div className="d-flex">
        <Input type="radio" {...props} />
        {children}
      </div>
      {description && (
        <p className="gkit-p-15 gkit-light ms-4 ps-2">{description}</p>
      )}
    </div>
  );
};

export const RadioGroup = ({ label, options, values, onChange }) => {
  return (
    <div>
      <p className="gkit-p-20 fw-bold">{label}</p>
      {options.map((option, idx) => (
        <Radio
          {...option}
          checked={values[option.name]}
          key={idx}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

export default Radio;
