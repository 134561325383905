import React from "react";
import { useSelector } from "react-redux";
import InnerNavbar from "shared/InnerNavbar";
import Router from "./Router";

const accountTabs = [
  {
    to: "/account/addresses",
    label: "Addresses",
    hash: "addesses"
  },
  {
    to: "/account/payment-methods",
    label: "Payment methods",
    hash: "payment-methods"
  },
  {
    to: "/account/notifications",
    label: "Notifications",
    hash: "notifications"
  },
  {
    to: "/account/profile",
    label: "Profile details",
    hash: "profile"
  }
];

const Account = () => {
  const membershipNumber = useSelector(s => s.loyalty.account?.number);
  return (
    <div>
      <p className="gkit-h2 gkit-sm-h3 mb-2">Account</p>
      {membershipNumber && (
        <p className="gkit-p-20 gkit-sm-p-17 gkit-light mb-4">
          Membership number: <strong>{membershipNumber}</strong>
        </p>
      )}

      <InnerNavbar tabs={accountTabs} Router={Router} />
      <div className="d-none d-md-block">
        <Router />
      </div>
    </div>
  );
};

export default Account;
