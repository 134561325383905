import React from "react";
import GrindPackagesImage from "assets/img/packages.png";
import Button from "./Button";

const EmptyOrderState = ({ title, subtitle, getStarted = true }) => {
  return (
    <div className="p-4 text-center m-auto" style={{ maxWidth: 370 }}>
      <img
        src={GrindPackagesImage}
        width={155}
        height={148}
        alt="Grind packages"
      />
      <p className="gkit-p-20 fw-bold mt-4 mb-2">{title}</p>
      {subtitle && <p className="gkit-p-15 mt-0">{subtitle}</p>}
      {getStarted && (
        <a href="https://grind.co.uk/#create-plan">
          <Button size="md mt-3">Get started</Button>
        </a>
      )}
    </div>
  );
};

export default EmptyOrderState;
