import React, { useState } from "react";
import { Drawer } from "react-rainbow-components";

import Logo from "assets/img/grind-logo.svg";
import ProfileIcon from "assets/icons/profile.svg";
import LogoutIcon from "assets/icons/logout.svg";
import MoneyBackIcon from "assets/icons/money-back.svg";

import { submitLogout } from "../auth/actions";
import { useDispatch, useSelector } from "react-redux";

import { tabs } from "./Navbar";
import { Redirect } from "react-router-dom";

const Hamburger = ({ isOpen, setIsOpen, user }) => {
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState();
  const {
    account: { referralData }
  } = useSelector(s => s);

  function handleNavigation(to) {
    setRedirect(to);
    setIsOpen(false);
  }

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: redirect
        }}
      />
    );
  }

  return (
    <Drawer
      id="hamburger-menu"
      className="gkit-bg-pink"
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
      <div className="d-flex justify-content-center p-2">
        <img className="logo" src={Logo} alt="Grind Logo" />
      </div>
      {user && (
        <div className="d-flex py-3 align-items-center">
          <img src={ProfileIcon} alt="Profile icon" className="me-3" />
          <p className="gkit-p-15 fw-bold">
            {user.first_name} {user.last_name}
          </p>
        </div>
      )}
      {referralData?.credit && parseInt(referralData.credit.amount) > 0 && (
        <div className="d-flex py-3 align-items-center">
          <img
            src={MoneyBackIcon}
            alt="Account credit"
            width={32}
            height={32}
            className="me-3"
          />
          <div>
            <p className="gkit-p-15 fw-bold mb-0">Account Credit</p>
            <p className="gkit-p-17 mt-0">{referralData.credit.formatted}</p>
          </div>
        </div>
      )}
      {tabs.map(tab => (
        <div
          className="d-flex py-3 align-items-center cursor-pointer"
          onClick={() => handleNavigation(tab.to)}
          key={tab.to}
        >
          <p className="gkit-p-15 fw-bold">{tab.label}</p>
        </div>
      ))}
      <div
        className="d-flex py-3 align-items-center cursor-pointer"
        onClick={() => dispatch(submitLogout())}
      >
        <img
          src={LogoutIcon}
          alt="Logout"
          width={24}
          height={24}
          className="me-3"
        />
        <p className="gkit-p-15 fw-bold">Logout</p>
      </div>
    </Drawer>
  );
};

export default Hamburger;
