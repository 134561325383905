import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import Card from "shared/Card";
import cx from "classnames";

import { useSelector } from "react-redux";

import MoneyBackIcon from "assets/icons/money-back.svg";

import styles from "./Navbar.module.scss";

const tabs = [
  {
    to: "/account",
    label: "Account"
  },
  {
    to: "/subscriptions",
    label: "Subscriptions"
  },
  {
    to: "/order-history",
    label: "Orders"
  },
  {
    to: "/rewards",
    label: "Rewards"
  }
];

const NavigationLink = ({ to, icon, activeIcon, label, active }) => {
  return (
    <NavLink
      className={cx(styles.navLink)}
      activeClassName={styles.active}
      to={to}
    >
      <p className="gkit-p-17 gkit-sm-p-15 fw-bold m-0">{label}</p>
    </NavLink>
  );
};

const Navbar = () => {
  const location = useLocation();
  const {
    account: { referralData }
  } = useSelector(s => s);
  return (
    <Card className={`bg-white border-0 p-0 d-flex ${styles.navbarCard}`}>
      <div className="d-flex justify-content-between w-100">
        <div className={styles.navbar}>
          {tabs.map(tab => (
            <NavigationLink
              {...tab}
              key={tab.label}
              // If the url starts as tab url then consider the tab active
              active={
                ["/", location.pathname.split("/")[1]].join("") === tab.to
              }
            />
          ))}
        </div>
        {referralData?.credit && parseInt(referralData.credit.amount) > 0 && (
          <div className="d-flex m-3 border-start ps-3 d-none d-md-flex">
            <img
              src={MoneyBackIcon}
              alt="Account credit"
              width={32}
              height={32}
            />
            <div className="d-flex flex-column justify-content-center ms-2">
              <p className="gkit-p-13 fw-bold m-0">Account Credit</p>
              <p className="gkit-p-17 fw-bold m-0">
                {referralData.credit.formatted}
              </p>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

export default Navbar;
export { tabs };
