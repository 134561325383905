import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import AddToSubscriptionModal from "./rewardsPage/AddToSubscriptionModal";
import GenerateVoucherModal from "./rewardsPage/GenerateVoucherModal";

import JoinRewardsProgram from "./rewardsPage/JoinRewardsProgram";
import OnlineRewardModal from "./rewardsPage/OnlineRewardModal";
import RewardLanding from "./rewardsPage/RewardLanding";

const RewardsPage = () => {
  const [selectedReward, setSelectedReward] = useState(null);

  const [isSelectingRedemptionMethod, setIsSelectingRedemptionMethod] =
    useState(false);
  const [isGeneratingVoucher, setIsGeneratingVoucher] = useState(false);
  const [isAddingToSubscription, setIsAddingToSubscription] = useState(false);

  const {
    loyalty,
    subscriptions: { subscriptions }
  } = useSelector(s => s);

  const onRewardClick = useCallback(reward => {
    setSelectedReward(reward);
    setIsSelectingRedemptionMethod(true);
  }, []);

  const activeSubscriptions = useMemo(
    () => subscriptions?.filter(sub => sub.status === "active") || [],
    [subscriptions]
  );

  // Prompt to join the program if they aren't part of it
  if (!loyalty.account) {
    return <JoinRewardsProgram />;
  }

  // Rewards Page!!!
  return (
    <>
      <RewardLanding onRewardClick={onRewardClick} />

      {/**
       * This modal is for ONLINE Rewards, and will basically ask the customer
       * if they'd like to generate a voucher or add the gift to an existing
       * subscription (if they have an existing active subscription).
       */}
      <OnlineRewardModal
        hasActiveSubscriptions={activeSubscriptions.length > 0}
        onClose={() => setIsSelectingRedemptionMethod(false)}
        onAddToSubscriptionSelect={rewardDetail => {
          setIsSelectingRedemptionMethod(false);
          setSelectedReward(rewardDetail);
          setIsAddingToSubscription(true);
        }}
        onVoucherSelect={() => {
          setIsSelectingRedemptionMethod(false);
          setIsGeneratingVoucher(true);
        }}
        reward={selectedReward?.type === "online" && selectedReward}
        show={Boolean(
          selectedReward?.type === "online" && isSelectingRedemptionMethod
        )}
      />

      <GenerateVoucherModal
        onClose={() => setIsGeneratingVoucher(false)}
        reward={selectedReward}
        show={Boolean(selectedReward && isGeneratingVoucher)}
      />

      <AddToSubscriptionModal
        subscriptions={activeSubscriptions}
        onClose={() => setIsAddingToSubscription(false)}
        reward={selectedReward}
        show={Boolean(selectedReward && isAddingToSubscription)}
      />
    </>
  );
};

export default RewardsPage;
