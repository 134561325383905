import React, { useCallback, useMemo, useState } from "react";
import Modal from "../Modal";
import Button from "../Button";
import { Textarea, Picklist, Option } from "react-rainbow-components";

import { useDispatch } from "react-redux";
import {
  cancelSubscription,
  updateSubscriptionSuccess
} from "app/subscriptions/actions";
import ErrorHandling from "shared/ErrorHandling";
import api, { extractAPIErrorString } from "api";
import cx from "classnames";

const cancelReasonOptions = [
  {
    label: "This is too expensive.",
    value: "too_expensive",
    prompt: {
      label:
        "We'd love you to continue your Grind subscription, how does 20% off your next 3 orders sound?",
      ctas: [
        {
          label: "Apply Code",
          action: {
            type: "discount_code",
            code: "SAVEX20"
          }
        }
      ]
    }
  },
  {
    label: "My introductory offer has ended.",
    value: "offer_ended",
    prompt: {
      label:
        "We'd love you to continue your Grind subscription, how does 20% off your next 3 orders sound?",
      ctas: [
        {
          label: "Apply Code",
          action: {
            type: "discount_code",
            code: "STAYX20"
          }
        }
      ]
    }
  },
  { label: "I didn't want a subscription.", value: "accident" },
  { label: "I've already got too much coffee.", value: "too_much" },
  {
    label: "My coffee needs have changed and I no longer use pods.",
    value: "needs_changed"
  },
  {
    label: "My coffee needs have changed and I want to take a break.",
    value: "need_break"
  },
  { label: "I prefer another brand.", value: "want_different_product" },
  { label: "I didn't like the coffee.", value: "not_my_taste" },
  { label: "I now buy Grind from Tesco or other supermarket.", value: "buy_from_supermarket" },
].sort(() => (Math.random() > 0.5 ? 1 : -1));

cancelReasonOptions.push({ label: "Other reason...", value: "other" });

const cancelReasonLookup = cancelReasonOptions.reduce((acc, option) => {
  acc[option.value] = option;
  return acc;
}, {});

const CancelSubscriptionModal = ({
  isOpen,
  handleClose,
  handleRetainActions,
  currentStatus,
  subscriptionId
}) => {
  const dispatch = useDispatch();
  const [successState, setSuccessState] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedPickerValue, setSelectedPickerValue] = useState(null);
  const [loadingProperty, setLoadingProperty] = useState(null);
  const [comment, setComment] = useState(null);
  const selectedValue = useMemo(
    () => selectedPickerValue?.value || null,
    [selectedPickerValue?.value]
  );
  const selectedOption = useMemo(() => {
    return cancelReasonLookup[selectedValue] || null;
  }, [selectedValue]);
  const [error, setError] = useState(null);

  const handleCloseAndReset = useCallback(() => {
    handleClose();
    setTimeout(() => {
      setSelectedPickerValue(null);
      setSuccessState(null);
      setError(null);
    }, 1000);
  }, [handleClose]);

  const onCtaClick = useCallback(
    action =>
      new Promise(resolve => {
        if (action.type === "discount_code" && action.code) {
          api
            .put(`/shop/subscriptions/${subscriptionId}/discount`, {
              code: action.code
            })
            .then(
              ({ data }) => {
                dispatch(updateSubscriptionSuccess(data));
                handleCloseAndReset();
              },
              err => setError(extractAPIErrorString(err))
            )
            .finally(() => resolve());
        }
      }),

    [dispatch, handleCloseAndReset, subscriptionId]
  );

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      handleClose={handleCloseAndReset}
      title="Cancel Subscription"
      subtitle="We're sorry to hear you're looking to cancel your subscription, but
    could you please let us know why?"
      successState={successState}
    >
      <div className="react-rainbow-components">
        <Picklist
          id="reason"
          onChange={value => setSelectedPickerValue(value)}
          value={selectedPickerValue}
          label="Select a reason"
          placeholder="Please select a reason..."
        >
          {cancelReasonOptions.map(cancelOption => (
            <Option
              label={cancelOption.label}
              name={cancelOption.value}
              value={cancelOption.value}
              key={cancelOption.value}
            />
          ))}
        </Picklist>

        {selectedOption?.prompt && (
          <div className="gkit-bg-pink flex-column flex-md-row mt-3 p-3 d-flex align-items-center rounded gap-3">
            <p className="gkit-p-17 flex-grow-1">
              {selectedOption.prompt.label}
            </p>

            {selectedOption.prompt.ctas && (
              <div className="flex-grow-1 flex-shrink-0 d-flex flex-column">
                {selectedOption.prompt.ctas.map((cta, idx) => {
                  const ctaId = `${selectedOption.value}-cta${idx}`;

                  return (
                    <Button
                      className={cx(
                        "flex-shrink-0",
                        idx === 0 ? "mt-0" : "mt-2"
                      )}
                      isLoading={loadingProperty === ctaId}
                      key={`${cta.type}-${idx}`}
                      onClick={() => {
                        setLoadingProperty(ctaId);
                        onCtaClick(cta.action).finally(() => {
                          setLoadingProperty(null);
                        });
                      }}
                    >
                      {cta.label}
                    </Button>
                  );
                })}
              </div>
            )}
          </div>
        )}

        {selectedValue === "other" && (
          <Textarea
            className="mt-4"
            id="other_comments"
            rows={4}
            label="Let us know how we can improve"
            labelAlignment="left"
            onBlur={event => setComment(event.target.value)}
          />
        )}
      </div>
      {selectedValue === "accident" && (
        <div className="gkit-bg-pink flex-column flex-md-row mt-3 p-3 d-flex align-items-center rounded gap-3">
          <p className="gkit-p-17 flex-grow-1">
            Did you know you can buy Grind as a one time purchase?
          </p>

          <Button
            to="//grind.co.uk/products/nespressopods"
            className="flex-shrink-0 mt-2 mt-md-0"
          >
            Shop now
          </Button>
        </div>
      )}
      {selectedValue === "not_my_taste" && (
        <div className="gkit-bg-pink flex-column flex-md-row mt-3 p-3 d-flex align-items-center rounded gap-3">
          <p className="gkit-p-17 flex-grow-0">
            Did you know we now offer a variety of blends across both our Pods
            and Bean &amp; Ground range.
          </p>

          <Button
            className="flex-shrink-0 mt-2 mt-md-0"
            onClick={() => handleRetainActions("changeBlend")}
          >
            Swap your coffee
          </Button>
        </div>
      )}
      {selectedValue === "want_different_product" && (
        <div className="gkit-bg-pink flex-column flex-md-row mt-3 p-3 d-flex align-items-center rounded gap-3">
          <p className="gkit-p-17 flex-grow-0">
            Grind coffee pods are completely home-compostable and plastic-free.
            By choosing Grind, you're doing good by the planet.
          </p>

          <Button
            to="//grind.co.uk/pages/grind-sustainability"
            className="flex-shrink-0 mt-2 mt-md-0"
          >
            Find out more
          </Button>
        </div>
      )}
      {selectedValue === "too_much" && (
        <div className="gkit-bg-pink flex-column flex-md-row mt-3 p-3 d-flex rounded gap-3">
          <p className="gkit-p-17 flex-grow-0">
            Did you know you can skip or pause your subscription at any time?
            You can also update the timing of your deliveries if you're
            receiving coffee too frequently.
          </p>

          <div className="flex-grow-1 flex-shrink-0 d-flex flex-column">
            {currentStatus !== "paused" && (
              <Button
                className="mt-2 mt-md-0 flex-shrink-0"
                onClick={() => handleRetainActions("SkipDeliveryModal")}
              >
                Skip next delivery
              </Button>
            )}

            <Button
              className={`flex-shrink-0 mt-2${currentStatus === "paused" ? " mt-md-0" : ""
                }`}
              onClick={() => handleRetainActions("PauseSubscriptionModal")}
            >
              Pause subscription
            </Button>

            <Button
              className="mt-2 flex-shrink-0"
              onClick={() => handleRetainActions("EditFrequencyModal")}
            >
              Change frequency
            </Button>
          </div>
        </div>
      )}
      {selectedValue === "needs_changed" && (
        <div className="gkit-bg-pink flex-column flex-md-row mt-3 p-3 d-flex align-items-center rounded gap-3">
          <p className="gkit-p-17 flex-grow-1">
            Did you know we have a Bean &amp; Ground range, roasted in London
            and made from the same beans we use across our London cafes.
          </p>

          <Button
            className="flex-shrink-0 mt-2 mt-md-0"
            onClick={() => handleRetainActions("changeBlend")}
          >
            Change subscription
          </Button>
        </div>
      )}
      {selectedValue === "need_break" && (
        <div className="gkit-bg-pink flex-column flex-md-row mt-3 p-3 d-flex rounded gap-3">
          <p className="gkit-p-17 flex-grow-1">
            Did you know you can skip or pause your subscription at any time?
          </p>

          <div className="flex-grow-0 flex-shrink-0 d-flex flex-column">
            {currentStatus !== "paused" && (
              <Button
                className="mt-2 mt-md-0 flex-shrink-0"
                onClick={() => handleRetainActions("SkipDeliveryModal")}
              >
                Skip next delivery
              </Button>
            )}

            <Button
              className={`flex-shrink-0 mt-2${currentStatus === "paused" ? " mt-md-0" : ""
                }`}
              onClick={() => handleRetainActions("PauseSubscriptionModal")}
            >
              Pause subscription
            </Button>
          </div>
        </div>
      )}
      <ErrorHandling error={error} />
      <div className="mt-4">
        <Button
          className="me-3 gkit-bg-red"
          disabled={!selectedValue}
          isLoading={isSubmitting}
          onClick={() => {
            setSubmitting(true);

            const payload = {
              reason: selectedValue
            };

            if (comment) {
              payload.comment = comment;
            }

            dispatch(cancelSubscription(subscriptionId, payload))
              .then(() =>
                setSuccessState({
                  title: "Your subscription has now been cancelled"
                })
              )
              .catch(err => setError(err))
              .finally(() => setSubmitting(false));
          }}
        >
          Cancel subscription
        </Button>
        <Button
          btnType="tertiary"
          className="mt-2 mt-md-0"
          onClick={handleCloseAndReset}
        >
          Go back
        </Button>
      </div>
    </Modal>
  );
};

export default CancelSubscriptionModal;
