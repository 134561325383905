import React, { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";

import EmptyOrderState from "shared/EmptyOrderState";

import Loading from "shared/Loading";
import AllSubscriptions from "./AllSubscriptions";
import ArchivedSubscriptions from "./ArchivedSubscriptions";

const PageContent = ({ subscriptions }) => {
  const { all, cancelled } = useMemo(
    () => ({
      all: subscriptions
        ? subscriptions.filter(sub => sub.status !== "cancelled")
        : null,
      cancelled: subscriptions
        ? subscriptions.filter(sub => sub.status === "cancelled")
        : null
    }),
    [subscriptions]
  );

  if (!all) {
    return <Loading />;
  } else if (all.length === 0) {
    return (
      <EmptyOrderState
        title="Looks like you don't currently have a Grind subscription"
        subtitle="Once you create your first subscription with us, you'll then be able to see it here. "
      />
    );
  } else {
    return (
      <Fragment>
        
        <AllSubscriptions subscriptions={all} />
        {cancelled && cancelled.length > 0 && (
          <ArchivedSubscriptions subscriptions={cancelled} />
        )}
      </Fragment>
    );
  }
};

const Subscriptions = () => {
  const subscriptions = useSelector(s => s.subscriptions.subscriptions);

  return (
    <div>
      <p className="headline-28 mb-0">Subscriptions</p>
      <p className="body-17 gkit-light mt-3">
        View and update your Grind subscriptions, so you can make it work best
        for you, at home or wherever you are.
      </p>
      <PageContent subscriptions={subscriptions} />
    </div>
  );
};

export default Subscriptions;
