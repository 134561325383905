import React from "react";
import cx from "classnames";

import styles from "./AvailableRewardCount.module.scss";

const AvailableRewardCount = ({
  availablePoints,
  className,
  rewardPointCost
}) => {
  const rewardCount = Math.floor(availablePoints / rewardPointCost);

  if (!rewardCount) {
    return null;
  }

  return (
    <div className={cx(styles.container, className)}>
      <p className="gkit-p-17 gkit-white fw-medium">×{rewardCount}</p>
    </div>
  );
};

export default AvailableRewardCount;
