import React from "react";
import { Switch, Route } from "react-router-dom";
import Details from "./Details";
import Plan from "./Plan";
import Deliveries from "./Deliveries";
import NotFound from "shared/NotFound";

const routes = [
  {
    exact: true,
    path: "/subscriptions/:id/details",
    component: Details
  },
  {
    exact: false,
    path: "/subscriptions/:id/plan/:action?",
    component: Plan
  },
  {
    exact: false,
    path: "/subscriptions/:id/deliveries",
    component: Deliveries
  },
  {
    component: NotFound
  }
];

const Router = () => {
  return (
    <Switch>
      {routes.map(({ ...props }, index) => (
        <Route key={index} {...props} />
      ))}
    </Switch>
  );
};

export default Router;
