import React, { Fragment, useState } from "react";
import CustomDatePicker from "../inputs/CustomDatePicker";
import { RadioGroup } from "react-rainbow-components";
import Modal from "../Modal";
import Button from "../Button";
import moment from "moment";
import {
  reactivateSubscription,
  resumeSubscription
} from "app/subscriptions/actions";
import { useDispatch } from "react-redux";
import ErrorHandling from "shared/ErrorHandling";

const options = [
  { value: "today", label: "Today" },
  { value: "pick", label: "Pick a date" }
];

const currentStatus = {
  cancelled: {
    title: "Reactivate Subscription",
    confirmationTitle: "Succesfully reactivated subscription",
    subtitle: "When would you like your subscription to restart?",
    action: reactivateSubscription
  },
  paused: {
    title: "Resume Subscription",
    confirmationTitle: "Subscription successfully resumed",
    subtitle: "When would you like your subscription to resume?",
    action: resumeSubscription
  },
  active: {}
};

const ActivateSubscriptionModal = ({
  isOpen,
  status,
  subscriptionId,
  handleClose
}) => {
  const dispatch = useDispatch();
  const minDate = moment().add(1, "day").startOf("day").toDate();
  const maxDate = moment().add(3, "month").startOf("day").toDate();

  const [radio, setRadio] = useState("today");
  const [successState, setSuccessState] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [nextDate, setNextDate] = useState(minDate);

  if (!currentStatus[status] && !successState) {
    return null;
  }

  const { title, subtitle, action, confirmationTitle } = currentStatus[status];

  function handleAction(values) {
    setSubmitting(true);
    dispatch(action(subscriptionId, values)).then(
      (data) => {
        setSuccessState({
          title: confirmationTitle,
          subtitle: `Your next order will be processed on ${moment(
            data.next_dispatch.order_date,
            "YYYY-MM-DD"
          ).format("DD/MM/YYYY")}`
        });
        setSubmitting(false);
      },
      (err) => setError(err)
    );
  }

  function handleCloseAndReset() {
    handleClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleCloseAndReset}
      title={title}
      subtitle={subtitle}
      successState={successState}
    >
      <RadioGroup
        id="pick-start-date"
        options={options}
        value={radio}
        onChange={(e) => setRadio(e.target.value)}
      />
      {radio === "pick" && (
        <Fragment>
          <CustomDatePicker
            name="next_date"
            value={nextDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={setNextDate}
          />
          <ErrorHandling error={error} />
          <div className="mt-4">
            <Button
              className="me-3"
              onClick={() => handleAction({ next_date: nextDate })}
              isLoading={isSubmitting}
            >
              Save
            </Button>
            <Button
              btnType="tertiary"
              className="mt-2 mt-md-0"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </Fragment>
      )}
      {radio === "today" && (
        <Fragment>
          <ErrorHandling error={error} />
          <div className="mt-4">
            <Button
              className="me-3"
              isLoading={isSubmitting}
              onClick={() => handleAction({ next_date: minDate })}
            >
              Update
            </Button>
            <Button
              btnType="tertiary"
              className="mt-2 mt-md-0"
              onClick={handleCloseAndReset}
              type="reset"
            >
              Cancel
            </Button>
          </div>
        </Fragment>
      )}
    </Modal>
  );
};

export default ActivateSubscriptionModal;
