import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Form, Formik } from "formik";

import StripeCardField from "./StripeCardField";
import * as Yup from "yup";
import InputField from "shared/Formik/InputField";
import Button from "../Button";

import LockIcon from "assets/icons/lock.svg";

const CollectCardDetails = ({
  onEntityAdded,
  handleClose,
  isPaying,
  setPaying
}) => {
  const [error, setError] = useState(null);
  const elements = useElements();
  const stripe = useStripe();

  const createPaymentMethod = cardElement =>
    new Promise((resolve, reject) => {
      stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement
        })
        .then(({ error: stripeErr, paymentMethod }) => {
          if (stripeErr) {
            setError(stripeErr.message);
            reject(stripeErr.message);
          } else {
            resolve(paymentMethod);
          }
        });
    });

  // If we've successfully entered card details, create a payment method
  // with it.
  const onSubmit = data =>
    new Promise(resolve => {
      setError(null);
      setPaying(true);
      if (!data.card) {
        resolve();
        return;
      }

      const cardElement = elements.getElement(CardElement);
      // Create the payment method from the elements field
      createPaymentMethod(cardElement)
        .then(
          // Then create the payment card from the created payment method
          paymentMethod => onEntityAdded(paymentMethod, data.update_all),
          errorMessage => {
            setError(errorMessage);
            resolve();
          }
        )
        .then(
          () => {
            resolve();
          },
          err => {
            setError(err);
            resolve();
          }
        );
    });

  return (
    <Formik
      initialValues={{ card: false, update_all: false }}
      validationSchema={Yup.object().shape({
        card: Yup.bool().oneOf([true], "Card details are required.")
      })}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <StripeCardField name="card" />
          <p
            className="gkit-p-15 gkit-light-1 mb-0 mt-3"
            style={{ lineHeight: "20px" }}
          >
            <img src={LockIcon} className="me-2" height={20} alt="Lock" />
            Your details are stored securely
          </p>

          <InputField
            type="checkbox"
            className="react-rainbow-components react-rainbow-checkbox my-4"
            name="update_all"
            label="Update all active subscriptions to this card"
          />
          {error && <p className="gkit-p-15 text-danger mb-0">{error}</p>}
          <div className="d-flex flex-column flex-md-row justify-content-between mt-3">
            <Button
              btnType="secondary"
              size="sm"
              type="submit"
              disabled={isPaying}
              isLoading={isPaying}
            >
              Save Payment Card
            </Button>
            <Button
              onClick={handleClose}
              className="mt-2 mt-md-0"
              btnType="tertiary"
              type="reset"
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CollectCardDetails;
