import React, { useState } from "react";
import Button from "shared/Button";
import { useSelector } from "react-redux";
import Loading from "shared/Loading";
import Separator from "shared/Separator";
import PaymentMethod from "shared/PaymentMethod";
import AddNewPaymentMethodModal from "shared/modals/AddNewPaymentMethodModal";

const PaymentMethods = () => {
  const [modal, setModal] = useState(null);
  const { paymentMethods } = useSelector((s) => s.account);

  if (!paymentMethods) {
    return <Loading />;
  }

  return (
    <>
      {paymentMethods.length === 0 ? (
        <EmptyPaymentMethodState />
      ) : (
        <div>
          <p className="gkit-p-20 fw-bold mb-2">Saved payment methods</p>
          {paymentMethods.map((paymentMethod) => (
            <div key={paymentMethod.id}>
              <PaymentMethod
                paymentMethod={paymentMethod}
                hasPadding
                allowDelete
                allowEdit
              />
              <Separator className="my-0" />
            </div>
          ))}
        </div>
      )}
      <Button
        size="md"
        className="mt-4 w-100-mobile"
        onClick={() => setModal(true)}
      >
        Add new payment method
      </Button>
      <AddNewPaymentMethodModal
        isOpen={modal}
        handleClose={() => setModal(false)}
      />
    </>
  );
};

export default PaymentMethods;

const EmptyPaymentMethodState = () => (
  <div>
    <p className="gkit-p-20 fw-bold mb-2">Saved cards</p>
    <p className="gkit-p-17 gkit-light my-0">
      You don’t currently have any saved cards. Would you like to add one now?
      This will make purchasing in the future quicker, and easier for you!
    </p>
  </div>
);
