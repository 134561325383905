/* eslint-disable array-callback-return */
import React, { useState, useEffect, Fragment } from "react";
import Separator from "shared/Separator";
import { useDispatch } from "react-redux";
import ScheduledDelivery from "shared/ScheduledDelivery";
import Loading from "shared/Loading";
import { getSubscriptionSchedule } from "app/subscriptions/actions";

const Deliveries = ({ match: { params } }) => {
  const [schedule, setSchedule] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!schedule) {
      dispatch(getSubscriptionSchedule(params.id)).then(data => {
        setSchedule(data);
      });
    }
  }, [dispatch, params.id, schedule]);

  if (!schedule) {
    return <Loading />;
  }

  function updateSchedule() {
    return setSchedule(null);
  }

  return (
    <div>
      <p className="gkit-p-20 fw-bold">Your next order date</p>
      <ScheduledDelivery
        {...schedule[0]}
        subscriptionId={params.id}
        reloadDeliveries={updateSchedule}
      />
      {schedule.length > 1 && (
        <Fragment>
          <Separator />
          <p className="gkit-p-20 fw-bold">Following deliveries</p>
          {schedule.map((delivery, idx) => {
            if (idx !== 0) {
              return (
                <ScheduledDelivery
                  key={delivery.id}
                  {...delivery}
                  subscriptionId={params.id}
                  reloadDeliveries={updateSchedule}
                />
              );
            }
          })}
        </Fragment>
      )}
    </div>
  );
};

export default Deliveries;
