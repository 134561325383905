import React, { useState } from "react";
import { Form, Formik } from "formik";
import InputField from "shared/Formik/InputField";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Button from "shared/Button";
import { initiateLogin } from "./actions";
import { Redirect } from "react-router-dom";
import ErrorHandling from "shared/ErrorHandling";

const InitiateLogin = () => {
  const { pendingGift: gift } = useSelector((s) => s.account);
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [redirect, setRedirect] = useState();

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: redirect
        }}
      />
    );
  }

  return (
    <div>
      <h2 className="gkit-h2">
        {gift ? "Log in or register to redeem your Gift Subscription" : "Login"}
      </h2>
      <p className="gkit-p-20 gkit-light">
        Please enter your email address to continue.
      </p>
      <Formik
        initialValues={{
          email: ""
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Invalid email format")
            .required("Email address is required")
        })}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(initiateLogin(values)).then(
            (data) => {
              if (!data) {
                setRedirect("/auth/login");
              } else {
                setRedirect("/auth/register");
              }
            },
            (err) => {
              setError(err);
              setSubmitting(false);
            }
          );
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form className="mt-4">
            <InputField
              name="email"
              type="email"
              label="Email"
              className="mb-4"
            />
            <ErrorHandling error={error} />
            <Button
              type="submit"
              size="md"
              isLoading={isSubmitting}
              disabled={!dirty || !isValid}
            >
              Continue
            </Button>
          </Form>
        )}
      </Formik>
      <p className="gkit-p-17 mt-5 mb-1">Forgot password?</p>
      <a
        className="gkit-p-17 text-decoration-none fw-bold gkit-link"
        href="/auth/forgot-password"
      >
        Click here to start recovering it.
      </a>
      <p className="gkit-p-17 mb-1">
        Problems with accessing your account or logging in?{" "}
      </p>
      <p className="gkit-p-17 m-0">
        Please contact us at{" "}
        <a
          className="text-decoration-none gkit-p-17 d-inline fw-bold gkit-link"
          href="mailto:shop@grind.co.uk"
        >
          shop@grind.co.uk
        </a>
      </p>
    </div>
  );
};

export default InitiateLogin;
