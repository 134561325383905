// import moment from "moment";
import { filter } from "lodash";

const insertItem = (array, index, item) => {
  return [...array.slice(0, index), item, ...array.slice(index)];
};

const removeItem = (array, index) => {
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

const updateItem = (array, index, updatedItem) => {
  return array.map((item, idx) => {
    if (idx !== index) {
      // This isn't the item we care about - keep it as-is
      return item;
    }
    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...updatedItem
    };
  });
};

export const getProvincesList = ({ countryCode, countries }) => {
  const countryObject = filter(countries, o => o.code === countryCode)[0];
  return countryObject.provinces;
};

export const getCountryName = ({ countryCode, countries }) => {
  const countryObject = filter(countries, o => o.code === countryCode)[0];
  return countryObject.name;
};

export const getCountryAndProvince = ({
  country_code,
  province_code,
  countries
}) => {
  const countryObject = filter(countries, o => o.code === country_code)[0];

  if (!countryObject) {
    return [country_code, province_code || null];
  }
  let provinceObject;
  if (province_code) {
    provinceObject = filter(
      countryObject.provinces,
      o => o.code === province_code
    )[0];
  }
  return [countryObject?.name, provinceObject ? provinceObject.name : null];
};

const stackedAddress = ({
  countries,
  address1,
  address2,
  city,
  zip,
  ...props
}) => {
  const address = [address1];
  if (address2) {
    address.push(address2);
  }
  address.push(city, zip);
  const [country_name, province_name] = getCountryAndProvince({
    countries: countries,
    ...props
  });
  if (province_name) {
    address.push(province_name);
  }
  address.push(country_name);
  return address.map((part, idx) => {
    return (
      <span key={idx}>
        {idx > 0 && <br />}
        {part}
      </span>
    );
  });
};

const singleLineAddress = ({
  countries,
  address1,
  address2,
  city,
  zip,
  ...props
}) => {
  const address = [address1];
  if (address2) {
    address.push(address2);
  }
  address.push(city, zip);
  const [country_name, province_name] = getCountryAndProvince({
    countries: countries,
    ...props
  });
  if (province_name) {
    address.push(province_name);
  }
  address.push(country_name);
  return address.join(", ");
};

const priceFormatter = ({ price, countryCode = "GB", currency = "GBP" }) => {
  return new Intl.NumberFormat(`en-${countryCode}`, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: Number.isInteger(Number(price)) ? 0 : 2,
    maximumFractionDigits: 2
  }).format(price);
};

const identifyVariantRawParameters = (processedVariant, reformatted) => {
  const { format, item, size } = processedVariant;
  let format_id = "",
    size_id = "",
    product_id = "",
    mixAndMatchPick = null;

  // Search through formatted to locate the raw details of the variant
  Object.keys(reformatted).forEach(eachFormatIndex => {
    if (reformatted[eachFormatIndex].name === format.name) {
      format_id = Number(eachFormatIndex);
      const selectedFormat = reformatted[eachFormatIndex];
      Object.keys(selectedFormat.sizes).forEach(eachSizeIndex => {
        const eachSize = selectedFormat.sizes[eachSizeIndex];
        if (
          eachSize.metadata.title === size.title &&
          eachSize.metadata.subtitle === size.subtitle
        ) {
          size_id = Number(eachSizeIndex);
          if (item.options?.length) {
            const initialValue = {};
            mixAndMatchPick = item.options.reduce((obj, item, idx) => {
              return {
                ...obj,
                [idx + 1]: item
              };
            }, initialValue);
          }
        }
        return;
      });
    }
    return;
  });

  return {
    format_id,
    size_id,
    variant_id: item.variant_id,
    mixAndMatchPick,
    product_id
  };
};

const calculatePrice = (products, type) => {
  return products
    .reduce((acc, product) => acc + parseFloat(product[type].amount), 0)
    .toFixed(2);
};

function setCookie(cname, cvalue, days) {
  var d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
}

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// SKUs to exclude for gift swapping
const bulkSKUs = [
  "PFCCN-GRIN100PM-HOUS",
  "PFCCN-GRIN100PM-LIGH",
  "PFCCN-GRIN100PM-DECA",
  "PFCCN-GRIN100PM-DARK",
  "POD100-H",
  "POD100-B",
  "POD100-D",
  "POD100-R",
  "PFCCN-GRIN100P-HOUS",
  "PFCCN-GRIN100P-LIGH",
  "PFCCN-GRIN100P-DECA",
  "PFCCN-GRIN100P-DARK",
  "WHOGRI005",
  "WHOGRI012",
  "WHOGRI014"
];

const shortestPrice = price => {
  if (!price) {
    return null;
  }

  const strPrice = parseFloat(price).toFixed(2);
  return strPrice.endsWith(".00")
    ? strPrice.substring(0, strPrice.length - 3)
    : strPrice;
};

const upgrade30PodSku = sku => {
  const [a, b, c] = sku.split("-")[1].replace("00", "").split("");

  return `60PODLB-${a}${a}${b}${b}${c}${c}`;
};

const downgrade60PodSku = sku => {
  const [a, , b, , c] = sku.split("-")[1].split("");

  return `PODLB-00${a}${b}${c}`;
};

export {
  bulkSKUs,
  insertItem,
  removeItem,
  updateItem,
  singleLineAddress,
  stackedAddress,
  priceFormatter,
  identifyVariantRawParameters,
  calculatePrice,
  setCookie,
  getCookie,
  shortestPrice,
  upgrade30PodSku,
  downgrade60PodSku
};
