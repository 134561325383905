import React from "react";

import cx from "classnames";

import styles from "./AddToSubscriptionModal.module.scss";

const ComplexOptionSelect = ({
  className,
  onSelect,
  option,
  selectedValue,
  showLabel
}) => {
  return (
    <div className={className}>
      {showLabel && <h5 className="body-17 fw-bold">{option.name}</h5>}
      <div className="d-flex flex-column">
        {option.values.map((value, idx) => (
          <button
            className={cx(
              styles.complexValueContainer,
              value.value === selectedValue && styles.active,
              "d-flex align-items-start",
              idx === 0 ? "mt-1" : "mt-2"
            )}
            key={value.value}
            onClick={() => onSelect(value.value)}
            type="button"
          >
            {value.icon_url && (
              <img
                alt={value.title}
                className={cx(styles.complexValueIcon, "me-2")}
                src={value.icon_url}
              />
            )}
            <div className="text-start">
              <p className="body-17 fw-bold color-charcoal">{value.title}</p>
              <p className="body-13 color-gray-0">{value.description}</p>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default ComplexOptionSelect;
