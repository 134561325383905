import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import { useDispatch, useSelector } from "react-redux";
import { claimGiftToken, setPendingGift } from "app/account/actions";

const ClaimGiftModal = () => {
  const dispatch = useDispatch();
  const { pendingGift: gift } = useSelector(s => s.account);
  const [redeemSuccess, setRedeemSuccess] = useState(false);

  useEffect(() => {
    if (gift) {
      dispatch(claimGiftToken(gift.token))
        .then(data => {
          setRedeemSuccess(data);
        })
        .catch(err => console.log(err));
    }
  }, [dispatch, gift]);

  if (!redeemSuccess || !gift) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      successState={{
        title: "New Gift Subscription",
        subtitle: `You’ve received a ${redeemSuccess.iterations} delivery Gift Subscription from 
        ${redeemSuccess.sender.first_name} ${redeemSuccess.sender.last_name}`,
      }}
      handleClose={() => {
        setRedeemSuccess(false);
        dispatch(setPendingGift(null));
      }}
    />
  );
};

export default ClaimGiftModal;
