import React, { useMemo, useState } from "react";
import Card from "shared/Card";
import Button from "shared/Button";

import { useSelector } from "react-redux";

import ReferralImage from "assets/img/referral.svg";
import { priceFormatter } from "shared/utility";

const ReferralCard = () => {
  const { referralData } = useSelector(s => s.account);
  const [hasCopied, setHasCopied] = useState(false);

  const getReward = useMemo(() => {
    return priceFormatter({
      // referral data doesn't have country code, just currency. Currency will default to GB formatting
      price: referralData.credit_reward.amount,
      currency: referralData.credit_reward.currency
    });
  }, [referralData.credit_reward]);

  const totalGetReward = useMemo(() => {
    return priceFormatter({
      // referral data doesn't have country code, just currency. Currency will default to GB formatting
      price: referralData.credit_reward.amount * referralData.iterations,
      currency: referralData.credit_reward.currency
    });
  }, [referralData.credit_reward, referralData.iterations]);

  const giveReward = useMemo(() => {
    return priceFormatter({
      // referral data doesn't have country code, just currency. Currency will default to GB formatting
      price: referralData.discount_value.amount,
      currency: referralData.discount_value.currency
    });
  }, [referralData.discount_value]);

  const totalGiveReward = useMemo(() => {
    return priceFormatter({
      // referral data doesn't have country code, just currency. Currency will default to GB formatting
      price: referralData.discount_value.amount * referralData.iterations,
      currency: referralData.discount_value.currency
    });
  }, [referralData.discount_value, referralData.iterations]);

  const { code, credit_reward, discount_value } = referralData;

  const iterationCountWord = useMemo(
    () =>
      ({
        2: "two",
        4: "four"
      }[referralData.iterations] || referralData.iterations),
    [referralData.iterations]
  );

  const copyCode = () => {
    navigator.clipboard.writeText(code);
    setHasCopied(true);
  };

  if (!code || !credit_reward?.formatted || !discount_value?.formatted) {
    return null;
  }

  return (
    <Card className="mb-3 pt-4 gkit-bg-cream">
      <div className="row">
        <div className="col-12 col-xl-9">
          <h3 className="gkit-h3 gkit-sm-p-20 fw-bold">
            Give {totalGiveReward}. Get {totalGetReward}.
          </h3>
          <p className="gkit-p-17 gkit-sm-p-15 gkit-light">
            Introduce a friend to Grind and when they use your code, they'll get{" "}
            {giveReward} off their first {iterationCountWord} subscription
            orders.
            <br />
            <br />
            We'll also give you {getReward} off your next {iterationCountWord}{" "}
            subscription orders too!
            {referralData.iterations > 2 && " For a limited time only."}
          </p>
        </div>
        <div className="d-none d-xl-block col-3 p-0">
          <img
            src={ReferralImage}
            alt="Grind gift subscription"
            width={100}
            height={119}
          />
        </div>
      </div>
      <div className="d-flex flex-lg-column flex-xl-row mt-3">
        <input value={code} disabled className="gkit-input-md me-3 bg-white" />
        <Button
          size="md"
          btnType={hasCopied ? "secondary" : "primary"}
          className="flex-shrink-0 mt-lg-3 mt-xl-0"
          onClick={copyCode}
        >
          {hasCopied ? "Copied" : "Copy code"}
        </Button>
      </div>
    </Card>
  );
};

export default ReferralCard;
