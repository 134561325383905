import React from "react";

import cx from "classnames";

import styles from "./AddToSubscriptionModal.module.scss";

const SimpleOptionSelect = ({
  className,
  onSelect,
  option,
  selectedValue,
  showLabel
}) => {
  return (
    <div className={className || undefined}>
      {showLabel && <h5 className="body-17 fw-bold">{option.name}</h5>}
      <div className={cx(styles.optionValueContainer, "d-flex flex-wrap")}>
        {option.values.map((value, idx) => (
          <button
            className={cx(
              styles.optionValue,
              "gkit-btn gkit-btn-sm",
              value === selectedValue ? "gkit-primary" : "gkit-tertiary"
            )}
            key={value}
            onClick={() => onSelect(value)}
          >
            {value}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SimpleOptionSelect;
