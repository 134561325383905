import React from "react";
import styles from "./OptionCard.module.scss";
import { PopularBadge } from "./Badge";
import cx from "classnames";

const OptionCard = ({
  innerClassName = "",
  children,
  className = "",
  popular = false,
  active,
  title,
  subtitle,
  image,
  ...props
}) => {
  return (
    <div
      className={`col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 ${
        styles.optionCard
      } ${active ? styles.activeCard : ""} ${className}`}
      {...props}
    >
      {children ? (
        children
      ) : (
        <div
          className={`card-body h-100 card-grid d-flex position-relative ${innerClassName}`}
        >
          <div className="d-flex align-items-start flex-column h-100 w-100">
            <img src={image} alt={title} className={cx(styles.image, "mb-3")} />
            <p className="gkit-p-17 fw-bold mb-2 mt-auto">{title}</p>
            {subtitle && (
              <p className="gkit-p-15 gkit-light mt-0 mb-2">{subtitle}</p>
            )}
            {popular && <PopularBadge />}
          </div>
        </div>
      )}
    </div>
  );
};

export default OptionCard;
