import React from "react";
import styles from "./Separator.module.scss";

const Separator = ({ type = "dashed", className = "" }) => {
  return (
    <div
      className={`
        ${styles.separator}
        ${type === "dashed" ? styles.dashedSeparator : ""}
        ${className}
       `}
    ></div>
  );
};

export default Separator;
