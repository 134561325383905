import React from "react";
import SparksIcon from "assets/icons/sparks.svg";

const SparklesBadge = ({ text, className }) => {
  return (
    <div
      className={`gkit-bg-pink rounded d-flex mb-auto py-1 px-2 ms-0 me-auto ms-md-2 ${className}`}
    >
      <img src={SparksIcon} height={16} width={16} alt="sparks" />
      <p className="gkit-p-13 fw-bold mx-2">{text}</p>
    </div>
  );
};

export default SparklesBadge;
