import React from "react";

import VisaBlank from "assets/icons/payment-blank-visa.svg";
import MastercardBlank from "assets/icons/payment-blank-mastercard.svg";
import AmexBlank from "assets/icons/payment-blank-amex.svg";
import Visa from "assets/icons/payment-visa.svg";
import Mastercard from "assets/icons/payment-mastercard.svg";
import Amex from "assets/icons/payment-amex.svg";

import BlankPayPal from "assets/icons/payment-blank-PayPal.png";
import PayPal from "assets/icons/payment-PayPal.svg";

import BlankCard from "assets/icons/payment-blank.svg";

const CreditCardImages = {
  Blank: {
    "American Express": AmexBlank,
    Visa: VisaBlank,
    MasterCard: MastercardBlank,
    Generic: BlankCard,
    PayPal: BlankPayPal
  },
  Regular: {
    amex: Amex,
    visa: Visa,
    mastercard: Mastercard,
    "American Express": Amex,
    Visa: Visa,
    MasterCard: Mastercard,
    PayPal: PayPal
  }
};

const ListPaymentOptions = ({ blank = false, showPayPal = false }) => {
  const cardOptions = ["Visa", "American Express", "MasterCard"];
  return (
    <div className="d-block">
      {cardOptions.map((cardOption) => (
        <img
          src={CreditCardImages[blank ? "Blank" : "Regular"][cardOption]}
          className="mx-1"
          key={cardOption}
          alt={`${cardOption} card`}
        />
      ))}
      {showPayPal && (
        <img
          src={CreditCardImages[blank ? "Blank" : "Regular"]["PayPal"]}
          className="mx-1"
          alt="PayPal"
        />
      )}
    </div>
  );
};

export { CreditCardImages};
export default ListPaymentOptions;
