import React, { useCallback, useState, useEffect } from "react";
import Modal from "../Modal";

import CollectCardDetails from "../components/CollectCardDetails";
import Radio from "../inputs/Radio";
import { useSelector, useDispatch } from "react-redux";

import ListPaymentOptions from "../ListPaymentOptions";
import AddPayPalPayment from "../AddPayPalPayment";
import Button from "../Button";
import {
  addPaymentMethod,
  updateAllSubscriptionPaymentMethods
} from "app/account/actions";
import { updateSubscriptionPaymentMethod } from "app/subscriptions/actions";
import ErrorHandling from "shared/ErrorHandling";
import { useStripe } from "@stripe/react-stripe-js";

const AddNewPaymentMethodModal = ({ isOpen, handleClose, subscriptionId }) => {
  const paymentProviders = useSelector(state => state.config.paymentProviders);
  const [payPalCheckout, setPayPalCheckout] = useState(null);
  const [paymentMethodType, setPaymentMethodType] = useState(null);
  const [error, setError] = useState(null);
  const [isPaying, setPaying] = useState(false);
  const dispatch = useDispatch();
  const stripe = useStripe();

  useEffect(() => {
    if (paymentProviders?.paypal) {
      setPayPalCheckout(paymentProviders.paypal.checkoutInstance);
    }
  }, [paymentProviders, isOpen]);

  // TODO: Rewrite This ALL!

  const handleModalClose = useCallback(() => {
    setPaymentMethodType(null);
    setPaying(false);
    handleClose();
  }, [handleClose]);

  const onPaymentMethodAdded = useCallback(
    (paymentMethod, updateAll) =>
      new Promise((resolve, reject) => {
        setError(null);

        return dispatch(
          addPaymentMethod(
            {
              type: "stripe",
              stripe: { token: paymentMethod.id }
            },
            stripe
          )
        )
          .then(
            data => {
              // Update all subscriptions with this payment method
              if (updateAll) {
                return dispatch(
                  updateAllSubscriptionPaymentMethods({
                    payment_method_id: data.id
                  })
                );
              }

              // Update just this subscription with this payment method
              if (subscriptionId) {
                return dispatch(
                  updateSubscriptionPaymentMethod(
                    subscriptionId,
                    {
                      payment_method_id: data.id
                    },
                    stripe
                  )
                );
              }
            },
            err => {
              setError(err);
            }
          )
          .then(
            () => {
              resolve();
              handleModalClose();
            },
            err => {
              reject(err);
              setError(err);
            }
          );
      }),
    [dispatch, handleModalClose, stripe, subscriptionId]
  );

  const paymentMethods = [
    {
      id: "card_payment",
      children: <ListPaymentOptions />,
      condition: true
    },
    {
      id: "paypal_payment",
      children: (
        <AddPayPalPayment
          paypalCheckoutInstance={payPalCheckout}
          onEntityAdded={() => {
            addPaymentMethod();
            handleModalClose();
          }}
        />
      ),
      condition: payPalCheckout
    }
  ];

  if (paymentMethodType !== "card_payment") {
    return (
      <Modal
        isOpen={isOpen}
        handleClose={handleModalClose}
        title="Select a payment method"
      >
        {paymentMethods.map(paymentMethod => (
          <div key={paymentMethod.id}>
            {paymentMethod.condition && (
              <Radio
                name="card_payment"
                id={paymentMethod.id}
                checked={paymentMethodType === paymentMethod.id}
                onChange={setPaymentMethodType}
                containerClass="my-3"
              >
                {paymentMethod.children}
              </Radio>
            )}
          </div>
        ))}
        <div className="mt-4">
          <Button btnType="tertiary" onClick={handleModalClose}>
            Cancel
          </Button>
        </div>
      </Modal>
    );
  }
  return (
    <Modal isOpen={isOpen}>
      {paymentMethodType === "card_payment" && (
        <CollectCardDetails
          onEntityAdded={onPaymentMethodAdded}
          handleClose={handleModalClose}
          isPaying={isPaying}
          setPaying={setPaying}
        />
      )}
      <ErrorHandling error={error} />
      <div className="d-flex flex-row-reverse justify-content-between align-items-start">
        {paymentMethodType !== "card_payment" && (
          <Button btnType="tertiary" onClick={handleModalClose} type="reset">
            Cancel
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default AddNewPaymentMethodModal;
