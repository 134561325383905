import React from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import { addPaymentMethod } from "app/account/actions";

const paypalButtonArguments = (
  paypalCheckoutInstance,
  onSuccessfulCheckout
) => ({
  fundingSource: window.paypal.FUNDING.PAYPAL,

  createBillingAgreement: () =>
    paypalCheckoutInstance.createPayment({
      flow: "vault",
    }),

  onApprove: (data, actions) =>
    paypalCheckoutInstance
      .tokenizePayment(data)
      .then((data) => onSuccessfulCheckout(data)),

  onCancel: (data) => {
    console.warn("Paypal Cancelled: ", data);
  },

  onError: (err) => {
    console.error("Paypal Error: ", err);
  },

  style: {
    color: "black",
    height: 32,
    label: "paypal",
  },
});

const AddPayPalPayment = ({ paypalCheckoutInstance, onEntityAdded }) => {
  const dispatch = useDispatch();
  const PayPalButton = window.paypal.Buttons.driver("react", {
    React,
    ReactDOM,
  });

  function onSuccessfulCheckout(data) {
    dispatch(
      addPaymentMethod({
        type: "paypal",
        paypal: { token: data.nonce },
      })
    ).then(({ data }) => onEntityAdded(data));
  }

  return (
    <PayPalButton
      {...paypalButtonArguments(paypalCheckoutInstance, onSuccessfulCheckout)}
    />
  );
};

export default AddPayPalPayment;
