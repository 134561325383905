import React, { useState } from "react";
import { Form, Formik } from "formik";
import Modal from "../Modal";
import InputField from "../Formik/InputField";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import Button from "../Button";

import { updateProfile, updateEmail } from "app/account/actions";
import ErrorHandling from "shared/ErrorHandling";

const EditProfileModal = ({
  isOpen,
  first_name,
  last_name,
  email,
  handleClose
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [successState, setSuccessState] = useState(null);

  function handleCloseAndReset() {
    handleClose();
    setTimeout(() => {
      setError(null);
      setSuccessState(null);
    }, 1000);
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleCloseAndReset}
      title="Edit personal details"
      subtitle="Add or update your personal details"
      successState={successState}
    >
      <Formik
        initialValues={{
          email: email,
          first_name: first_name,
          last_name: last_name
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Invalid email format")
            .required("Email address is required"),
          first_name: Yup.string().required("First name is required"),
          last_name: Yup.string().required("Last name is required")
        })}
        onSubmit={(values) =>
          new Promise(
            async (resolve, reject) => {
              if (
                values.first_name !== first_name ||
                values.last_name !== last_name
              ) {
                await dispatch(
                  updateProfile({
                    first_name: values.first_name,
                    last_name: values.last_name
                  })
                ).catch((err) => {
                  setError(err);
                  reject();
                });
              }
              if (values.email !== email) {
                await dispatch(updateEmail({ email: values.email })).catch(
                  (err) => {
                    setError(err);
                    reject();
                  }
                );
              }
              resolve();
            }
          ).then(() =>
            setSuccessState({
              title: "Your profile was successfully updated"
            })
          )
        }
      >
        {({ isSubmitting }) => (
          <Form className="mt-3">
            <div className="row mb-3">
              <div className="col-12 col-md-6">
                <InputField name="first_name" type="text" label="First name" />
              </div>
              <div className="col-12 col-md-6 mt-3 mt-md-0">
                <InputField name="last_name" type="text" label="Last name" />
              </div>
            </div>
            <InputField name="email" type="email" label="Email" />
            <ErrorHandling error={error} />
            <div className="mt-4">
              <Button
                type="submit"
                size="md"
                className="me-3"
                isLoading={isSubmitting}
              >
                Update
              </Button>
              <Button
                size="md"
                btnType="tertiary"
                onClick={handleCloseAndReset}
                type="reset"
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditProfileModal;
