import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMarketingPreferences, setMarketingPreferences } from "./actions";
import Loading from "shared/Loading";

import { CheckboxGroup } from "shared/inputs/Checkbox";

// const marketingPreferences = [
//   {
//     name: "accepts_marketing",
//     label: "Marketing",
//     disabled: false,
//     description: "Promotional communications and newsletters"
//   }
// ];
const subscriptionPreferences = [
  {
    name: "order_confirmations",
    label: "Order confirmations",
    disabled: false,
    description:
      "Once an order is placed through your account, we'll send you an email to let you know"
  },
  {
    name: "shipping_confirmations",
    label: "Shipping confirmations",
    disabled: false,
    description:
      "As soon as your order is despatched, we'll let you know your delivery is on route."
  },
  {
    name: "dispatch_notifications",
    label: "Reminder confirmations",
    disabled: false,
    description:
      "A couple of days before your next order is despatched, we'll send you a reminder, just in case you want to make any changes"
  }
];

const Notifications = () => {
  const { marketing } = useSelector(s => s.account);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(marketing).length === 0) {
      dispatch(getMarketingPreferences());
    }
  }, [dispatch, marketing]);

  function handleChange(e) {
    dispatch(setMarketingPreferences({ [e.target.name]: e.target.checked }));
  }

  if (Object.keys(marketing).length === 0) {
    return <Loading />;
  }

  return (
    <div>
      {/* <CheckboxGroup
        label="Marketing notifications"
        className="react-rainbow-components react-rainbow-checkbox"
        options={marketingPreferences}
        values={marketing}
        onChange={handleChange}
      />
      <Separator className="mt-4" /> */}
      <CheckboxGroup
        label="Subscription notifications"
        className="react-rainbow-components react-rainbow-checkbox"
        options={subscriptionPreferences}
        values={marketing}
        onChange={handleChange}
      />
    </div>
  );
};

export default Notifications;
