import React, { Fragment, useEffect, useState, useMemo } from "react";
import moment from "moment";
import Button from "../../shared/Button";
import { useSelector } from "react-redux";
import GiftSubscriptionItem from "shared/GiftSubscriptionItem";
import CalendarIcon from "assets/icons/calendar.svg";
import { stackedAddress } from "shared/utility";
import ErrorHandling from "shared/ErrorHandling";

const ConfirmNewSubscription = ({
  setSettings,
  subscription,
  handleAction,
  handleProductEdit,
  handleFrequencyEdit,
  handleAddressEdit,
  handleWizardClose,
  countryCode,
  completeError
}) => {
  const {
    config: {
      planConfigs: {
        [countryCode]: { variantIdMap }
      },
      countries
    },
    account: { addresses }
  } = useSelector((s) => s);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    setSettings({
      size: "lg"
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSubmitting(false);
  }, [subscription]);

  const product = variantIdMap[subscription.variant_id];
  const address = subscription.address_id
    ? addresses.filter((address) => address.id === subscription.address_id)[0]
    : subscription;
  const addressDisplay = useMemo(
    () => stackedAddress({ countries: countries, ...address }),
    [address, countries]
  );

  if (!subscription.variant_id) {
    return null;
  }

  const onSubscriptionStart = () => {
    setSubmitting(true);
    handleAction();
  };

  return (
    <Fragment>
      <h2 className="gkit-h2 gkit-sm-h3 mb-3">Review your order</h2>
      <h3 className="gkit-h3 gkit-sm-p-20 fw-bold mt-0 mb-2">Your plan</h3>
      <GiftSubscriptionItem {...product} handleSwap={handleProductEdit} />
      <h3 className="gkit-h3 gkit-sm-p-20 fw-bold mt-4 mb-2">
        Delivery schedule
      </h3>
      <div className="d-flex flex-column flex-md-row justify-content-between">
        <div className="d-flex flex-grow-1">
          <img src={CalendarIcon} height={40} width={40} alt="schedule" />
          <div className="d-flex flex-column ms-3">
            <p className="gkit-p-17">
              Every {subscription.interval} {subscription.period}
              {subscription.interval !== 1 && "s"}
            </p>
            <p className="gkit-p-17 mt-0">
              Starting on{" "}
              {moment(subscription.start_date, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              )}
            </p>
          </div>
        </div>
        <Button
          btnType="secondary"
          className="m-auto mt-2 mt-md-0"
          onClick={handleFrequencyEdit}
        >
          Edit
        </Button>
      </div>
      <h3 className="gkit-h3 gkit-sm-p-20 fw-bold mt-4 mb-2">
        Delivery address
      </h3>
      <div className="d-flex flex-column flex-md-row justify-content-between">
        <p className="gkit-p-15 flex-grow-1">{addressDisplay}</p>

        <Button
          btnType="secondary"
          className="m-auto mt-2 mt-md-0"
          onClick={handleAddressEdit}
        >
          Edit
        </Button>
      </div>
      {completeError && <ErrorHandling error={completeError} />}
      <div className="d-flex flex-column flex-md-row justify-content-between mt-5">
        <Button
          btnType="primary"
          onClick={onSubscriptionStart}
          isLoading={isSubmitting}
          className="me-3"
        >
          Start subscription
        </Button>
        <Button
          btnType="danger"
          className="mt-2 mt-md-0 bg-white"
          onClick={handleWizardClose}
        >
          Cancel
        </Button>
      </div>
    </Fragment>
  );
};

export default ConfirmNewSubscription;
