import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Account from "../account/Account";
import Subscriptions from "../subscriptions/Subscriptions";
import OrderHistory from "../orderHistory/OrderHistory";
import SubscriptionDetails from "../subscriptionDetails/SubscriptionDetails";
import NotFound from "shared/NotFound";
import RewardsPage from "app/RewardsPage";

const routes = [
  { exact: false, path: "/auth", redirect: "/" },
  { exact: false, path: "/account", component: Account },
  { exact: false, path: "/reset-password", redirect: "/subscriptions" },
  { exact: false, path: "/admin-login", redirect: "/subscriptions" },
  { exact: false, path: "/gift", redirect: "/subscriptions" },
  { exact: true, path: "/subscriptions", component: Subscriptions },
  {
    exact: false,
    path: "/subscriptions/:id",
    component: SubscriptionDetails
  },

  { exact: false, path: "/order-history", component: OrderHistory },
  { exact: false, path: "/rewards", component: RewardsPage },
  { exact: true, path: "/", component: Account },
  {
    component: NotFound
  }
];

const Router = () => {
  return (
    <Switch>
      {routes.map(({ redirect, ...props }, index) =>
        redirect ? (
          <Route key={index} {...props}>
            <Redirect to={redirect} />
          </Route>
        ) : (
          <Route key={index} {...props} />
        )
      )}
    </Switch>
  );
};

export default Router;
