import React from "react";
import Button from "../Button";
import Modal from "../Modal";

const ReadMoreAboutBlendsModal = ({ isOpen, handleClose, options }) => {

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Blends">
      {options.map((option, idx) => (
        <div key={idx} className="d-flex flex-row align-items-center my-3">
          <img
            className="me-4"
            width={40}
            height={40}
            src={option.image}
            alt={option.title}
          />
          <div>
            <p className="gkit-p-20 gkit-bold mb-0 mt-2">{option.title}</p>
            <p className="gkit-p-15 gkit-light mt-1">{option.description}</p>
          </div>
        </div>
      ))}
      <Button btnType="tertiary" className="mt-3" onClick={handleClose}>
        Close
      </Button>
    </Modal>
  );
};

export default ReadMoreAboutBlendsModal;
