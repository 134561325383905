import React, { useState } from "react";
import Button from "../Button";
import Modal from "../Modal";
import { useDispatch } from "react-redux";

import { calculatePrice } from "shared/utility";
import ErrorHandling from "shared/ErrorHandling";

import { updateSubscriptionProducts } from "app/subscriptions/actions";
import { priceFormatter } from "shared/utility";

const ConfirmProductUpdateModal = ({
  isOpen,
  products,
  subscriptionId,
  handleClose,
  countryCode,
  currency
}) => {
  const newTotal = calculatePrice(products, "total");
  const dispatch = useDispatch();
  const [successState, setSuccessState] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const hasDiscount = products.filter(product =>
    Boolean(product.discount?.amount > 0)
  );

  function handleCloseAndReset() {
    handleClose();
    setTimeout(() => {
      setError(null);
      setSuccessState(null);
    }, 1000);
  }

  function handleProductsUpdate() {
    setSubmitting(true);
    const payload = products
      .filter(product => !product.type || product.type === "subscription")
      .map(({ id, product, quantity, variant }) => ({
        id,
        product_id: product.id,
        variant_id: variant.id,
        quantity: quantity
      }));

    dispatch(updateSubscriptionProducts(subscriptionId, { products: payload }))
      .then(() =>
        setSuccessState({
          title: "Your subscription was successfully updated"
        })
      )
      .catch(err => setError(err))
      .finally(() => setSubmitting(false));
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleCloseAndReset}
      title="Order Change"
      subtitle="You are modifying your subscription order."
      successState={successState}
    >
      <p className="gkit-p-20">
        Your new total is{" "}
        {priceFormatter({
          price: newTotal,
          countryCode: countryCode,
          currency: currency
        })}
        {hasDiscount && hasDiscount.length > 0 && (
          <span>
            *
            <br />
            <span className="gkit-p-13 text-bold gkit-light mt-2">
              *Applicable discount will be applied after confirmation
            </span>
          </span>
        )}
      </p>
      <ErrorHandling error={error} />
      <div className="mt-4">
        <Button
          btnType="primary"
          isLoading={isSubmitting}
          onClick={handleProductsUpdate}
          className="me-3"
        >
          Confirm changes
        </Button>
        <Button
          btnType="tertiary"
          onClick={handleCloseAndReset}
          className="mt-2 mt-md-0"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
export default ConfirmProductUpdateModal;
