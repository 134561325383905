import React from "react";

import cx from "classnames";

import styles from "./Buttons.module.scss";
import ButtonBase from "./ButtonBase";

export default function ButtonPrimary({ children, className, ...props }) {
  return (
    <ButtonBase className={cx(styles.primary, className)} {...props}>
      {children}
    </ButtonBase>
  );
}
