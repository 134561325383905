import React from "react";
import { Input } from "react-rainbow-components";

const Checkbox = ({ description, ...props }) => {
  return (
    <div className="react-rainbow-components react-rainbow-checkbox my-4">
      <Input type="checkbox" {...props} />
      {description && (
        <p className="gkit-p-15 gkit-light ms-4 ps-2">{description}</p>
      )}
    </div>
  );
};

export const CheckboxGroup = ({ label, options, values, onChange }) => {
  return (
    <div>
      <p className="gkit-p-20 fw-bold">{label}</p>
      {options.map((option, idx) => (
        <Checkbox
          {...option}
          checked={values[option.name]}
          key={idx}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

export default Checkbox;
