import React from "react";
import cx from "classnames";

import Card from "shared/Card";

import styles from "./LoyaltyCard.module.scss";

import AssetCup from "assets/img/assetRewardsCup.png";

import IconStar10 from "assets/icons/iconRewardStar10.svg";
import IconStar27 from "assets/icons/iconRewardStar27.svg";
//import IconArrowRight from "assets/icons/iconArrowRight.svg";
import { Link } from "react-router-dom";
import AvailableRewardCount from "./loyaltyCard/AvailableRewardCount";
import { getRewardPointCost } from "app/loyalty/loyaltyUtilities";
import PointsProgressBar from "app/rewardsPage/PointsProgressBar";
//import RewardActivityModal from "app/rewardsPage/RewardActivityModal";

const LoyaltyCard = ({ className, loyalty }) => {
  // This shouldn't be here, but our sidebar is outside of the page hierarchy..
  // So if our sidebar is triggering actions they gotta go here unfortunately
  //const [showRewardActivity, setShowRewardActivity] = useState(false);

  const rewardPointCost = getRewardPointCost(loyalty);

  const pointsToNextReward =
    rewardPointCost - ((loyalty.account?.points_earned || 0) % rewardPointCost);
  return (
    <>
      <div className={className}>
        <Card
          className={cx(
            styles.card,

            "d-none d-lg-flex flex-row justify-content-between align-items-stretch p-0"
          )}
        >
          <div className="p-3">
            <h4 className="body-20 fw-bold text-white">Grind Rewards.</h4>
            {loyalty.account ? (
              <>
                <div className="d-flex align-items-center">
                  <p className={cx(styles.points)}>
                    {loyalty.account.points_earned}
                  </p>
                  <img
                    alt="Your Reward Points."
                    className="ms-1"
                    src={IconStar27}
                  />
                </div>
                <p className="caption-13 color-gray-1">
                  1 point for every £1 spent
                </p>
              </>
            ) : (
              <>
                <p className="body-15 color-gray-1-5 mt-2">
                  Start earning points and getting free coffee.
                </p>
                <Link
                  className="gkit-btn gkit-btn-sm gkit-secondary mt-3"
                  to="/rewards"
                  type="button"
                >
                  Get started
                </Link>
              </>
            )}
          </div>
          <div
            className={cx(
              styles.cupContainer,
              Boolean(loyalty.account) && styles.activeAccount,
              "d-flex flex-column align-items-center position-relative"
            )}
          >
            <img
              alt="Grind Rewards"
              className={cx(styles.cup)}
              src={AssetCup}
            />
            {Boolean(loyalty.account) && (
              <>
                <div>
                  <div className="d-flex flex-row align-items-center">
                    <p className={cx("caption-13 color-gray-2")}>
                      {`Next reward in ${pointsToNextReward}`}
                    </p>
                    <img
                      alt="Grind rewards."
                      className="ms-1"
                      src={IconStar10}
                    />
                  </div>
                  <PointsProgressBar
                    className="mt-2 align-self-stretch"
                    progress={pointsToNextReward}
                    total={rewardPointCost}
                  />
                </div>
                <AvailableRewardCount
                  availablePoints={loyalty.account?.points_earned}
                  className={styles.availableRewardCount}
                  rewardPointCost={rewardPointCost}
                />
              </>
            )}
          </div>
        </Card>
        {/* <button
          className={cx(styles.historyButton, "w-100")}
          onClick={() => setShowRewardActivity(true)}
          type="button"
        >
          View rewards history
          <img
            alt="View history"
            className={styles.icon}
            src={IconArrowRight}
            width={16}
            height={16}
          />
        </button> */}
      </div>
      {/* <RewardActivityModal
        onClose={() => setShowRewardActivity(false)}
        show={showRewardActivity}
      /> */}
    </>
  );
};

export default LoyaltyCard;
