import React, { useState, useMemo, Fragment } from "react";
import Button from "./Button";
import ShippingInfo from "./ShippingInfo";

import moment from "moment";

import HouseIcon from "assets/icons/house.svg";
import ChargeIcon from "assets/icons/charge.svg";
import SubscriptionItems from "./SubscriptionItems";
import { NavLink } from "react-router-dom";

import { singleLineAddress } from "./utility";
import PaymentError from "./PaymentError";
import { calculatePrice, priceFormatter } from "./utility";
import Card from "./Card";
import SubscriptionStatus from "./SubscriptionStatus";
import { useSelector } from "react-redux";
import ActivateSubscriptionModal from "./modals/ActivateSubscriptionModal";
import Separator from "./Separator";
import LastDeliveryInfo from "./LastDeliveryInfo";

const Subscription = ({ subscription }) => {
  const [modal, setModal] = useState(false);
  const { countries } = useSelector(s => s.config);
  const totalPrice = calculatePrice(
    subscription.next_dispatch
      ? subscription.next_dispatch.products
      : subscription.products,
    "total"
  );

  const inactiveSubscription = useMemo(
    () =>
      subscription.status === "cancelled" || subscription.status === "paused"
        ? true
        : false,
    [subscription.status]
  );

  const borderColours = {
    failing: "danger",
    failed: "danger",
    cancelled: "primary",
    paused: "primary",
    active: "success",
    expired: "primary"
  };

  const headline = useMemo(() => {
    switch (subscription.status) {
      case "paused":
        return `Paused until ${moment(subscription.paused_until).format(
          "Do MMM YYYY"
        )}`;
      case "expired":
        return "Finished gift subscription";
      case "active":
        return `Next order processed on ${
          subscription.next_dispatch
            ? moment(
                subscription.next_dispatch.order_date,
                "YYYY-MM-DD"
              ).format("Do MMM YYYY")
            : "N/A"
        }`;
      default:
        return "";
    }
  }, [subscription]);

  return (
    <Card
      className={`gkit-border-${borderColours[subscription.status]} my-4 ${
        !inactiveSubscription ? "gkit-bg-gray-4" : ""
      }`}
    >
      <PaymentError subscription={subscription} />
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <SubscriptionStatus
            status={subscription.status}
            className="me-4 me-md-0"
          />
          <p className="gkit-p-20 medium ms-2 d-none d-md-block my-auto">
            {headline}
          </p>
        </div>
        {(() => {
          switch (subscription.status) {
            case "paused":
              return (
                <Button
                  btnType="secondary"
                  className="w-auto gkit-transparent"
                  onClick={() => setModal(true)}
                >
                  Resume
                </Button>
              );
            case "cancelled":
              return (
                <Button
                  btnType="secondary"
                  className="w-auto gkit-transparent"
                  onClick={() => setModal(true)}
                >
                  Reactivate
                </Button>
              );
            default:
              return (
                <NavLink to={`/subscriptions/${subscription.id}/details`}>
                  <Button btnType="secondary" className="gkit-transparent">
                    Edit
                  </Button>
                </NavLink>
              );
          }
        })()}
      </div>
      <p className="gkit-p-20 medium my-3 d-block d-md-none">{headline}</p>
      <SubscriptionItems
        subscriptionId={subscription.id}
        countryCode={subscription.country_code}
        currency={subscription.currency}
        gift={subscription.gift && subscription.gift.status !== "expired"}
        products={
          subscription.next_dispatch
            ? subscription.next_dispatch.products
            : subscription.products
        }
        isWhite
        hasPadding={
          !(
            subscription.status === "cancelled" ||
            subscription.status === "paused"
          )
        }
      />
      {inactiveSubscription ? (
        <ActivateSubscriptionModal
          isOpen={modal}
          status={subscription.status}
          subscriptionId={subscription.id}
          handleClose={() => setModal(false)}
        />
      ) : (
        <Fragment>
          <ShippingInfo
            icon={HouseIcon}
            title="Shipping address"
            subtitle={singleLineAddress({
              countries: countries,
              ...subscription.shipping_address
            })}
          />
          <ShippingInfo
            icon={ChargeIcon}
            title="Subscription total"
            info={`${
              subscription.gift && subscription.gift.status !== "expired"
                ? "GIFTED"
                : priceFormatter({
                    price: totalPrice,
                    countryCode: subscription.country_code,
                    currency: subscription.currency
                  })
            }`}
          />
          {subscription.last_dispatch && (
            <>
              <Separator />
              <LastDeliveryInfo
                orderDate={subscription.last_dispatch.order_date}
                payment={subscription.last_dispatch.order.payment}
                countryCode={subscription.country_code}
              />
            </>
          )}
        </Fragment>
      )}
    </Card>
  );
};

export default Subscription;
