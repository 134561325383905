import React, { useState } from "react";
import { Form, Formik } from "formik";
import InputField from "shared/Formik/InputField";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { registerUser } from "./actions";
import ErrorHandling from "shared/ErrorHandling";
import { Redirect } from "react-router-dom";
import InputFieldLine from "shared/Formik/InputFieldLine";
import ButtonPrimary from "shared/ButtonPrimary";

const Register = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [redirect, setRedirect] = useState();
  const email = useSelector(s => s.auth.email);

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: redirect
        }}
      />
    );
  }

  return (
    <div>
      <h1 className="gkit-h2">
        New here?
        <br />
        Create your account.
      </h1>
      <Formik
        initialValues={{
          email: email ?? "",
          //password: "",
          first_name: "",
          last_name: "",
          accepts_marketing: false
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Invalid email format")
            .required("Email address is required"),
          password: Yup.string().required("Password is required"),
          first_name: Yup.string().required("First name is required"),
          last_name: Yup.string().required("Last name is required")
        })}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(registerUser(values))
            .then(() => {
              setSubmitting(false);
              setRedirect("/");
            })
            .catch(err => {
              setError(err);
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form className="mt-4">
            <InputFieldLine name="email" type="email" placeholder="Email *" />
            <InputFieldLine
              className="mt-2"
              name="first_name"
              type="text"
              placeholder="First name *"
            />
            <InputFieldLine
              className="mt-2"
              name="last_name"
              type="text"
              placeholder="Last name *"
            />
            <InputFieldLine
              className="mt-2"
              name="password"
              type="password"
              placeholder="Password *"
            />
            <InputField
              name="accepts_marketing"
              type="checkbox"
              label="I want to receive marketing emails"
              className="mt-4 react-rainbow-components react-rainbow-checkbox"
            />
            <ErrorHandling error={error} />
            <div className="px-md-5 mt-4">
              <ButtonPrimary
                className="w-100"
                type="submit"
                size="md"
                isLoading={isSubmitting}
                disabled={!dirty || !isValid}
              >
                Create account
              </ButtonPrimary>
            </div>
          </Form>
        )}
      </Formik>

      <p className="gkit-p-17 mt-5 mb-1">Already created an account? </p>
      <a className="gkit-p-17 text-decoration-none fw-bold gkit-link" href="/">
        Try logging in with another email address
      </a>
    </div>
  );
};

export default Register;
