import React, { useState } from "react";
import Button from "shared/Button";
import { useSelector } from "react-redux";
// import Separator from "shared/Separator";

import EditProfileModal from "shared/modals/EditProfileModal";

const Profile = () => {
  const {
    auth: { user },
  } = useSelector((s) => s);
  const [modal, setModal] = useState(null);

  return (
    <div>
      <p className="gkit-p-20 fw-bold mb-2">Personal details</p>
      <p className="gkit-p-17 my-0">
        {user.first_name} {user.last_name}
      </p>
      <p className="gkit-p-17 mt-0">{user.email}</p>
      <Button
        btnType="tertiary"
        className="mt-3"
        onClick={() => setModal("edit")}
      >
        Edit
      </Button>
      {/* NO ENDPOINT
      <Separator />
      <p className="gkit-p-20 fw-bold mb-2">Danger zone</p>
      <p className="gkit-p-17 my-0 gkit-light">Delete your account</p>
      <Button btnType="danger" className="mt-3">
        Delete account
      </Button> */}
      <EditProfileModal
        isOpen={modal === "edit"}
        {...user}
        handleClose={() => setModal(null)}
      />
    </div>
  );
};

export default Profile;
