import React, { useState } from "react";
import Modal from "../Modal";
import { useSelector } from "react-redux";
import Radio from "shared/inputs/Radio";
import Button from "../Button";
import PaymentMethod from "shared/PaymentMethod";
import ErrorHandling from "shared/ErrorHandling";

const ChangePaymentMethodModal = ({
  isOpen,
  paymentMethodId,
  handleAction,
  handleClose,
  handleAddNew
}) => {
  const [radio, setRadio] = useState(paymentMethodId);
  const [successState, setSuccessState] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const existingPaymentMethods = useSelector(
    (state) => state.account.paymentMethods
  );

  function handlePaymentMethodUpdate() {
    setSubmitting(true);
    handleAction({ payment_method_id: radio })
      .then(() => {
        setSuccessState({
          title: "Payment method successfully updated"
        });
      })
      .catch((err) => setError(err))
      .finally(() => setSubmitting(false));
  }

  if (isOpen && existingPaymentMethods && existingPaymentMethods.length === 0) {
    handleAddNew();
  }

  function handleCloseAndReset() {
    handleClose();
    setTimeout(() => {
      setError(null);
      setSuccessState(null);
    }, 1000);
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleCloseAndReset}
      title="Change Payment Method"
      subtitle="Pick one of your existing payment methods or add a new one"
      successState={successState}
    >
      {existingPaymentMethods &&
        existingPaymentMethods.map((paymentMethod) => (
          <Radio
            key={paymentMethod.id}
            onChange={() => setRadio(paymentMethod.id)}
            checked={radio === paymentMethod.id}
          >
            <PaymentMethod paymentMethod={paymentMethod} />
          </Radio>
        ))}
      <ErrorHandling error={error} />
      <div className="d-flex justify-content-between mt-3">
        <div>
          <Button
            className="me-3"
            onClick={handlePaymentMethodUpdate}
            isLoading={isSubmitting}
          >
            Save
          </Button>
          <Button
            btnType="secondary"
            onClick={handleAddNew}
            className="mt-2 d-md-none"
          >
            Add new
          </Button>
          <Button
            btnType="tertiary"
            onClick={handleCloseAndReset}
            className="mt-2 mt-md-0"
          >
            Cancel
          </Button>
        </div>
        <Button
          btnType="secondary"
          onClick={handleAddNew}
          className="d-none d-md-block"
        >
          Add new
        </Button>
      </div>
    </Modal>
  );
};

export default ChangePaymentMethodModal;
