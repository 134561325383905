import { createActions } from "redux-actions";
import { SET_ORDER_HISTORY } from "./actionTypes";
import api, { extractAPIErrorString } from "api";

import moment from "moment";

const { setOrderHistory } = createActions(SET_ORDER_HISTORY);

export const getOrderHistory = () => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .get("/shop/orders")
      .then(({ data: { data } }) => {
        const groupped = data.reduce((r, order) => {
          const date = moment(order.created_at, "YYYY-MM-DD").format(
            "MMMM_YYYY"
          );
          if (!r[date]) r[date] = [order];
          else r[date].push(order);
          return r;
        }, {});

        dispatch(setOrderHistory(groupped));
        resolve(groupped);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const downloadInvoice = id => {
  return new Promise((resolve, reject) => {
    api
      .get(`/shop/orders/${id}/invoice-pdf`)
      .then(({ data }) => {
        window.open(data.url, "_blank");
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};
