import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import Button from "shared/Button";
import FrequencySection from "shared/config/FrequencySection";
import CustomDatePicker from "shared/inputs/CustomDatePicker";

const ConfigureSubscription = ({
  subscription,
  setSettings,
  handleAction,
  handleGoBack,
  handleWizardClose
}) => {
  const minDate = moment().toDate();
  const [frequency, setFrequency] = useState(
    subscription.interval && subscription.period
      ? { interval: subscription.interval, period: subscription.period }
      : null
  );
  const [startDate, setStartDate] = useState(
    subscription.start_date ? subscription.start_date : minDate
  );
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    setSettings({
      size: "lg",
      title: "Select subscription start date",
      subtitle:
        "We'll send your delivery via Royal Mail, it'll be with you in 2-3 days. All of our deliveries are carbon positive.",
      footer: (
        <div className="d-flex w-100 flex-column flex-md-row justify-content-between px-2">
          <div className="">
            <Button
              btnType="primary"
              onClick={handleContinue}
              className="me-md-3"
              disabled={!frequency}
            >
              Continue
            </Button>
            <Button
              btnType="tertiary"
              className="mt-2 mt-md-0"
              onClick={handleGoBack}
            >
              Back
            </Button>
          </div>
          <Button
            btnType="danger"
            className="mt-2 mt-md-0 bg-white"
            onClick={handleWizardClose}
          >
            Cancel
          </Button>
        </div>
      )
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frequency]);

  function handleContinue() {
    handleAction({
      interval: frequency.interval,
      period: frequency.period,
      start_date: startDate
    });
    setFrequency(null);
  }

  return (
    <Fragment>
      <div className="row col-8">
        <CustomDatePicker
          name="start_date"
          value={startDate}
          onChange={setStartDate}
          minDate={minDate}
        />
      </div>
      <h3 className="gkit-h3 mt-5 mb-2">Select frequency</h3>
      <p className="gkit-p-17 mt-0 gkit-light mb-3">
        How often do you want to receive your coffee deliveries?
      </p>
      <FrequencySection
        frequency={frequency}
        onFrequencySelect={setFrequency}
        showMore={showMore}
      />
      <div className="mt-2 text-end">
        <Button
          btnType="secondary"
          className="mb-0"
          onClick={() => setShowMore(!showMore)}
        >
          {!showMore ? "More options" : "Fewer options"}
        </Button>
      </div>
    </Fragment>
  );
};

export default ConfigureSubscription;
