import {
  INITIATE_AUTH,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_LOGGED_OUT,
  SET_ADMIN,
  SET_USER
} from "./actionTypes";
import { handleActions } from "redux-actions";

export const initialState = {
  isLoggedIn: null,
  accessToken: null,
  user: null,
  email: null,
  isAdmin: false
};

export default handleActions(
  {
    [INITIATE_AUTH]: (state, { payload }) => {
      return {
        ...state,
        email: payload.email
      };
    },
    [LOGIN_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        accessToken: payload
      };
    },
    [SET_ADMIN]: (state) => ({ ...state, isAdmin: true }),
    [SET_USER]: (state, { payload }) => {
      return {
        ...state,
        isLoggedIn: true,
        user: {
          ...(state.user || {}),
          ...payload
        }
      };
    },
    [LOGOUT]: () => initialState,
    [LOGIN_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        accessToken: payload
      };
    },
    [SET_USER]: (state, { payload }) => {
      return {
        ...state,
        isLoggedIn: true,
        user: {
          ...(state.user || {}),
          ...payload
        }
      };
    },
    [SET_LOGGED_OUT]: (state) => ({
      ...state,
      isLoggedIn: false
    })
  },
  initialState
);
