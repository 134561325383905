import {
  SET_SUBSCRIPTIONS,
  CLEAR_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_SUCCESS
} from "./actionTypes";
import { handleActions } from "redux-actions";

export const initialState = {
  subscriptions: null,
  lookup: {}
};

export default handleActions(
  {
    [SET_SUBSCRIPTIONS]: (state, { payload }) => ({
      ...state,
      lookup: payload.reduce((acc, subscription) => {
        acc[subscription.id] = subscription;
        return acc;
      }, {}),
      subscriptions: payload
    }),
    [CLEAR_SUBSCRIPTIONS]: () => {
      return {
        ...initialState
      };
    },
    [UPDATE_SUBSCRIPTION_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        lookup: { ...state.lookup, [payload.id]: payload },
        subscriptions: state.subscriptions
          ? state.subscriptions.map(sub =>
              sub.id === payload.id ? payload : sub
            )
          : [payload]
      };
    },
    [CREATE_SUBSCRIPTION_SUCCESS]: (state, { payload }) => ({
      ...state,
      lookup: { ...state.lookup, [payload.id]: payload },
      subscriptions: [payload, ...state.subscriptions]
    })
  },
  initialState
);
