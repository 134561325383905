import React from "react";
import { Application, DatePicker } from "react-rainbow-components";

const CustomDatePicker = ({ value, onChange, label, ...props }) => {
  const theme = {
    rainbow: {
      palette: {
        brand: "#e191a0"
      }
    }
  };

  return (
    <div className="react-rainbow-components">
      <Application theme={theme}>
        <DatePicker
          {...props}
          value={value ? new Date(value) : undefined}
          label={label}
          local="en-GB"
          id="react_rainbow_datepicker"
          className="react-rainbow-datepicker"
          onChange={(value) => onChange(value.toISOString())}
        />
      </Application>
    </div>
  );
};

export default CustomDatePicker;
