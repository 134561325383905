import React from "react";

import { priceFormatter } from "./utility";

import styles from "./CardDetail.module.scss";
import SparklesBadge from "./SparklesBadge";

const CardDetail = ({
  className,
  image,
  popular,
  name,
  subtitle,
  badgeClassName
}) => {
  return (
    <div className={`d-flex align-items-start flex-column h-100 ${className}`}>
      <img
        src={image}
        width={40}
        height={40}
        alt={name}
        style={{ objectFit: "contain" }}
        className="mb-3"
      />
      <p className="gkit-p-17 gkit-bold mb-2 mt-auto">{name}</p>
      {subtitle && (
        <p className="gkit-p-15 gkit-gray-subtitle mt-0 mb-2">{subtitle}</p>
      )}
      {popular && <SparklesBadge title="Popular" className={badgeClassName} />}
    </div>
  );
};

const PackageFormatCardDetail = ({
  className,
  image,
  title,
  price,
  discounted,
  subtitle,
  gift,
  countryCode,
  currency
}) => {
  return (
    <div className={`row card-body h-100 align-items-center ${className}`}>
      <div className="col-5 col-md-4 h-100">
        <div
          className={`${styles.square} h-100`}
          style={{ backgroundImage: `url('${image}')`, borderRadius: "6px" }}
        ></div>
      </div>
      <div className="col">
        <p className="gkit-p-20 gkit-bold">{title}</p>
        {!gift && (
          <p className="gkit-p-20 gkit-bold">
            {discounted !== undefined && (
              <span>
                <span className="text-success mr-2">
                  {priceFormatter({
                    price: discounted,
                    countryCode: countryCode,
                    currency: currency
                  })}
                </span>
                <span className="gkit-p-15">
                  <s>
                    {priceFormatter({
                      price: price,
                      countryCode: countryCode,
                      currency: currency
                    })}
                  </s>
                </span>
              </span>
            )}
            <span className="text-success">
              {discounted === undefined &&
                priceFormatter({
                  price: price,
                  countryCode: countryCode,
                  currency: currency
                })}
            </span>
          </p>
        )}
        <p className={`gkit-p-15 ${gift ? "mb-4 pb-lg-3" : "mb-0"}`}>
          {subtitle}
        </p>
      </div>
    </div>
  );
};

export { CardDetail, PackageFormatCardDetail };
