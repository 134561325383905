import React from "react";
import styles from "./AuthLayout.module.scss";
import { Link, useLocation } from "react-router-dom";
import cx from "classnames";

export default function AuthLayout({ children }) {
  const { pathname } = useLocation();

  return (
    <div className={styles.outerContainer}>
      <img
        alt="Grind Coffee."
        className="d-block d-md-none"
        src={require("../../assets/img/assetAuthBackground.jpg")}
      />
      <div className={styles.card}>
        <div className={styles.toggleContainer}>
          <Link
            className={cx(
              styles.link,
              ([
                "/",
                "/auth/login",
                "/auth/verify-login",
                "/auth/passwordless",
                "/auth/forgot-password",
                "/auth/reset-password",
                "/auth/forgot-password-sent",
                "/auth/reset-password"
              ].indexOf(pathname) !== -1 ||
                pathname.startsWith("/reset-password/")) &&
                styles.active
            )}
            to="/"
          >
            Log in
          </Link>
          <Link
            className={cx(
              styles.link,
              ["/auth/register", "/auth/verify-register"].indexOf(pathname) !==
                -1 && styles.active
            )}
            to="/auth/register"
          >
            Create account
          </Link>
        </div>
        <div className={styles.contentContainer}>{children}</div>
      </div>
    </div>
  );
}
