import React, { useCallback, useState } from "react";
import cx from "classnames";
import SubscriptionBlock from "./SubscriptionBlock";
import styles from "./AddToSubscriptionModal.module.scss";
import { useDispatch } from "react-redux";
import { claimLoyaltyReward } from "app/loyalty/actions";

const SubscriptionSelectModalContent = ({
  onClose,
  onClaimed,
  onVariantClear,
  reward,
  subscriptions,
  variant
}) => {
  const [isClaiming, setIsClaiming] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(
    subscriptions[0]
  );

  const dispatch = useDispatch();

  const onClaimClick = useCallback(() => {
    if (isClaiming) {
      return;
    }

    setIsClaiming(true);
    dispatch(
      claimLoyaltyReward(reward.id, {
        subscription_id: selectedSubscription.id,
        variant_id: variant?.id
      })
    )
      .then(onClaimed)
      .finally(() => setIsClaiming(false));
  }, [dispatch, isClaiming, onClaimed, reward, selectedSubscription, variant]);

  if (!reward) {
    return null;
  }

  return (
    <>
      <div className="modal-header">
        <h4 className="body-20 fw-bold">Select subscription</h4>
        <button
          aria-label="Close"
          className="btn-close"
          onClick={onClose}
          type="button"
        />
      </div>
      <div className="modal-body">
        <img
          alt={reward.title}
          className={styles.rewardImage}
          src={reward.image_url}
        />
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h4 className="body-20 fw-bold color-charcoal">{reward.title}</h4>
            {variant && (
              <p className="body-15 fw-medium color-gray-0">
                {variant.options.join(", ")}
              </p>
            )}
          </div>
          {variant && (
            <button
              className="gkit-btn gkit-btn-sm gkit-tertiary"
              onClick={onVariantClear}
              type="button"
            >
              Edit
            </button>
          )}
        </div>
        <h5 className="body-17 fw-bold mt-3">
          Your subscription{subscriptions.length > 1 ? "s" : ""}
        </h5>
        <div className={cx(styles.subscriptionListContainer, "mt-2")}>
          {subscriptions.map((subscription, idx) => (
            <SubscriptionBlock
              className={cx("w-100", idx > 0 && "mt-2")}
              key={subscription.id}
              isSelected={subscription.id === selectedSubscription?.id}
              onClick={() => setSelectedSubscription(subscription)}
              subscription={subscription}
            />
          ))}
        </div>
        <button
          className={cx(
            "gkit-btn gkit-btn-md gkit-primary mt-3",
            isClaiming && "gkit-loading"
          )}
          disabled={isClaiming || !selectedSubscription}
          onClick={onClaimClick}
          type="button"
        >
          Add to subscription
        </button>
      </div>
    </>
  );
};

export default SubscriptionSelectModalContent;
