import React from "react";

const ErrorHandling = ({ error }) => {
  if (!error) return null;

  if (error.message) {
    return (
      <p className="gkit-p-15 fw-bold text-danger my-3">{error.message}</p>
    );
  }

  if (error.name) {
    return (
      <p className="gkit-p-15 fw-bold text-danger my-3">{error.name}</p>
    );
  }

  return <p className="gkit-p-15 fw-bold text-danger my-3">{error}</p>;
};

export default ErrorHandling;
