import React, { Fragment, useState } from "react";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Separator from "shared/Separator";
import Button from "shared/Button";
import Address from "shared/Address";
import PaymentMethod from "shared/PaymentMethod";

import CancelSubscriptionModal from "shared/modals/CancelSubscriptionModal";
import PauseSubscriptionModal from "shared/modals/PauseSubscriptionModal";
import GetShipmentNowModal from "shared/modals/GetShipmentNowModal";
import RescheduleShipmentModal from "shared/modals/RescheduleShipmentModal";
import EditFrequencyModal from "shared/modals/EditFrequencyModal";

import {
  rescheduleDelivery,
  updateSubscriptionPaymentMethod
} from "app/subscriptions/actions";
import ActivateSubscriptionModal from "shared/modals/ActivateSubscriptionModal";
import AddNewPaymentMethodModal from "shared/modals/AddNewPaymentMethodModal";
import SkipDeliveryModal from "shared/modals/SkipDeliveryModal";
import ChangePaymentMethodModal from "shared/modals/ChangePaymentMethodModal";
import DiscountSection from "./DiscountSection";

import CalendarIcon from "assets/icons/calendar.svg";
import TruckIcon from "assets/icons/truck.svg";
import { useStripe } from "@stripe/react-stripe-js";

const Details = ({ match: { params } }) => {
  const stripe = useStripe();
  const subscription = useSelector(s => s.subscriptions.lookup[params.id]);
  const [modal, setModal] = useState(null);
  const [redirect, setRedirect] = useState();

  const dispatch = useDispatch();

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: redirect
        }}
      />
    );
  }

  function handleRetainActions(action) {
    if (action === "changeBlend") {
      setModal(null);
      setRedirect(`/subscriptions/${params.id}/plan/change`);
    } else {
      setModal(action);
    }
  }

  const DeliverySchedule = () => {
    return (
      <Fragment>
        <p className="gkit-p-20 fw-bold">Delivery schedule</p>
        <div className="row mt-3">
          <div className="col-12 col-md-6">
            <img
              src={CalendarIcon}
              width={32}
              height={32}
              alt="next delivery"
            />
            <p className="gkit-p-17 fw-bold mb-2 mt-3">Next order processed</p>
            <p className="gkit-p-15 mt-0">
              {subscription.next_dispatch
                ? moment(
                    subscription.next_dispatch.order_date,
                    "YYYY-MM-DD"
                  ).format("D MMM YYYY")
                : "N/A"}
            </p>
            <div className="d-flex mt-3 mb-4 mb-md-0 flex-column flex-md-row">
              <Button
                btnType="tertiary"
                className="me-2 my-1 my-md-0"
                onClick={() => setModal("GetShipmentNowModal")}
              >
                I want it now
              </Button>
              <Button
                btnType="tertiary"
                className="my-1 my-md-0"
                onClick={() => setModal("RescheduleShipmentModal")}
              >
                Change date
              </Button>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <img
              src={TruckIcon}
              width={32}
              height={32}
              alt="delivery frequency"
            />
            <p className="gkit-p-17 fw-bold mb-2 mt-3">Delivery frequency</p>
            <p className="gkit-p-15 mt-0">
              {`Every ${subscription.interval} ${subscription.period}${
                subscription.interval !== 1 ? "s" : ""
              }`}
            </p>
            <Button
              btnType="tertiary"
              className="mt-3"
              onClick={() => setModal("EditFrequencyModal")}
            >
              Change frequency
            </Button>
          </div>
        </div>
        <Separator />
      </Fragment>
    );
  };

  const ResumeSubscription = () => {
    return (
      <Fragment>
        <p className="gkit-p-20 fw-bold">Resume subscription</p>
        <p className="gkit-p-17">
          Next order processed{" "}
          {moment(subscription.paused_until, "YYYY-MM-DD").format("D MMM YYYY")}
        </p>
        <div className="d-flex mt-3 flex-column flex-md-row">
          <Button
            btnType="tertiary"
            className="me-3 my-1 my-md-0"
            onClick={() => setModal("PauseSubscriptionModal")}
          >
            Modify
          </Button>
          <Button
            className="my-1 my-md-0"
            onClick={() => setModal("ActivateSubscriptionModal")}
          >
            Resume subscription
          </Button>
        </div>
        <Separator />
      </Fragment>
    );
  };

  const ReactivateSubscription = () => {
    return (
      <Fragment>
        <p className="gkit-p-20 fw-bold">Reactivate subscription</p>
        <Button
          btnType="tertiary"
          className="mt-3 text-success"
          onClick={() => setModal("ActivateSubscriptionModal")}
        >
          Reactivate subscription
        </Button>
        <Separator />
      </Fragment>
    );
  };

  return (
    <Fragment>
      {subscription.status === "active" && <DeliverySchedule />}
      {subscription.status === "paused" && <ResumeSubscription />}
      {subscription.status === "cancelled" && <ReactivateSubscription />}
      <p className="gkit-p-20 fw-bold mb-3">
        {!subscription.payment_method &&
        subscription.gift &&
        subscription.status === "expired"
          ? "Your gift subscription has ended"
          : "Payment method"}
      </p>
      {subscription.payment_method && (
        <PaymentMethod
          paymentMethod={subscription.payment_method}
          subscriptionId={subscription.id}
          allowChange={subscription.status === "active" ? true : false}
          allowEdit={subscription.status === "active" ? true : false}
        />
      )}
      {!subscription.payment_method && subscription.gift && (
        <div>
          <p className="gkit-p-17">
            If you'd like to continue receiving coffee
            {!subscription.status === "expired"
              ? " after your gift subscription ends"
              : ""}
            , please add your payment method to your account and we'll keep your
            deliveries coming.
          </p>
          <Button
            className="mt-3"
            onClick={() => setModal("ChangePaymentMethodModal")}
          >
            Add payment method
          </Button>
        </div>
      )}
      <Separator />
      <p className="gkit-p-20 fw-bold mb-3">Invoice address</p>
      <Address
        address={
          subscription.billing_address
            ? subscription.billing_address
            : subscription.shipping_address
        }
        allowEdit={subscription.status === "active" ? true : false}
        allowChange={subscription.status === "active" ? true : false}
        subscriptionId={subscription.id}
        sameAsShipping={!subscription.billing_address}
        isBilling
      />
      <Separator />
      <p className="gkit-p-20 fw-bold mb-3">Delivery address</p>
      <Address
        address={subscription.shipping_address}
        allowEdit={subscription.status === "active" ? true : false}
        allowChange={subscription.status === "active" ? true : false}
        subscriptionId={subscription.id}
      />
      {/* This section is used to handle discount codes */}
      {subscription.status !== "cancelled" && (
        <DiscountSection discount={subscription.discount} />
      )}
      {/* This section allows the customer to cancel or pause subscriptions */}
      {subscription.status !== "cancelled" && (
        <div>
          <Separator />
          <p className="gkit-p-20 fw-bold">Danger zone</p>
          <p className="gkit-p-17 gkit-light">
            You can {subscription.status === "active" && "pause or"} cancel your
            subscription any time.
          </p>
          <div className="d-flex mt-3 flex-column flex-md-row">
            {subscription.status === "active" && (
              <Button
                btnType="tertiary"
                className="me-3 my-1 my-md-0"
                onClick={() => setModal("PauseSubscriptionModal")}
              >
                Pause subscription
              </Button>
            )}
            <Button
              btnType="danger"
              className="my-1 my-md-0"
              onClick={() => setModal("CancelSubscriptionModal")}
            >
              Cancel subscription
            </Button>
          </div>
        </div>
      )}
      {subscription.status === "active" && modal === "GetShipmentNowModal" && (
        <GetShipmentNowModal
          isOpen={modal === "GetShipmentNowModal"}
          handleClose={() => setModal(null)}
          subscriptionId={subscription.id}
        />
      )}
      {subscription.status === "active" &&
        modal === "RescheduleShipmentModal" && (
          <RescheduleShipmentModal
            isOpen={modal === "RescheduleShipmentModal"}
            handleClose={() => setModal(null)}
            currentValue={
              subscription.next_dispatch
                ? subscription.next_dispatch.order_date
                : "N/A"
            }
            handleAction={payload =>
              dispatch(rescheduleDelivery(subscription.id, payload, stripe))
            }
          />
        )}
      {modal === "EditFrequencyModal" && (
        <EditFrequencyModal
          isOpen={modal === "EditFrequencyModal"}
          handleClose={() => setModal(null)}
          currentFrequency={{
            interval: subscription.interval,
            period: subscription.period
          }}
          subscriptionId={subscription.id}
        />
      )}
      {modal === "PauseSubscriptionModal" && (
        <PauseSubscriptionModal
          isOpen={modal === "PauseSubscriptionModal"}
          handleClose={() => setModal(null)}
          subscriptionId={subscription.id}
        />
      )}
      {modal === "CancelSubscriptionModal" && (
        <CancelSubscriptionModal
          isOpen={modal === "CancelSubscriptionModal"}
          handleClose={() => setModal(null)}
          subscriptionId={subscription.id}
          handleRetainActions={handleRetainActions}
          currentStatus={subscription.status}
        />
      )}
      {modal === "AddNewPaymentMethodModal" && (
        <AddNewPaymentMethodModal
          isOpen={modal === "AddNewPaymentMethodModal"}
          handleClose={() => setModal(null)}
          subscriptionId={subscription.id}
        />
      )}
      {modal === "ChangePaymentMethodModal" && (
        <ChangePaymentMethodModal
          isOpen={modal === "ChangePaymentMethodModal"}
          handleClose={() => setModal(null)}
          handleAction={payload =>
            dispatch(
              updateSubscriptionPaymentMethod(subscription.id, payload, stripe)
            )
          }
          handleAddNew={() => setModal("AddNewPaymentMethodModal")}
        />
      )}
      {modal === "ActivateSubscriptionModal" && (
        <ActivateSubscriptionModal
          status={subscription.status}
          subscriptionId={subscription.id}
          isOpen={modal === "ActivateSubscriptionModal"}
          handleClose={() => setModal(null)}
        />
      )}
      {modal === "SkipDeliveryModal" && (
        <SkipDeliveryModal
          isOpen={modal === "SkipDeliveryModal"}
          next
          handleClose={() => setModal(null)}
          subscription={subscription}
          deliveryId={subscription?.next_dispatch?.id}
        />
      )}
    </Fragment>
  );
};

export default Details;
