import React from "react";
import cx from "classnames";
import { getRewardPointCost } from "app/loyalty/loyaltyUtilities";

import AssetCup from "assets/img/assetRewardsCup.png";
import IconStar10 from "assets/icons/iconRewardStar10.svg";
import IconStar27 from "assets/icons/iconRewardStar27.svg";

import styles from "./RewardsSummaryBanner.module.scss";
import PointsProgressBar from "app/rewardsPage/PointsProgressBar";
import AvailableRewardCount from "../loyaltyCard/AvailableRewardCount";

const RewardsSummaryBanner = ({ loyalty }) => {
  const rewardPointCost = getRewardPointCost(loyalty);
  const currentPoints = loyalty.account?.points_earned || 0;
  const pointsToNextReward =
    rewardPointCost - (currentPoints % rewardPointCost);

  return (
    <div
      className={cx(styles.container, "d-flex flex-row align-items-stretch")}
    >
      <div
        className={cx(styles.pointContainer, "d-flex align-items-center p-3")}
      >
        <p className={styles.points}>{currentPoints}</p>
        <img
          alt="Your Reward Points."
          className="ms-1"
          width={18}
          height={17}
          src={IconStar27}
        />
      </div>
      <div
        className={cx(
          styles.progressContainer,
          "d-flex flex-grow-1 align-self-stretch align-items-center px-3 position-relative"
        )}
      >
        <div className="flex-grow-1">
          <div className="d-flex flex-row align-items-center">
            <p className="caption-13 color-gray-2">
              {`Next reward in ${pointsToNextReward}`}
            </p>
            <img alt="Grind rewards." className="ms-1" src={IconStar10} />
          </div>
          <PointsProgressBar
            className="mt-2"
            progress={pointsToNextReward}
            total={rewardPointCost}
          />
        </div>

        <img
          className={cx(styles.cup, "ms-2")}
          alt="Grind rewards."
          width={64}
          height={80}
          src={AssetCup}
        />
        <AvailableRewardCount
          availablePoints={currentPoints}
          className={styles.availableRewardCount}
          rewardPointCost={rewardPointCost}
        />
      </div>
    </div>
  );
};

export default RewardsSummaryBanner;
