import React, { useState } from "react";
import Button from "shared/Button";
import { useSelector } from "react-redux";
import Loading from "shared/Loading";
import Separator from "shared/Separator";
import Address from "shared/Address";
import CreateAddressModal from "shared/modals/CreateAddressModal";

const Addresses = () => {
  const {
    account: { addresses },
    auth: { user }
  } = useSelector((s) => s);
  const [modal, setModal] = useState(false);

  if (!addresses) {
    return <Loading />;
  }

  return (
    <>
      {addresses.length === 0 ? (
        <EmptyAddressState />
      ) : (
        <div>
          <p className="gkit-p-20 fw-bold mb-2">Saved addresses</p>
          {addresses.map((address) => (
            <Address
              address={address}
              user={user}
              showDefault={true}
              key={address.id}
              allowDelete
            />
          ))}
          <Separator />
        </div>
      )}
      <Button
        size="md"
        className={`w-100-mobile ${addresses.length === 0 ? "mt-4" : ""}`}
        onClick={() => setModal(true)}
      >
        Add new address
      </Button>
      <CreateAddressModal
        isOpen={modal}
        {...user}
        handleClose={() => setModal(false)}
      />
    </>
  );
};

export default Addresses;

const EmptyAddressState = () => {
  return (
    <div>
      <p className="gkit-p-20 fw-bold mb-2">Delivery address</p>
      <p className="gkit-p-17 gkit-light mt-0">
        Add the delivery address to where you want your subscription and your
        orders to be shipped
      </p>
    </div>
  );
};
