import { createActions } from "redux-actions";
import _ from "lodash";
import {
  SET_PREFERENCES,
  SET_ADDRESSES,
  ADD_ADDRESS_TO_STATE,
  UPDATE_ADDRESSES_STATE,
  DELETE_ADDRESS_FROM_STATE,
  SET_PAYMENT_METHODS,
  UPDATE_PAYMENT_METHODS_STATE,
  DELETE_PAYMENT_METHOD_FROM_STATE,
  ADD_PAYMENT_METHOD_TO_STATE,
  SET_REFERRAL_DATA,
  SET_PENDING_GIFT,
  SET_GIFT_DATA,
  CLAIM_GIFT,
  REDEEM_GIFT,
} from "./actionTypes";
import api, { extractAPIErrorString } from "api";

import { setUser } from "../auth/actions";
import {
  createSubscriptionSuccess,
  updateSubscriptionSuccess,
} from "app/subscriptions/actions";

const {
  setPreferences,
  setAddresses,
  addAddressToState,
  updateAddressesState,
  deleteAddressFromState,
  setPaymentMethods,
  updatePaymentMethodsState,
  addPaymentMethodToState,
  deletePaymentMethodFromState,
  setReferralData,
  setPendingGift,
  setGiftData,
  claimGift,
  redeemGift,
} = createActions(
  SET_PREFERENCES,
  SET_ADDRESSES,
  ADD_ADDRESS_TO_STATE,
  UPDATE_ADDRESSES_STATE,
  DELETE_ADDRESS_FROM_STATE,
  SET_PAYMENT_METHODS,
  UPDATE_PAYMENT_METHODS_STATE,
  ADD_PAYMENT_METHOD_TO_STATE,
  DELETE_PAYMENT_METHOD_FROM_STATE,
  SET_REFERRAL_DATA,
  SET_PENDING_GIFT,
  SET_GIFT_DATA,
  CLAIM_GIFT,
  REDEEM_GIFT
);

export { setPendingGift };

export const getMarketingPreferences = payload => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .get("/account/preferences", payload)
      .then(({ data }) => {
        dispatch(setPreferences(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const setMarketingPreferences = payload => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .put("/account/preferences", payload)
      .then(({ data }) => {
        dispatch(setPreferences(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const updateProfile = payload => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .put("/account/profile", payload)
      .then(({ data }) => {
        dispatch(setUser(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const updateEmail = payload => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .put("/account/profile/email", payload)
      .then(({ data }) => {
        dispatch(setUser(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const getAddresses = payload => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .get("/account/addresses", payload)
      .then(({ data }) => {
        dispatch(setAddresses(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const addAddress = payload => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post("/account/addresses", payload)
      .then(({ data }) => {
        dispatch(addAddressToState(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const updateAddress = (id, payload) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .put(`/account/addresses/${id}`, payload)
      .then(({ data }) => {
        dispatch(updateAddressesState(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const updateAllSubscriptionAddresses = payload => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(addAddress(payload))
      .then(address =>
        api
          .put("/shop/subscriptions/address", { address_id: address.id })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(err => {
            reject(extractAPIErrorString(err));
          })
      )
      .catch(err => reject(err));
  });
};

export const deleteAddress = id => dispatch => {
  return new Promise((resolve, reject) => {
    api.delete(`/account/addresses/${id}`).then(
      () => {
        dispatch(deleteAddressFromState(id));
        resolve();
      },
      err => {
        reject(extractAPIErrorString(err));
      }
    );
  });
};

export const getPaymentMethods = payload => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .get("/account/payment-methods", payload)
      .then(({ data }) => {
        dispatch(setPaymentMethods(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const addPaymentMethod =
  (payload, stripe = null) =>
  dispatch =>
    new Promise((resolve, reject) => {
      api
        .post("/account/payment-methods", payload)
        .then(({ data }) => {
          dispatch(addPaymentMethodToState(data));
          resolve(data);
        })
        .catch(err => {
          if (stripe && requiresAction(err)) {
            handleRequiresAction(err.response, stripe).then(data => {
              dispatch(addPaymentMethodToState(data));
              resolve(data);
            }, reject);
          } else {
            reject(extractAPIErrorString(err));
          }
        });
    });

export const updateAllSubscriptionPaymentMethods = payload => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .put("/shop/subscriptions/payment-method", payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const updatePaymentMethod = (id, payload) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .put(`/account/payment-methods/${id}`, payload)
      .then(({ data }) => {
        dispatch(updatePaymentMethodsState(data));
        dispatch(updateSubscriptionSuccess(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const deletePaymentMethod = id => dispatch => {
  return new Promise((resolve, reject) => {
    api.delete(`/account/payment-methods/${id}`).then(
      () => {
        dispatch(deletePaymentMethodFromState(id));
        resolve();
      },
      err => {
        reject(extractAPIErrorString(err));
      }
    );
  });
};

export const fetchReferralData = () => dispatch =>
  new Promise(resolve => {
    api.get("/account/referral-code").then(
      ({ data }) => {
        dispatch(setReferralData(data));
        resolve(data);
      },
      // We resolve on error because it's an expected response for people
      // who haven't met referral code criteria yet.
      () => resolve()
    );
  });

export const fetchGiftData = () => dispatch =>
  new Promise(resolve => {
    api.get("/shop/subscriptions/gifts").then(
      ({ data: { data } }) => {
        dispatch(setGiftData(data));
        resolve(data);
      },
      () => resolve()
    );
  });

export const claimGiftToken = giftToken => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`/shop/subscriptions/gifts/${giftToken}/claim`)
      .then(({ data }) => {
        //dispatch(setPendingGift(null));
        dispatch(claimGift(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const retrieveGiftData = giftToken => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .get(`/shop/subscriptions/gifts/${giftToken}`)
      .then(({ data }) => {
        dispatch(setPendingGift({ ...data, token: giftToken }));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const startGiftSubscription = (giftId, payload) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`/shop/subscriptions/gifts/${giftId}/start`, payload)
      .then(({ data }) => {
        dispatch(redeemGift(giftId));
        dispatch(createSubscriptionSuccess(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

// Not Actions, but useful shared methods that kinda belong here
export const requiresAction = err =>
  [
    "checkout.requires_action",
    "payment_method.requires_action",
    "charge.requires_action"
  ].indexOf(_.get(err, "response.data.error.code")) !== -1;

export const handleRequiresAction = (
  { data: errResponse },
  stripe,
  subscriptionId = null
) =>
  new Promise((resolve, reject) => {
    const isCharge = Boolean(_.get(errResponse, "error.charge"));
    const intentSecret =
      _.get(
        errResponse,
        isCharge
          ? "error.charge.authorization"
          : "error.payment_method.authorization"
      ) || _.get(errResponse, "error.charge.payment_method_id");

    const paymentMethodId = _.get(
      errResponse,
      isCharge ? "error.charge.payment_method_id" : "error.payment_method.id"
    );

    const stripeHandleFunction = isCharge
      ? stripe.handleCardAction
      : stripe.confirmCardSetup;

    stripeHandleFunction(intentSecret).then(({ error }) => {
      if (error) {
        reject();
      } else {
        api
          .post(
            isCharge
              ? `/shop/subscriptions/${subscriptionId}/confirm-payment-method`
              : `/account/payment-methods/${paymentMethodId}/confirm`
          )
          .then(({ data }) => resolve(data), reject);
      }
    });
  });
