import React, { useState, useMemo } from "react";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useLocation, Redirect } from "react-router-dom";
import { resetPassword } from "./actions";
import ErrorHandling from "shared/ErrorHandling";

import styles from "./ForgotPassword.module.scss";
import InputFieldLine from "shared/Formik/InputFieldLine";
import ButtonPrimary from "shared/ButtonPrimary";

const resetPasswordRegex = new RegExp("^/reset-password/([0-9a-zA-Z]{40})$");

const ResetPassword = params => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [error, setError] = useState();
  const [redirect, setRedirect] = useState();

  // Handle token ingestion
  const resetPasswordToken = useMemo(() => {
    const resetPasswordMatch = location.pathname.match(resetPasswordRegex);
    if (resetPasswordMatch && resetPasswordMatch.length === 2) {
      return resetPasswordMatch[1];
    }

    return null;
  }, [location.pathname]);

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: redirect
        }}
      />
    );
  }

  return (
    <div className="pb-5">
      <h1 className="gkit-h1">Enter your new password.</h1>

      <Formik
        initialValues={{
          password: "",
          token: resetPasswordToken
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .min(6, "Password must be at least 6 characters long.")
            .required()
        })}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(resetPassword(values)).then(
            () => setRedirect("/"),
            err => {
              setError(err);
              setSubmitting(false);
            }
          );
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form className="mt-4">
            <InputFieldLine
              name="password"
              type="password"
              placeholder="Password *"
            />
            <ErrorHandling error={error} />
            <div className={styles.buttonContainer}>
              <ButtonPrimary
                className="d-block w-100"
                type="submit"
                size="md"
                isLoading={isSubmitting}
                disabled={!dirty || !isValid}
              >
                Continue
              </ButtonPrimary>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassword;
