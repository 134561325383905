import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Content.module.scss";
import UnAuthenticatedRouter from "../auth/Router";
import { useLocation } from "react-router-dom";

import { checkForExistingSession, completeLogIn } from "../auth/actions";
import Greeting from "./Greeting";
import Navbar from "./Navbar";
import PageContent from "./PageContent";
import GiftSubscriptionCard from "./GiftSubscriptionCard";
import ReferralCard from "./ReferralCard";
// import DownloadAppCard from "./DownloadAppCard";

import { retrieveGiftData } from "app/account/actions";
import ClaimGiftModal from "shared/modals/ClaimGiftModal";
import Loading from "shared/Loading";
import SurveyCard from "./SurveyCard";

import LoyaltyCard from "./LoyaltyCard";
import MobileRewardsBanner from "./MobileRewardsBanner";
import AuthLayout from "app/auth/AuthLayout";

const adminLoginRegex = new RegExp("^/admin-login/([0-9a-zA-Z]{40})$");
const giftRedemptionRegex = new RegExp("^/gift/([0-9a-fA-F]{40})$");

const ContentLayout = ({ children, className = "" }) => {
  return (
    <div className="content position-relative">
      <div className={styles.gradientBackground} />
      <div className={`container z-index-1 ${className}`}>{children}</div>
    </div>
  );
};

const Content = () => {
  const {
    auth: { user, isLoggedIn },
    account: { pendingGift, referralData },
    loyalty
  } = useSelector(s => s);
  const dispatch = useDispatch();
  const location = useLocation();

  // Handle gift token ingestion
  const giftToken = useMemo(() => {
    const giftTokenMatch = location.pathname.match(giftRedemptionRegex);
    if (giftTokenMatch && giftTokenMatch.length === 2) {
      return giftTokenMatch[1];
    }

    return null;
  }, [location.pathname]);

  // Handle admin login
  const adminLoginToken = useMemo(() => {
    const adminLoginTokenMatch = location.pathname.match(adminLoginRegex);
    if (adminLoginTokenMatch && adminLoginTokenMatch.length === 2) {
      return adminLoginTokenMatch[1];
    }

    return null;
  }, [location.pathname]);

  useEffect(() => {
    if (adminLoginToken) {
      dispatch(completeLogIn(adminLoginToken, true)).catch(error => {
        console.log(error);
      });

      return;
    }

    if (giftToken) {
      dispatch(retrieveGiftData(giftToken));

      return;
    }
  }, [dispatch, adminLoginToken, giftToken]);

  useEffect(() => {
    if (adminLoginToken || isLoggedIn) {
      return;
    }

    dispatch(checkForExistingSession());
  }, [dispatch, isLoggedIn, adminLoginToken]);

  if (!isLoggedIn && isLoggedIn !== false) {
    return (
      <ContentLayout className="p-2 d-flex h-100 justify-content-center align-items-center">
        <Loading />
      </ContentLayout>
    );
  }

  if (!isLoggedIn) {
    return (
      <AuthLayout>
        <UnAuthenticatedRouter />
      </AuthLayout>
    );
  }

  return (
    <>
      <ContentLayout className="pb-4">
        <Greeting firstName={user.first_name} />
        <GiftSubscriptionCard className="d-block d-lg-none" />
        <Navbar />
        <div className="row mt-2 mt-md-4">
          <div className="col-12 col-lg-8 pe-lg-3">
            <PageContent />
          </div>
          <div className="col-12 col-lg-4 mt-3 mt-lg-0 ps-lg-3">
            <GiftSubscriptionCard className="d-none d-lg-block" />
            <LoyaltyCard className="mb-3" loyalty={loyalty} />
            {referralData && <ReferralCard />}
            <SurveyCard />
            {/* <DownloadAppCard /> */}
          </div>
        </div>
        {pendingGift && <ClaimGiftModal />}
        <MobileRewardsBanner loyalty={loyalty} />
      </ContentLayout>
    </>
  );
};

export default Content;
