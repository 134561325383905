import React, { Fragment } from "react";
import { Picklist, Option } from "react-rainbow-components";
import { useField, useFormikContext } from "formik";
import ErrorHandling from "shared/ErrorHandling";

const SpecialSelect = ({ className, options, label, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue, setFieldTouched } = useFormikContext();

  console.log(meta.touched && meta.error);

  return (
    <Fragment>
      <Picklist
        className={`react-rainbow-components ${className}`}
        label={label}
        labelAlignment="left"
        enableSearch
        {...field}
        {...props}
        onBlur={() => setFieldTouched(props.name, true)}
        onChange={(value) => setFieldValue(props.name, value)}
      >
        {options.map((option, idx) => (
          <Option key={idx} {...option} />
        ))}
      </Picklist>
      {meta.error && meta.touched && <ErrorHandling error={meta.error} />}
    </Fragment>
  );
};

export default SpecialSelect;
