import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import styles from "./InnerNavbar.module.scss";

import ChevronDown from "assets/icons/chevron-down.svg";
import ChevronUp from "assets/icons/chevron-up.svg";

const NavigationLink = ({ to, label }) => {
  return (
    <NavLink
      className={`${styles.navLink} mx-xl-4 mx-lg-3`}
      activeClassName={styles.activeNavLink}
      to={to}
    >
      <p className="gkit-p-17 m-0">{label}</p>
    </NavLink>
  );
};

const AccordionLink = ({ to, label, hash, Router }) => {
  const [open, setOpen] = useState(false);

  const Content = () => (
    <div
      id={hash}
      className={`${styles.accordionLink} ${open ? styles.activeNavLink : ""
        } border-right border-left border-bottom gkit-bg-gray-4 justify-content-between`}
      onClick={() => setOpen(!open)}
    >
      <p className="gkit-p-17">{label}</p>
      {open ? (
        <img src={ChevronUp} alt="Chevron up" width={16} height={16} />
      ) : (
        <img src={ChevronDown} alt="Chevron down" width={16} height={16} />
      )}
    </div>
  );

  return (
    <Fragment>
      <HashLink
        to={`${to}#${hash}`}
        scroll={(el) => el.scrollIntoView({ behavior: "auto", block: "end" })}
      >
        <Content />
      </HashLink>

      {open && (
        <div className="p-3">
          <Router />
        </div>
      )}
    </Fragment>
  );
};

const InnerNavbar = ({ tabs, Router = null }) => {
  return (
    <Fragment>
      <div className={`d-none d-md-flex ${styles.navbar}`}>
        {tabs.map((tab) => (
          <NavigationLink {...tab} key={tab.label} />
        ))}
      </div>
      <div className={`d-block d-md-none ${styles.accordionNav}`}>
        {tabs.map((tab) => (
          <AccordionLink
            {...tab}
            key={tab.label}
            Router={Router}
          />
        ))}
      </div>
    </Fragment>
  );
};

export default InnerNavbar;