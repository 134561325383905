import React, { useMemo } from "react";

import "./CardGrid.scss";

// Mapping is based on the length of the items in the grid
// for example if it has 1 item, it will occupy the entire row = 12
// if it has 5 items, it will occupy 25% of the row, row = 3
const defaultDivisor = {
  1: 12,
  2: 12,
  3: 6,
  4: 4,
  5: 3,
  default: 3,
  defaultLarge: 4
};

// Honestly I want to burn this whole component to the ground.
const CardGrid = ({
  divisor,
  children,
  firstOverride,
  override,
  highlightPopular = false
}) => {
  const selectedDivisor = divisor || defaultDivisor;
  // bootstrap overrides if you're the first element.
  const firstOverrideDivisor = useMemo(() => {
    if (!firstOverride) return null;
    return Object.keys(firstOverride).reduce((a, b) => {
      if (b === "xs") {
        a.push(`col-${firstOverride[b]}`);
      } else {
        a.push(`col-${b}-${firstOverride[b]}`);
      }
      return a;
    }, []);
  }, [firstOverride]);

  // Bootstrap overrides for one card.
  const overrideDivisor = useMemo(() => {
    if (!override) return;
    return Object.keys(override).reduce((a, b) => {
      if (b === "xs") {
        a.push(`col-${override[b]}`);
      } else {
        a.push(`col-${b}-${override[b]}`);
      }
      return a;
    }, []);
  }, [override]);

  const elements = useMemo(() => {
    if (!children) {
      return <div className={`card-grid d-flex`}></div>;
    }
    const length = children.length;
    return React.Children.toArray(children).map((child, index) => {
      const thisDivisor =
        (index === 0 && firstOverrideDivisor) || overrideDivisor;
      return (
        <div
          key={index}
          className={`card-grid d-flex
            ${
              thisDivisor
                ? thisDivisor.join(" ")
                : `col-${
                    selectedDivisor[length] || selectedDivisor.default
                  } col-lg-${selectedDivisor.defaultLarge}`
            } ${child.props.popular && highlightPopular ? "col-12" : ""}`}
        >
          {React.cloneElement(child, {
            className: "flex-grow-1"
          })}
        </div>
      );
    });
  }, [
    children,
    selectedDivisor,
    firstOverrideDivisor,
    overrideDivisor,
    highlightPopular
  ]);

  return (
    <div className="container p-0 m-0">
      <div className={"card-grid row card-grid-row"}>{elements}</div>
    </div>
  );
};

export default CardGrid;
