import React, { useState } from "react";
import Button from "./Button";
import AddNewPaymentMethodModal from "./modals/AddNewPaymentMethodModal";
import { updateSubscriptionPaymentMethod } from "app/subscriptions/actions";
import { useDispatch } from "react-redux";
import ErrorMessageModal from "./modals/ErrorMessageModal";
import Modal from "./Modal";
import { useStripe } from "@stripe/react-stripe-js";

const getSubscriptionWarning = subscription => {
  const failure_reasons = {
    card_declined: { message: "Your card was declined.", allowRetry: true },
    card_removed: { message: "Your card was removed." },
    card_deleted: { message: "Your card was deleted." },
    card_expired: { message: "Your card has expired." },
    insufficient_funds: {
      message: "Your card has insufficient funds.",
      allowRetry: true
    },
    authentication_required: {
      message: "Your card requires SCA authentication."
    }
  };
  if (
    subscription.failure_reason &&
    ["failing", "failed"].indexOf(subscription.status) !== -1
  ) {
    const reason = failure_reasons[subscription.failure_reason];
    return {
      type: "error",
      ...reason
    };
  }
  if (
    subscription.status === "active" &&
    subscription.payment_method &&
    subscription.payment_method.deleted_at
  ) {
    return {
      type: "warning",
      message: "Your selected payment card has been removed.",
      explanation:
        "Edit your payment method with a new card to avoid any interruptions."
    };
  }
  if (subscription.status === "failed") {
    return {
      type: "error",
      message: "Your subscription payment has failed.",
      allowRetry: true
    };
  }
  // TODO: handle if card will expire soon
  return null;
};

const PaymentError = ({ subscription }) => {
  const paymentError = getSubscriptionWarning(subscription);
  const [modal, setModal] = useState(null);
  const [error, setError] = useState(null);
  const [successState, setSuccessState] = useState(null);
  const [loadingButton, setLoadingButton] = useState(null);
  const dispatch = useDispatch();
  const stripe = useStripe();

  if (!paymentError) {
    return null;
  }

  return (
    <div className="gkit-bg-red rounded px-3 py-2 d-flex justify-content-between align-items-center mb-3 flex-column flex-sm-row">
      <div className="flex-shrink-1">
        <p className="gkit-p-15 fw-bold text-white">
          {paymentError.message
            ? paymentError.message
            : "Your last payment failed"}
        </p>
        {paymentError.explanation && (
          <p className="gkit-p-15 text-white mt-1">
            {paymentError.explanation}
          </p>
        )}
      </div>
      <div className="ms-sm-3 mt-2 mt-sm-0 flex-shrink-1 d-flex flex-column w-100-mobile">
        <Button
          btnType="tertiary"
          className="bg-white"
          onClick={() => setModal("addNew")}
        >
          Add new card
        </Button>
        {paymentError.allowRetry && subscription.payment_method && (
          <Button
            btnType="tertiary"
            className="mt-2 bg-white"
            isLoading={loadingButton === "updateSubscriptionPaymentMethod"}
            onClick={() => {
              setLoadingButton("updateSubscriptionPaymentMethod");
              dispatch(
                updateSubscriptionPaymentMethod(
                  subscription.id,
                  {
                    payment_method_id: subscription.payment_method.id
                  },
                  stripe
                )
              )
                .then(
                  () =>
                    setSuccessState({
                      title:
                        "The payment has been successfully processed for the subscription"
                    }),
                  err => setError(err)
                )
                .finally(() => setLoadingButton(null));
            }}
          >
            Retry payment
          </Button>
        )}
      </div>
      <AddNewPaymentMethodModal
        isOpen={modal === "addNew"}
        subscriptionId={subscription.id}
        handleClose={() => setModal(null)}
      />
      {error && (
        <ErrorMessageModal
          error={error}
          handleClose={() => {
            setModal(null);
            setError(null);
          }}
        />
      )}
      {successState && (
        <Modal
          isOpen={successState}
          handleClose={() => {
            setModal(null);
            setSuccessState(null);
          }}
          successState={successState}
        />
      )}
    </div>
  );
};

export default PaymentError;
