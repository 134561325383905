import React from "react";
import cx from "classnames";
import GenericModal from "shared/components/GenericModal";

import IconTin from "assets/icons/iconHowToTin.svg";
import IconCoin from "assets/icons/iconHowToCoin.svg";
import IconCup from "assets/icons/iconHowToCup.svg";

const points = [
  {
    icon: IconTin,
    title:
      "Visit Grind and earn points on everything you buy, both in-store and online.",
    body: "We name everything, take-away coffees, bottomless brunch, Grind pods..."
  },
  {
    icon: IconCoin,
    title: "You'll earn 1 point for every £1 you spend.",
    body: "You can see your points balance in the app or on our website."
  },
  {
    icon: IconCup,
    title: "Redeem your points on coffee rewards.",
    body: "Pick up a free coffee, Espresso Martini, box of 10 Nespresso® pods or 227g Whole Bean/Ground pouch."
  }
];

const RewardHowToModal = ({ onClose, show }) => (
  <GenericModal className="modal-dialog-centered" onClose={onClose} show={show}>
    <div className="modal-header">
      <h4 className="body-20 fw-bold">Grind rewards - here's how it works</h4>
      <button
        aria-label="Close"
        className="btn-close"
        onClick={onClose}
        type="button"
      />
    </div>
    <div className="p-3">
      {points.map((point, idx) => (
        <div
          className={cx(
            "d-flex flex-row align-items-center",
            idx > 0 && "mt-4"
          )}
          key={idx}
        >
          <img alt={point.title} src={point.icon} width={60} height={60} />
          <div className="ms-3">
            <p className="gkit-p-17 fw-bold my-0">{point.title}</p>
            <p className="gkit-p-15 fw-regular gkit-light my-0">{point.body}</p>
          </div>
        </div>
      ))}

      <button
        className="w-100 gkit-btn gkit-btn-lg gkit-btn-tertiary mt-4"
        onClick={onClose}
        type="button"
      >
        Got it
      </button>
    </div>
  </GenericModal>
);

export default RewardHowToModal;
