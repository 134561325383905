import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "shared/Button";
import ConfirmProductUpdateModal from "shared/modals/ConfirmProductUpdateModal";
import ProductSelectorModal from "shared/modals/ProductSelectorModal";
import SubscriptionItems from "shared/SubscriptionItems";
import { fetchPlanConfig } from "app/config/actions";
import { priceFormatter } from "shared/utility";
import _ from "lodash";

const Plan = ({ match: { params } }) => {
  const dispatch = useDispatch();
  const subscription = useSelector(
    state => state.subscriptions.lookup[params.id]
  );
  const variantIdMap = useSelector(s =>
    _.get(s, `config.planConfigs.${subscription.country_code}.variantIdMap`)
  );
  const [defaultProducts, setDefaultProducts] = useState(subscription.products);
  const [products, setProducts] = useState(defaultProducts);
  const [modal, setModal] = useState(null);

  useEffect(() => {
    if (!variantIdMap) {
      dispatch(
        fetchPlanConfig({
          country_code: subscription.country_code,
          currency: subscription.currency
        })
      );
    }
  }, [subscription, dispatch, variantIdMap]);

  useEffect(() => {
    setProducts(subscription.products);
    setDefaultProducts(subscription.products);
  }, [subscription.next_dispatch, subscription.products]);

  function handleProductAdd(payload) {
    const variant = variantIdMap[payload.variant_id];
    setProducts([
      ...products,
      {
        product: {
          id: payload.product_id,
          title: variant.format.name
        },
        variant: {
          id: payload.variant_id,
          title: variant.item.title,
          options: [variant.item.title]
        },
        quantity: 1,
        price: {
          amount: `${variant.item.price}`,
          currency: subscription.currency,
          formatted: priceFormatter({
            price: variant.item.price,
            countryCode: subscription.country_code,
            currency: subscription.currency
          })
        },
        total: {
          amount: `${variant.item.price}`,
          currency: subscription.currency,
          formatted: priceFormatter({
            price: variant.item.price,
            countryCode: subscription.country_code,
            currency: subscription.currency
          })
        }
      }
    ]);
    setModal(null);
  }

  return (
    <Fragment>
      <p className="gkit-p-20 fw-bold mb-3">Plan</p>

      {products !== defaultProducts && (
        <div className="alert alert-warning mb-3" role="alert">
          You have unsaved changes. Please click save to update your
          subscription.
        </div>
      )}

      <SubscriptionItems
        setProducts={
          ["active", "paused", "failing", "failed"].indexOf(
            subscription.status
          ) !== -1 && setProducts
        }
        allowEdit
        subscriptionId={subscription.id}
        countryCode={subscription.country_code}
        currency={subscription.currency}
        gift={subscription.gift && subscription.gift.status !== "expired"}
        products={products}
      />
      {subscription.status === "active" && !subscription.gift && (
        <Button
          btnType="tertiary"
          className="mt-3"
          onClick={() => setModal("ProductSelectorModal")}
        >
          Add coffee to order
        </Button>
      )}
      {products !== defaultProducts && (
        <div className="d-flex flex-column flex-md-row mt-3">
          <Button
            btnType="primary"
            onClick={() => setModal("ConfirmProductUpdateModal")}
          >
            Save changes
          </Button>
          <Button
            btnType="danger"
            className="ms-md-3 mt-3 mt-md-0"
            onClick={() => setProducts(defaultProducts)}
          >
            Discard
          </Button>
        </div>
      )}
      {variantIdMap && (
        <ProductSelectorModal
          isOpen={modal === "ProductSelectorModal"}
          handleClose={() => setModal(null)}
          handleAction={handleProductAdd}
          countryCode={subscription.country_code}
        />
      )}
      <ConfirmProductUpdateModal
        isOpen={modal === "ConfirmProductUpdateModal"}
        handleClose={() => setModal(null)}
        products={products}
        subscriptionId={subscription.id}
        countryCode={subscription.country_code}
        currency={subscription.currency}
      />
    </Fragment>
  );
};

export default Plan;
