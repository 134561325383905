export const SET_PREFERENCES = "SET_PREFERENCES";
export const SET_ADDRESSES = "SET_ADDRESSES";
export const SET_PAYMENT_METHODS = "SET_PAYMENT_METHODS";

export const ADD_ADDRESS_TO_STATE = "ADD_ADDRESS_TO_STATE";
export const ADD_PAYMENT_METHOD_TO_STATE = "ADD_PAYMENT_METHOD_TO_STATE";

export const UPDATE_PREFERENCES = "UPDATE_PREFERENCES";
export const UPDATE_ADDRESSES_STATE = "UPDATE_ADDRESSES_STATE";
export const UPDATE_PAYMENT_METHODS_STATE = "UPDATE_PAYMENT_METHODS_STATE";

export const DELETE_ADDRESS_FROM_STATE = "DELETE_ADDRESS_FROM_STATE";
export const DELETE_PAYMENT_METHOD_FROM_STATE = "DELETE_PAYMENT_METHOD_FROM_STATE";

export const SET_REFERRAL_DATA = "SET_REFERRAL_DATA"

export const SET_PENDING_GIFT = "SET_PENDING_GIFT"
export const SET_GIFT_DATA = "SET_GIFT_DATA"
export const CLAIM_GIFT = "CLAIM_GIFT"
export const REDEEM_GIFT = "REDEEM_GIFT" //remove gift subscription from the gifts list