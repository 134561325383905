import React, { Fragment, useState, useEffect } from "react";
import styles from "./SubscriptionItems.module.scss";

import AddIcon from "assets/icons/add.svg";
import MinusIcon from "assets/icons/minus.svg";
import PlaceholderImage from "assets/img/placeholder.png";
import Button from "shared/Button";
import ProductSelectorModal from "./modals/ProductSelectorModal";

import { useSelector, useDispatch } from "react-redux";
import RemoveProductModal from "./modals/RemoveProductModal";
import { NavLink } from "react-router-dom";
import SparklesBadge from "./SparklesBadge";
import Modal from "shared/Modal";
import { fetchPlanConfig } from "app/config/actions";
import { priceFormatter } from "./utility";
import _ from "lodash";

const SubscriptionItems = ({
  subscriptionId,
  products,
  countryCode,
  currency,
  gift,
  setProducts,
  allowEdit,
  isWhite = false,
  hasPadding = true
}) => {
  const dispatch = useDispatch();
  const variantIdMap = useSelector(s =>
    _.get(s, `config.planConfigs.${countryCode}.variantIdMap`)
  );

  useEffect(() => {
    if (!variantIdMap) {
      dispatch(
        fetchPlanConfig({ country_code: countryCode, currency: currency })
      );
    }
  }, [variantIdMap, countryCode, currency, dispatch]);

  function handleQuantityUpdate(action, idx) {
    const itemPrice = products[idx].price.amount / products[idx].quantity;
    const newQuantity = products[idx].quantity + (action === "add" ? 1 : -1);
    const newTotalPrice = newQuantity * itemPrice;
    const newProducts = products.map((product, productIdx) =>
      productIdx !== idx
        ? product
        : {
            ...product,
            quantity: newQuantity,
            price: {
              amount: `${newTotalPrice}`,
              currency: currency,
              formatted: priceFormatter({
                price: newTotalPrice,
                countryCode: countryCode,
                currency: currency
              })
            },
            total: {
              amount: `${newTotalPrice}`,
              currency: currency,
              formatted: priceFormatter({
                price: newTotalPrice,
                countryCode: countryCode,
                currency: currency
              })
            }
          }
    );
    setProducts(newProducts);
  }

  function removeItemFromSubscription(idx) {
    setProducts(products.filter((_, productIdx) => productIdx !== idx));
  }

  function handleProductSwap(payload, idx) {
    // only update if it's not the same product variant
    if (payload.variant_id !== products[idx].variant.id) {
      const variant = variantIdMap[payload.variant_id];
      const newProducts = [...products];

      // if the product is the same and only the variant is updated, only update the variant field
      if (payload.product_id === products[idx].product.id) {
        newProducts.splice(idx, 1, {
          ...products[idx],
          variant: {
            id: payload.variant_id,
            title: variant.item.title,
            options: [variant.item.title]
          }
        });
      } else {
        // otherwise update everything apart from quantity and remove id
        const newPrice = products[idx].quantity * variant.item.price;
        newProducts.splice(idx, 1, {
          quantity: products[idx].quantity,
          product: {
            id: payload.product_id,
            title: variant.format.name
          },
          variant: {
            id: payload.variant_id,
            title: variant.item.title,
            options: [variant.item.title]
          },
          price: {
            amount: `${newPrice}`,
            currency: currency,
            formatted: priceFormatter({
              price: newPrice,
              countryCode: countryCode,
              currency: currency
            })
          },
          total: {
            amount: `${newPrice}`,
            currency: currency,
            formatted: priceFormatter({
              price: newPrice,
              countryCode: countryCode,
              currency: currency
            })
          }
        });
      }
      setProducts(newProducts);
    }
  }

  const ProductsList = () => (
    <div
      className={`rounded ${hasPadding ? "p-3 border" : ""} ${
        isWhite ? "bg-white" : "gkit-bg-gray-4"
      }`}
    >
      {products &&
        products.map((product, idx) => (
          <SubscriptionProduct
            {...product}
            key={idx}
            idx={idx}
            isLast={products.length === idx + 1}
            handleQuantityUpdate={setProducts && handleQuantityUpdate}
            handleProductSwap={handleProductSwap}
            removeItemFromSubscription={removeItemFromSubscription}
            gift={gift}
            items={products}
            countryCode={countryCode}
            variantIdMap={variantIdMap}
          />
        ))}
    </div>
  );
  return (
    <>
      {allowEdit ? (
        <ProductsList />
      ) : (
        <NavLink to={`/subscriptions/${subscriptionId}/details`}>
          <ProductsList />
        </NavLink>
      )}
    </>
  );
};

const SubscriptionProduct = ({
  idx,
  price,
  total,
  product,
  gift,
  countryCode,
  quantity,
  variant,
  isLast,
  items,
  variantIdMap,
  handleQuantityUpdate,
  handleProductSwap,
  removeItemFromSubscription
}) => {
  const [modal, setModal] = useState(false);
  const blendVariants = [...new Set(variant.options)];

  function handleQuantityMinus() {
    if (quantity === 1) {
      if (items.length > 1) {
        setModal("delete");
      } else {
        setModal("last");
      }
    } else {
      handleQuantityUpdate("minus", idx);
    }
  }

  return (
    <div className={`d-flex ${!isLast ? "mb-4" : ""}`}>
      <div
        className={`position-relative d-none d-sm-block me-3 ${styles.imageContainer}`}
      >
        <img
          src={product.image ? product.image.url : PlaceholderImage}
          className="rounded"
          alt={product.title}
        />
        {!handleQuantityUpdate && <Quantity quantity={quantity} />}
      </div>
      <div className="d-flex flex-column flex-md-row flex-grow-1">
        <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex flex-column flex-md-row">
            <p
              className={`gkit-p-20 gkit-sm-p-17 fw-bold mt-2 mt-md-0 mb-0 ${
                gift ? "order-2 order-md-1 mt-2 mt-md-0 flex-grow-1" : ""
              }`}
            >
              {!handleQuantityUpdate && (
                <span className="d-inline d-sm-none">{quantity}x </span>
              )}
              {product.title}
            </p>
            {gift && (
              <SparklesBadge text="Gifted" className="order-1 order-md-2" />
            )}
          </div>
          <p className="gkit-p-15 mt-0 mb-1">
            {blendVariants.length === 1
              ? blendVariants[0]
              : variant.options.join(" / ")}
          </p>
          {!gift && (
            <p className="gkit-p-15 gkit-sm-p-13 fw-bold mt-0 mb-0">
              {total.amount !== price.amount ? (
                <span className="d-flex">
                  <span className="text-success me-2">{total.formatted}</span>
                  <span className="gkit-p-15 gkit-sm-p-13">
                    <s>{price.formatted}</s>
                  </span>
                </span>
              ) : (
                price.formatted
              )}
            </p>
          )}
        </div>
        {handleQuantityUpdate && (
          <div className="d-flex flex-row flex-shrink-0 justify-content-center align-items-center mt-2 mt-md-0">
            {!gift && (
              <Fragment>
                <img
                  src={MinusIcon}
                  height={40}
                  width={40}
                  alt="minus"
                  className="cursor-pointer"
                  onClick={handleQuantityMinus}
                />
                <p className="gkit-p-17 mx-2">{quantity}</p>
                <img
                  src={AddIcon}
                  height={40}
                  width={40}
                  alt="add"
                  className="cursor-pointer"
                  onClick={() => handleQuantityUpdate("add", idx)}
                />
              </Fragment>
            )}
            {variant && variantIdMap && handleProductSwap && (
              <Fragment>
                <Button
                  btnType="secondary"
                  className={gift ? "ms-md-3" : "ms-3"}
                  onClick={() => setModal("productSelector")}
                >
                  Swap
                </Button>
                {variant && variantIdMap && (
                  <ProductSelectorModal
                    initialVariantId={
                      variantIdMap[variant.id] ? variant.id : undefined
                    }
                    isOpen={modal === "productSelector"}
                    handleClose={() => setModal(null)}
                    handleAction={payload => {
                      handleProductSwap(payload, idx);
                      setModal(null);
                    }}
                    gift={gift}
                    countryCode={countryCode}
                  />
                )}
              </Fragment>
            )}
            <RemoveProductModal
              isOpen={modal === "delete"}
              item="Subscription Item"
              handleClose={() => setModal(null)}
              handleAction={() => removeItemFromSubscription(idx)}
            />
            <Modal
              isOpen={modal === "last"}
              title="Your plan can’t be empty"
              handleClose={() => setModal(null)}
              hideCloseButton={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const Quantity = ({ quantity }) => (
  <div className={`gkit-bg-red rounded-circle ${styles.quantity}`}>
    <p className="gkit-p-15 text-white fw-bold">{quantity}</p>
  </div>
);

export default SubscriptionItems;
