/* eslint-disable array-callback-return */
import React from "react";

import Calendar1 from "assets/icons/calendar-1.svg";
import Calendar2 from "assets/icons/calendar-2.svg";
import Calendar3 from "assets/icons/calendar-3.svg";
import Calendar4 from "assets/icons/calendar-4.svg";
import Calendar5 from "assets/icons/calendar-5.svg";
import Calendar6 from "assets/icons/calendar-6.svg";
import Calendar7 from "assets/icons/calendar-7.svg";
import Calendar8 from "assets/icons/calendar-8.svg";
import Calendar9 from "assets/icons/calendar-9.svg";
import Calendar10 from "assets/icons/calendar-10.svg";
import Calendar11 from "assets/icons/calendar-11.svg";
import Calendar12 from "assets/icons/calendar-12.svg";
import OptionCard from "../OptionCard";
import CardGrid from "../CardGrid";

const lessSubscriptionFrequency = [
  {
    id: 1,
    title: "Every 3 weeks",
    image: Calendar3,
    interval: 3,
    period: "week",
    popular: true
  },
  {
    id: 2,
    title: "Every 4 weeks",
    image: Calendar4,
    interval: 4,
    period: "week"
  },
  {
    id: 3,
    title: "Every 6 weeks",
    image: Calendar6,
    interval: 6,
    period: "week"
  },
  {
    id: 4,
    title: "Every 8 weeks",
    image: Calendar8,
    interval: 8,
    period: "week"
  }
];

const moreSubscriptionFrequency = [
  {
    id: 5,
    title: "Every week",
    image: Calendar1,
    interval: 1,
    period: "week"
  },
  {
    id: 6,
    title: "Every 2 weeks",
    image: Calendar2,
    interval: 2,
    period: "week"
  },
  {
    id: 1,
    title: "Every 3 weeks",
    image: Calendar3,
    interval: 3,
    period: "week",
    popular: true
  },
  {
    id: 2,
    title: "Every 4 weeks",
    image: Calendar4,
    interval: 4,
    period: "week"
  },
  {
    id: 7,
    title: "Every 5 weeks",
    image: Calendar5,
    interval: 5,
    period: "week"
  },
  {
    id: 3,
    title: "Every 6 weeks",
    image: Calendar6,
    interval: 6,
    period: "week"
  },
  {
    id: 8,
    title: "Every 7 weeks",
    image: Calendar7,
    interval: 7,
    period: "week"
  },
  {
    id: 4,
    title: "Every 8 weeks",
    image: Calendar8,
    interval: 8,
    period: "week"
  },
  {
    id: 9,
    title: "Every 9 weeks",
    image: Calendar9,
    interval: 9,
    period: "week"
  },
  {
    id: 10,
    title: "Every 10 weeks",
    image: Calendar10,
    interval: 10,
    period: "week"
  },
  {
    id: 11,
    title: "Every 11 weeks",
    image: Calendar11,
    interval: 11,
    period: "week"
  },
  {
    id: 12,
    title: "Every 12 weeks",
    image: Calendar12,
    interval: 12,
    period: "week"
  }
];

const FrequencySection = ({ frequency, onFrequencySelect, showMore }) => {
  return (
    <CardGrid
      override={{
        xs: 6,
        sm: 6,
        md: 4,
        lg: 3,
        xl: 3
      }}
    >
      {(showMore ? moreSubscriptionFrequency : lessSubscriptionFrequency).map(
        (frequencyOption) => (
          <OptionCard
            key={frequencyOption.id}
            {...frequencyOption}
            active={
              frequency &&
              frequencyOption.interval === frequency.interval &&
              frequencyOption.period === frequency.period
            }
            onClick={() =>
              onFrequencySelect({
                interval: frequencyOption.interval,
                period: frequencyOption.period
              })
            }
          />
        )
      )}
    </CardGrid>
  );
};

export default FrequencySection;
