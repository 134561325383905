import React, { Fragment } from "react";
import moment from "moment";

import cx from "classnames";

import styles from "./AddToSubscriptionModal.module.scss";

const SubscriptionBlock = ({
  className,
  isSelected,
  onClick,
  subscription
}) => (
  <button
    className={cx(
      styles.subscriptionContainer,
      isSelected && styles.active,
      "d-block text-start",
      className
    )}
    onClick={onClick}
    type="button"
  >
    <p className="body-15 fw-medium color-charcoal">
      Shipping on{" "}
      {moment(subscription.next_dispatch.order_date).format("D MMM YYYY")} to{" "}
      {subscription.next_dispatch.address.zip}
    </p>
    <p className="body-13 color-gray-0">
      {subscription.next_dispatch.products.map(product => (
        <Fragment key={product.id}>
          <span className="fw-bold">{product.quantity}x</span>{" "}
          {product.product.title} ({product.variant.title})
          <br />
        </Fragment>
      ))}
    </p>
  </button>
);

export default SubscriptionBlock;
