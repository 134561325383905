import React, { useState, useEffect, Fragment, useRef } from "react";
import Button from "./Button";
import SuccessIcon from "assets/icons/success.svg";
import Portal from "./Portal";

const Modal = ({
  title,
  header,
  footer,
  size,
  subtitle,
  dismissable = true,
  children,
  handleClose,
  isLoading = false,
  isOpen,
  successState,
  hideCloseButton = true
}) => {
  const [active, setActive] = useState(false);
  const backdrop = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setActive(true);
    }
    if (!isOpen && active) {
      setTimeout(() => {
        setActive(false);
      }, 250);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, active]);

  useEffect(() => {
    const { current } = backdrop;

    const transitionEnd = () => setActive(isOpen);

    const clickHandler = (e) => e.target === current && handleClose();

    if (current && dismissable) {
      current.addEventListener("transitionend", transitionEnd);
      current.addEventListener("click", clickHandler);
    }

    if (isOpen) {
      window.setTimeout(() => {
        document.activeElement.blur();
        setActive(isOpen);
        document.querySelector("#root").setAttribute("inert", "true");
      }, 10);
    }

    return () => {
      if (current) {
        current.removeEventListener("transitionend", transitionEnd);
        current.removeEventListener("click", clickHandler);
      }

      document.querySelector("#root").removeAttribute("inert");
    };
  }, [isOpen, handleClose, dismissable]);

  return (
    <Fragment>
      {(isOpen || active) && (
        <Portal>
          <div className="modal d-block" ref={backdrop}>
            <div
              className={`modal-dialog modal-dialog-centered modal-dialog-scrollable modal-${
                !successState ? size : "xs"
              }`}
              role="document"
            >
              <div
                className="modal-content shadow"
              >
                {header && (
                  <div className="modal-header">
                    <h3 className="gkit-h3 m-0">{header}</h3>
                    <span
                      className="gkit-h2 m-0 cursor-pointer"
                      onClick={handleClose}
                      aria-hidden="true"
                    >
                      &times;
                    </span>
                  </div>
                )}
                <div className="modal-body">
                  {!successState ? (
                    <div className="p-2 pt-3">
                      {title && (
                        <h3 className="gkit-h3 gkit-sm-p-20 fw-bold mb-2">
                          {title}
                        </h3>
                      )}
                      {subtitle && (
                        <p className="gkit-p-17 gkit-sm-p-15 mt-0 gkit-light mb-4">
                          {subtitle}
                        </p>
                      )}
                      {children}
                    </div>
                  ) : (
                    <div className="p-2 pt-3 text-center">
                      <img
                        width="48"
                        height="48"
                        alt="Success"
                        src={SuccessIcon}
                        className="mb-3"
                      />
                      {successState.title && (
                        <h3 className="gkit-h3 gkit-sm-p-20 fw-bold mb-0">
                          {successState.title}
                        </h3>
                      )}
                      {successState.subtitle && (
                        <p className="gkit-p-17 gkit-sm-p-15 mt-0 gkit-light mt-2">
                          {successState.subtitle}
                        </p>
                      )}
                      <Button className="mt-4" onClick={handleClose}>
                        Close
                      </Button>
                    </div>
                  )}
                </div>
                {footer && <div className="modal-footer">{footer}</div>}
              </div>
            </div>
          </div>
        </Portal>
      )}
    </Fragment>
  );
};

export default Modal;
