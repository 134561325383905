import React, { useCallback, useState } from "react";
import GenericModal from "shared/components/GenericModal";

import cx from "classnames";

import styles from "./OnlineRewardModal.module.scss";
import { useDispatch } from "react-redux";
import { loadLoyaltyReward } from "app/loyalty/actions";

const OnlineRewardModal = ({
  hasActiveSubscriptions,
  onVoucherSelect,
  onAddToSubscriptionSelect,
  onClose,
  reward,
  show
}) => {
  const dispatch = useDispatch();
  const [isFetchingDetail, setIsFetchingDetail] = useState(false);

  /**
   * This grabs the detail for the reward in order to get the product info
   * you need in order to add it to your subscription.
   */
  const onAddToSubscriptionClick = useCallback(() => {
    if (isFetchingDetail) {
      return;
    }

    setIsFetchingDetail(true);

    dispatch(loadLoyaltyReward(reward.id))
      .then(rewardDetail => {
        onAddToSubscriptionSelect(rewardDetail);
      })
      .finally(() => setIsFetchingDetail(false));
  }, [dispatch, isFetchingDetail, onAddToSubscriptionSelect, reward]);

  return (
    <GenericModal
      className="modal-dialog-centered modal-sm"
      onClose={onClose}
      show={show}
    >
      <div className="modal-header">
        <button
          aria-label="Close"
          className="btn-close"
          onClick={onClose}
          type="button"
        />
      </div>
      <div className="modal-body">
        {Boolean(reward.image_url) && (
          <img
            alt={reward.title}
            className={cx(styles.rewardImage, "mb-2")}
            src={reward.image_url}
          />
        )}
        <p className="gkit-h3 text-start">{reward.title}</p>
        {Boolean(reward.prompt) && (
          <p className="body-17 color-gray-0">{reward.prompt}</p>
        )}
        <div className="mt-4">
          {hasActiveSubscriptions && (
            <button
              className={cx(
                "gkit-btn gkit-btn-md gkit-primary d-block w-100 mb-2",
                isFetchingDetail && "gkit-loading"
              )}
              onClick={onAddToSubscriptionClick}
              type="button"
            >
              Add to my subscription
            </button>
          )}

          <button
            className="gkit-btn gkit-btn-md gkit-tertiary w-100 d-block"
            onClick={() => onVoucherSelect(reward)}
            type="button"
          >
            Get voucher code
          </button>
        </div>
      </div>
    </GenericModal>
  );
};

export default OnlineRewardModal;
