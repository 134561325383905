import React from "react";
import { Application, Spinner } from "react-rainbow-components";
import GrindIcon from "assets/img/grind-logo.svg";

const theme = {
  rainbow: {
    palette: {
      brand: "#f5dadf"
    }
  }
};

const Loading = () => {
  return (
    <div className="d-block position-relative" style={{ height: "100px" }}>
      <Application theme={theme}>
        <Spinner size="x-large" type="arc" variant="brand">
          <img src={GrindIcon} alt="Grind logo" width={60} />
        </Spinner>
      </Application>
    </div>
  );
};

export default Loading;
