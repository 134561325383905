import React, { useState } from "react";

import cx from "classnames";
import GenericModal from "shared/components/GenericModal";
import VariantSelectModalContent from "./addToSubscriptionModal/VariantSelectModalContent";
import SubscriptionSelectModalContent from "./addToSubscriptionModal/SubscriptionSelectModalContent";
import SubscriptionSuccessModalContent from "./addToSubscriptionModal/SubscriptionSuccessModalContent";

const AddToSubscriptionModal = ({ onClose, reward, show, subscriptions }) => {
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [initialSelectedValues, setInitialSelectedValues] = useState({});
  const [hasClaimed, setHasClaimed] = useState(false);

  if (!reward?.product) {
    return null;
  }

  return (
    <GenericModal
      className={cx("modal-dialog-centered")}
      onCleanup={() => {
        setSelectedVariant(null);
        setInitialSelectedValues({});
        setHasClaimed(false);
      }}
      onClose={onClose}
      show={show}
    >
      {hasClaimed && <SubscriptionSuccessModalContent onClose={onClose} />}

      {!hasClaimed &&
        !selectedVariant &&
        reward.product.options?.length > 0 && (
          <VariantSelectModalContent
            initialSelectedValues={initialSelectedValues}
            onClose={onClose}
            onVariantSelect={setSelectedVariant}
            reward={reward}
          />
        )}

      {!hasClaimed &&
        (selectedVariant || reward.product.options?.length === 0) && (
          <SubscriptionSelectModalContent
            onClose={onClose}
            onVariantClear={() => {
              setInitialSelectedValues(
                selectedVariant.options.reduce((acc, val, idx) => {
                  acc[idx] = val;
                  return acc;
                }, {})
              );

              setSelectedVariant(null);
            }}
            onClaimed={() => setHasClaimed(true)}
            reward={reward}
            subscriptions={subscriptions}
            variant={selectedVariant}
          />
        )}
    </GenericModal>
  );
};

export default AddToSubscriptionModal;
