import React, { useState } from "react";
import CustomDatePicker from "../inputs/CustomDatePicker";
import Modal from "../Modal";
import Button from "../Button";
import moment from "moment";
import ErrorHandling from "shared/ErrorHandling";

const RescheduleShipmentModal = ({
  isOpen,
  currentValue,
  handleAction,
  handleClose
}) => {
  const minDate = moment().add(1, "day").startOf("day").toDate();
  const maxDate = moment().add(3, "month").startOf("day").toDate();
  const [nextDate, setNextDate] = useState(currentValue);

  const [successState, setSuccessState] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  function handleCloseAndReset() {
    handleClose();
    setTimeout(() => {
      setError(null);
      setSuccessState(null);
    }, 1000);
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleCloseAndReset}
      title="Change your next order date"
      subtitle="Let us know when you'd like your next order to be created."
      successState={successState}
    >
      <CustomDatePicker
        value={nextDate}
        onChange={setNextDate}
        name="next_date"
        minDate={minDate}
        maxDate={maxDate}
      />
      <ErrorHandling error={error} />
      <div className="mt-4">
        <Button
          className="me-3"
          isLoading={isSubmitting}
          onClick={() => {
            setSubmitting(true);
            handleAction({ next_date: nextDate })
              .then((data) => {
                setSuccessState({
                  title: "Your order has been rescheduled"
                });
              })
              .finally(() => setSubmitting(false));
          }}
        >
          Update
        </Button>
        <Button
          btnType="tertiary"
          className="mt-2 mt-md-0"
          onClick={handleCloseAndReset}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default RescheduleShipmentModal;
