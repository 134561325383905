import React, { useState } from "react";
import Button from "../Button";
import Modal from "../Modal";

import ErrorHandling from "shared/ErrorHandling";

const RemoveProductModal = ({ isOpen, item = "", handleAction, handleClose }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [successState, setSuccessState] = useState(null);

  function handleCloseAndReset() {
    handleClose();
    setTimeout(() => {
      setError(null);
      setSuccessState(null);
    }, 1000);
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleCloseAndReset}
      title={`Delete ${item}`}
      subtitle={`Are you sure you want to remove this ${item.toLowerCase()}?`}
      successState={successState}
    >
      <ErrorHandling error={error} />
      <div className="mt-4">
        <Button
          btnType="primary"
          isLoading={isSubmitting}
          onClick={() => {
            setSubmitting(true);
            handleAction()
          }}
          className="me-3 gkit-bg-red"
        >
          Yes, delete
        </Button>
        <Button
          btnType="tertiary"
          className="mt-2 mt-md-0"
          onClick={handleCloseAndReset}
        >
          No, I want to keep it
        </Button>
      </div>
    </Modal>
  );
};

export default RemoveProductModal;
