import React from "react";
import cx from "classnames";
import style from "./Button.module.scss";

const Button = ({
  to = false,
  external,
  size = "sm",
  children,
  className = "",
  btnType = "primary",
  disabled,
  isLoading,
  ...props
}) => {
  const classes = cx(
    `gkit-btn-${size}`,
    !disabled && `gkit-${btnType}`,
    disabled && "disabled",
    isLoading && "gkit-loading",
    size === "sm" && "w-100-mobile",
    className
  );

  const content = isLoading ? "loading..." : children;

  if (to) {
    return (
      <a href={to} className={classes} disabled={disabled} {...props}>
        {content}
      </a>
    );
  } else {
    return (
      <button className={classes} disabled={disabled} {...props}>
        {content}
      </button>
    );
  }
};

export default Button;

export const ButtonIcon = ({
  alt,
  icon,
  className,
  size = "sm",
  children,
  dirn = "left",
  isLoading = false,
  ...props
}) => {
  return (
    <button
      className={`gkit-btn-${size} ${style.btnIcon} ${className} ${
        isLoading ? "gkit-loading gkit-tertiary bg-transparent" : ""
      }`}
      {...props}
    >
      {dirn !== "left" ? children : ""}
      {isLoading ? (
        <span className="d-none">loading..</span>
      ) : (
        <img
          className={children ? (dirn === "left" ? "me-2" : "ms-2") : ""}
          src={icon}
          alt={alt}
          width={24}
          height={24}
        />
      )}
      {dirn === "left" ? children : ""}
    </button>
  );
};
