import React, { useState } from "react";
import Modal from "../Modal";
import Button from "../Button";
import { useSelector } from "react-redux";
import Radio from "shared/inputs/Radio";
import ErrorHandling from "shared/ErrorHandling";
import { stackedAddress } from "shared/utility";

const ChangeAddressModal = ({
  isOpen,
  handleClose,
  handleAction,
  handleAddNewAddress,
  isBilling = false,
  countryCode
}) => {
  const existingAddresses = useSelector((state) => state.account.addresses);
  const { countries } = useSelector((s) => s.config);
  const [selectedAddressId, setSelectedAddressId] = useState();
  const [successState, setSuccessState] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);

  function handleCloseAndReset() {
    handleClose();
    setTimeout(() => {
      setError(null);
      setSuccessState(null);
    }, 1000);
  }

  function handleAddresssUpdate() {
    setSubmitting(true);
    handleAction({ address_id: selectedAddressId })
      .then(() =>
        setSuccessState({
          title: `Successfully updated the ${
            isBilling ? "invoice" : "delivery"
          } address`
        })
      )
      .catch((err) => setError(err))
      .finally(() => setSubmitting(false));
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleCloseAndReset}
      title={`Change ${isBilling ? "Invoice" : "Delivery"} Address`}
      subtitle={`Tell us where you want ${
        isBilling ? "the invoice to be sent" : "your orders to be delivered"
      }`}
      successState={successState}
    >
      {existingAddresses &&
        existingAddresses.map((address) => {
          if (address.country_code !== countryCode) {
            return null;
          }
          return (
            <Radio
              key={address.id}
              onChange={() => setSelectedAddressId(address.id)}
              checked={selectedAddressId === address.id}
            >
              <p className="gkit-p-15 m-0">
                {stackedAddress({ countries: countries, ...address })}
              </p>
            </Radio>
          );
        })}
      <ErrorHandling error={error} />
      <div className="d-flex flex-column flex-md-row justify-content-between mt-3">
        <div>
          <Button
            className="me-3"
            onClick={handleAddresssUpdate}
            disabled={!selectedAddressId}
            isLoading={isSubmitting}
          >
            Update
          </Button>
          <Button
            btnType="tertiary"
            onClick={handleCloseAndReset}
            className="mt-2 mt-md-0"
          >
            Cancel
          </Button>
        </div>
        {handleAddNewAddress && (
          <Button
            btnType="secondary"
            className="mt-2 mt-md-0"
            onClick={handleAddNewAddress}
          >
            Add new
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ChangeAddressModal;
