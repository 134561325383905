import React, { useCallback, useState } from "react";

import cx from "classnames";

import styles from "./JoinRewardsProgram.module.scss";

import ImageRewards from "assets/img/assetRewardsComingSoon.jpg";
import IconRewardStar from "assets/icons/iconRewardStar18.svg";
import { useDispatch } from "react-redux";
import { enrolInRewardScheme } from "app/loyalty/actions";
import { extractAPIErrorString } from "api";

const JoinRewardsProgram = () => {
  const dispatch = useDispatch();

  const [isJoining, setIsJoining] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const onJoinPress = useCallback(() => {
    setIsJoining(true);
    setErrorText(null);

    dispatch(enrolInRewardScheme())
      .then(
        data => {
          console.log("Enrolled! ", data);
        },
        err => setErrorText(extractAPIErrorString(err))
      )
      .finally(() => setIsJoining(false));
  }, [dispatch]);

  return (
    <div className="d-flex flex-column align-items-center pb-3">
      <img className={styles.img} src={ImageRewards} alt="Grind Rewards" />
      <h2 className="headline-28 mt-2">Grind Rewards.</h2>
      <p className={cx(styles.body, "body-17 color-gray-0 text-center mt-3")}>
        Buy coffee in-store, on our website or through the Grind app to earn
        points towards free coffee.
      </p>
      <button
        className={cx(
          "gkit-btn gkit-primary gkit-btn-lg mt-4",
          isJoining && "gkit-loading"
        )}
        onClick={onJoinPress}
        type="button"
      >
        <div className="d-flex align-items-center">
          {!isJoining && (
            <img alt="Rewards Program" className="me-2" src={IconRewardStar} />
          )}
          Join rewards program
        </div>
      </button>
      {errorText && (
        <p className="body-15 text-center text-danger mt-3">{errorText}</p>
      )}
      <p
        className={cx(
          styles.smallPrint,
          "body-13 color-gray-1 text-center mt-3"
        )}
      >
        By joining you agree to receive reward emails and other marketing
        messages.
      </p>
    </div>
  );
};

export default JoinRewardsProgram;
