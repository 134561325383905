import React, { useState } from "react";
import Modal from "../Modal";
import { useDispatch } from "react-redux";
import Button from "../Button";
import {
  skipDelivery,
  getSubscription,
  clearSubscriptions
} from "app/subscriptions/actions";

import moment from "moment";

import ErrorHandling from "shared/ErrorHandling";

const SkipDeliveryModal = ({
  isOpen,
  subscriptionId,
  next = false,
  deliveryId,
  handleClose,
  updateSchedule
}) => {
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [successState, setSuccessState] = useState(null);

  function handleCloseAndReset() {
    handleClose();
    setTimeout(() => {
      setError(null);
      setSuccessState(null);
    }, 1000);
  }

  function handleSkip() {
    setSubmitting(true);
    new Promise(async resolve => {
      try {
        await dispatch(skipDelivery(subscriptionId, deliveryId, true)).then(
          () => {
            dispatch(getSubscription(subscriptionId)).then(data =>
              setSuccessState({
                title: `${next ? "Next" : "Selected"} delivery skipped`,
                subtitle: next
                  ? `Your new next delivery date is ${moment(
                      data.next_dispatch.order_date,
                      "YYYY-MM-DD"
                    ).format("DD/MM/YYYY")}`
                  : null
              })
            );
            updateSchedule();
            dispatch(clearSubscriptions());
            resolve();
          }
        );
      } catch (e) {
        setError(e);
      }
    }).finally(() => setSubmitting(false));
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleCloseAndReset}
      title="Skip Delivery"
      subtitle={`Do you want to skip ${next ? "the next" : "this"} delivery?`}
      successState={successState}
    >
      <ErrorHandling error={error} />
      <div className="mt-4">
        <Button className="me-3" onClick={handleSkip} isLoading={isSubmitting}>
          Yes, skip delivery
        </Button>
        <Button
          btnType="tertiary"
          onClick={handleCloseAndReset}
          className="mt-2 mt-md-0"
        >
          No, I still want it
        </Button>
      </div>
    </Modal>
  );
};

export default SkipDeliveryModal;
