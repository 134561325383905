import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InnerNavbar from "shared/InnerNavbar";
import Router from "./Router";
import { useParams } from "react-router";
import Button from "shared/Button";
import Loading from "shared/Loading";

import ArrowLeftIcon from "assets/icons/arrow-left.svg";
import { NavLink } from "react-router-dom";
import PaymentError from "shared/PaymentError";
import { getSubscription } from "app/subscriptions/actions";

const SubscriptionDetails = () => {
  const params = useParams();
  const id = params.id;
  const subscription = useSelector(state => state.subscriptions.lookup[id]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!subscription) {
      dispatch(getSubscription(id));
    }
  }, [subscription, dispatch, id]);

  const activeTabs = [
    {
      to: `/subscriptions/${id}/details`,
      label: "Subscription details",
      hash: "details"
    },
    {
      to: `/subscriptions/${id}/plan`,
      label: "Your plan",
      hash: "plan"
    },
    {
      to: `/subscriptions/${id}/deliveries`,
      label: "Scheduled orders",
      hash: "deliveries"
    }
  ];

  const inactiveTabs = [
    {
      to: `/subscriptions/${id}/details`,
      label: "Subscription details",
      hash: "details"
    },
    {
      to: `/subscriptions/${id}/plan`,
      label: "Your plan",
      hash: "plan"
    }
  ];

  return (
    <div>
      <NavLink to="/subscriptions">
        <Button className="mb-4" size="md">
          <img
            src={ArrowLeftIcon}
            width={24}
            height={24}
            alt="Back to subscriptions"
            className="me-2"
          />
          Back to subscriptions
        </Button>
      </NavLink>
      <p className="gkit-h2 gkit-sm-h3 mb-2 pt-2">Manage your subscription</p>
      <p className="gkit-p-20 gkit-sm-p-17 gkit-light mb-4">
        Update your subscription to make it work best for you.
      </p>
      {subscription && <PaymentError subscription={subscription} />}
      {!subscription ? (
        <Loading />
      ) : (
        <Fragment>
          <InnerNavbar
            tabs={
              subscription &&
                subscription.status === "active" &&
                subscription.next_dispatch
                ? activeTabs
                : inactiveTabs
            }
            Router={Router}
          />
          <div className="d-none d-md-block">
            <Router />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default SubscriptionDetails;
