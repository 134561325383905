import React, { useState } from "react";
import cx from "classnames";
import GenericModal from "shared/components/GenericModal";

import GenerateCodeModalContent from "./generateVoucherModal/GenerateCodeModalContent";
import CodeSuccessModalContent from "./generateVoucherModal/CodeSuccessModalContent";

const GenerateVoucherModal = ({ onClose, reward, show }) => {
  const [generatedDiscount, setGeneratedDiscount] = useState(null);

  if (!reward) {
    return null;
  }

  return (
    <GenericModal
      className={cx("modal-dialog-centered")}
      onCleanup={() => {
        setGeneratedDiscount(null);
      }}
      onClose={onClose}
      show={show}
    >
      <div className="modal-header">
        <h4 className="body-20 fw-bold">Generate free code</h4>
        <button
          aria-label="Close"
          className="btn-close"
          onClick={onClose}
          type="button"
        />
      </div>
      {generatedDiscount ? (
        <CodeSuccessModalContent
          discount={generatedDiscount}
          onClose={onClose}
        />
      ) : (
        <GenerateCodeModalContent
          onClose={onClose}
          onDiscountGenerated={setGeneratedDiscount}
          reward={reward}
        />
      )}
    </GenericModal>
  );
};

export default GenerateVoucherModal;
