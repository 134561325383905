import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  Fragment
} from "react";
import { useSelector, useDispatch } from "react-redux";

import Button from "shared/Button";
import FormatSection from "shared/config/FormatSection";

import BlendSection from "shared/config/BlendSection";
import { identifyVariantRawParameters } from "shared/utility";
import { fetchPlanConfig } from "app/config/actions";
import Loading from "shared/Loading";

const ProductSelector = ({
  subscription,
  setSettings,
  handleAction,
  prevModal,
  handleGoBack,
  handleWizardClose,
  countryCode
}) => {
  const dispatch = useDispatch();
  const config = useSelector(s => s.config.planConfigs[countryCode]);

  const emptyState = {
    format_id: 0,
    size_id: 0,
    variant_id: "",
    mixAndMatchPick: null,
    product_id: ""
  };

  const [values, setValues] = useState(
    subscription.variant_id && config.variantIdMap
      ? identifyVariantRawParameters(
          config.variantIdMap[subscription.variant_id],
          config.formats
        )
      : emptyState
  );

  useEffect(() => {
    if (!config) {
      dispatch(fetchPlanConfig({ country_code: countryCode }));
    }
  }, [config, countryCode, dispatch]);

  useEffect(() => {
    setSettings({
      size: "lg",
      footer: (
        <div className="d-flex">
          <Button
            className="me-3"
            disabled={values.variant_id ? false : true}
            onClick={() => handleAction(values.variant_id)}
          >
            Continue
          </Button>
          {prevModal ? (
            <Button btnType="tertiary" onClick={handleGoBack}>
              Back
            </Button>
          ) : (
            <Button btnType="tertiary" onClick={handleWizardClose}>
              Cancel
            </Button>
          )}
        </div>
      )
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.variant_id]);

  const blendRef = useRef();

  const handleSelect = useCallback(
    payload => {
      setValues({ ...values, ...payload, size_id: 0 });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values]
  );

  if (!config) {
    return <Loading />;
  }

  return (
    <Fragment>
      <FormatSection
        excludeIndex={3}
        nextRef={blendRef}
        {...values}
        handleSelect={handleSelect}
        countryCode={countryCode}
      />
      <div ref={blendRef}>
        {values.size_id !== "" && (
          <BlendSection
            {...values}
            handleSelect={handleSelect}
            countryCode={countryCode}
          />
        )}
      </div>
    </Fragment>
  );
};

export default ProductSelector;
