import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import store from "./redux/store";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "shared/ScrollToTop";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "assets/styles/index.scss";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import constants from "api/constants";
import { globalErrorHandler } from "errors";

Bugsnag.start({
  apiKey: "a405fdb2da0cd97f83f379a8d7a6f90b",
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["local", "development", "production"],
  releaseStage: constants.bugsnagReleaseStage || process.env.NODE_ENV,
  onError: globalErrorHandler,
  ...(process.env.NODE_ENV === "development"
    ? { enabledBreadcrumbTypes: [] }
    : {})
});
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
const stripePromise = loadStripe(constants.stripeKey);

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <Router>
        <Helmet>
          <title>GRIND | Customer Portal</title>
          <meta property="og:title" content="GRIND | Customer Portal" />
          <meta property="og:site_name" content="GRIND | Customer Portal" />
          <link rel="canonical" href="https://my.grind.co.uk" />
        </Helmet>
        <ScrollToTop>
          <Elements
            options={{
              fonts: [
                {
                  cssSrc: "https://static.grind.co.uk/gkit/fonts.css"
                }
              ]
            }}
            stripe={stripePromise}
          >
            <App />
          </Elements>
        </ScrollToTop>
      </Router>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
