import React, { useState } from "react";
import DownloadIcon from "assets/icons/download.svg";
import ProductTag from "assets/icons/tag.svg";

import moment from "moment";
import { downloadInvoice } from "./actions";
import { ButtonIcon } from "shared/Button";

const OrderHistoryItem = ({
  id,
  order_number,
  created_at,
  products,
  subtotal,
  total
}) => {
  const [downloading, setDownLoading] = useState(false);
  function handleInvoiceDownload() {
    setDownLoading(true);
    downloadInvoice(id).then(() => setDownLoading(false));
  }

  return (
    <tr
      className="gkit-bg-gray-4"
      style={{
        borderBottom: "16px solid white"
      }}
    >
      <th scope="row" className="py-3 ps-3">
        <p className="gkit-p-15 fw-bold mb-1">#{order_number}</p>
        <p className="gkit-p-15 m-0">
          {moment(created_at, "YYYY-MM-DD").format("D MMM YYYY")}
        </p>
      </th>
      <td className="py-3">
        {products.map((product, idx) => (
          <SingleProduct {...product} key={idx} />
        ))}
      </td>
      <td className="py-3">
        <p className="gkit-p-15 gkit-bold m-0 text-right">
          {total.formatted === subtotal.formatted ? (
            total.formatted
          ) : (
            <>
              <span className="me-2 text-success">{total.formatted}</span>
              <span
                style={{
                  textDecoration: "line-through",
                  fontWeight: "normal"
                }}
              >
                {subtotal.formatted}
              </span>
            </>
          )}
        </p>
      </td>
      <td className="py-3 text-center">
        <ButtonIcon
          icon={DownloadIcon}
          isLoading={downloading}
          onClick={handleInvoiceDownload}
          alt="Download invoice"
        />
      </td>
    </tr>
  );
};

export default OrderHistoryItem;

const SingleProduct = ({ quantity, product }) => (
  <div className="mb-2 d-flex align-items-start">
    <img src={ProductTag} width={16} height={16} alt="Product tag" />
    <p className="gkit-p-15 m-0 ms-2">
      {quantity}x {product.title}
    </p>
  </div>
);

export { SingleProduct };
