import React, { useState, useRef, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import Button from "../Button";
import Modal from "../Modal";
import { LoadingShape } from "react-rainbow-components";
import FormatSection from "../config/FormatSection";
import SizeSection from "../config/SizeSection";

import BlendSection from "../config/BlendSection";
import { identifyVariantRawParameters } from "../utility";

const ProductSelectorModal = ({
  isOpen,
  gift = false,
  initialVariantId,
  handleClose,
  handleAction,
  prevModal,
  handleGoBack,
  countryCode
}) => {
  const { variantIdMap, formats } = useSelector(
    (s) => s.config.planConfigs[countryCode]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const emptyState = {
    format_id: 0,
    size_id: 0,
    variant_id: "",
    mixAndMatchPick: null,
    product_id: ""
  };

  const [values, setValues] = useState(
    initialVariantId && variantIdMap
      ? identifyVariantRawParameters(variantIdMap[initialVariantId], formats)
      : emptyState
  );

  useEffect(() => {
    if (initialVariantId && isOpen) {
      setValues(
        initialVariantId && variantIdMap
          ? identifyVariantRawParameters(
            variantIdMap[
            values.variant_id ? values.variant_id : initialVariantId
            ],
            formats
          )
          : emptyState
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const sizeRef = useRef();
  const blendRef = useRef();

  const handleSelect = useCallback(
    (payload) => {
      setValues({ ...values, ...payload });
    },
    [values]
  );

  if (!variantIdMap || !formats) {
    return <LoadingShape />;
  }

  function closeAndResetModal() {
    setValues(emptyState);
    handleClose();
  }

  function handleModalAction() {
    handleAction(values);
  }

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      handleClose={closeAndResetModal}
      footer={
        <div className="d-flex">
          <Button
            className="me-3"
            disabled={values.variant_id ? false : true}
            onClick={handleModalAction}
          >
            Continue
          </Button>
          {prevModal ? (
            <Button btnType="tertiary" onClick={handleGoBack}>
              Back
            </Button>
          ) : (
            <Button btnType="tertiary" onClick={closeAndResetModal}>
              Cancel
            </Button>
          )}
        </div>
      }
    >
      <FormatSection
        nextRef={sizeRef}
        {...values}
        handleSelect={handleSelect}
        countryCode={countryCode}
      />
      <div ref={sizeRef}>
        <SizeSection
          blendRef={blendRef}
          gift={gift}
          {...values}
          handleSelect={handleSelect}
          countryCode={countryCode}
        />
      </div>
      <div ref={blendRef}>
        {values.size_id !== "" && (
          <BlendSection
            {...values}
            handleSelect={handleSelect}
            countryCode={countryCode}
          />
        )}
      </div>
    </Modal>
  );
};

export default ProductSelectorModal;
