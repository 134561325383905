const standardErrorRequestURLs = {
  "/account/addresses": {
    ignore: false
  },
  "/account/payment-cards": {
    ignore: false
  },
  "/account/payment-methods": {
    ignore: false
  },
  "/account/preferences": {
    ignore: false
  },
  "/account/referral-code": {
    ignore: false
  },
  "/auth/login": {
    ignore: false
  },
  "/auth/logout": {
    ignore: false
  },
  "/auth/magic-login": {
    ignore: false
  },
  "/auth/email": {
    ignore: [404]
  },
  "/auth/register": {
    ignore: false
  },
  "/shop/countries": {
    ignore: false
  },

  "/shop/subscriptions/address": {
    ignore: false
  },
  "/shop/subscriptions/payment-method": {
    ignore: false
  },
  "/shop/subscriptions/gifts": {
    ignore: false
  },
  "/shop/subscriptions/plan-config": {
    ignore: false
  },
  "/shop/orders": {
    ignore: false
  },
  "/account/profile": {
    ignore: false
  },
  "/shop/subscriptions": {
    ignore: false
  },
  "/shop/payment-providers": {
    ignore: false
  }
};

const nonStandardErrorRequestUrls = [
  {
    url: /^\/account\/payment-cards\/\d+\/confirm$/,
    name: "/account/payment-cards/ID/confirm",
    ignore: false
  },
  {
    url: /^\/account\/payment-methods\/\d+\/confirm$/,
    name: "/account/payment-methods/ID/confirm",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/\d+$/,
    name: "/shop/subscriptions/ID",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/\d+\/address$/,
    name: "/shop/subscriptions/ID/address",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/\d+\/billing-address$/,
    name: "/shop/subscriptions/ID/billing-address",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/\d+\/cancel$/,
    name: "/shop/subscriptions/ID/cancel",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/\d+\/confirm-payment-method$/,
    name: "/shop/subscriptions/ID/confirm-payment-method",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/\d+\/discount$/,
    name: "/shop/subscriptions/ID/discount",
    ignore: false
  },
  {
    url: /^\/shop\/orders\/\d+\/invoice-pdf$/,
    name: "/shop/orders/ID/invoice-pdf",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/\d+\/pause$/,
    name: "/shop/subscriptions/ID/pause",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/\d+\/payment-method$/,
    name: "/shop/subscriptions/ID/payment-method",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/\d+\/products$/,
    name: "/shop/subscriptions/ID/products",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/\d+\/reactivate$/,
    name: "/shop/subscriptions/ID/reactivate",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/\d+\/reschedule$/,
    name: "/shop/subscriptions/ID/reschedule",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/\d+\/resume$/,
    name: "/shop/subscriptions/ID/resume",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/\d+\/schedule$/,
    name: "/shop/subscriptions/ID/schedule",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/\d+\/schedule\/\d+\/address$/,
    name: "/shop/subscriptions/ID/schedule/ID/address",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/\d+\/schedule\/\d+\/skip$/,
    name: "/shop/subscriptions/ID/schedule/ID/skip",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/gifts\/[a-z0-9]{12,40}\/claim$/,
    name: "/shop/subscriptions/gifts/ID/claim",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/gifts\/[a-z0-9]{12,40}\/start$/,
    name: "/shop/subscriptions/gifts/ID/start",
    ignore: false
  },
  {
    url: /^\/shop\/subscriptions\/gifts\/[a-z0-9]{12,40}$/,
    name: "/shop/subscriptions/gifts/ID",
    ignore: false
  }
];

export const globalErrorHandler = event => {
  const err = event.originalError;

  // Check if it's a HTTP Request
  const apiMethod = err?.config?.method;
  const apiUrl = err?.config.url;

  if (apiUrl) {
    const responseStatus = err?.response?.status;
    const responseErrorCode = err?.response?.data?.error?.code;

    const pathObject =
      standardErrorRequestURLs[apiUrl] ||
      nonStandardErrorRequestUrls.find(({ url }) => url.test(apiUrl))?.name;

    if (pathObject) {
      if (
        pathObject.ignore === true ||
        pathObject.ignore?.indexOf?.(responseStatus) !== -1 ||
        pathObject.ignoreCodes?.indexOf?.(responseErrorCode) !== -1
      ) {
        return false;
      }

      const apiCallSignature = [
        apiMethod?.toUpperCase?.(),
        pathObject.name || apiUrl,
        responseStatus
      ]
        .filter(Boolean)
        .join(" ");

      event.context = apiCallSignature;
      event.groupingHash = apiCallSignature;
    }

    if (responseStatus) {
      event.errors[0].errorClass = "HTTP Error";
    } else {
      event.errors[0].errorClass = "Network Error";
    }

    let requestData = null;
    try {
      requestData = JSON.parse(err?.config?.data);
    } catch (e) {}

    event.addMetadata("requestInfo", {
      status: err?.response?.status,
      requestData: requestData,
      headers: err?.config?.headers,
      method: err?.config?.method,
      url: err?.config?.url,
      responseData: err?.response?.data,
      responseErrorCode: responseErrorCode,
      responseErrorMessage: err?.response?.data?.error?.message
    });
  }
};
