import React, { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import { values as lodashValues } from "lodash";

import OptionCard from "../OptionCard";
import CardGrid from "../CardGrid";
import { PackageFormatCardDetail } from "../CardDetail";

const SizeSection = ({
  blendRef,
  gift,
  handleSelect,
  format_id,
  size_id,
  countryCode
}) => {
  const { formats } = useSelector((s) => s.config.planConfigs[countryCode]);
  const options = useMemo(() => {
    if (format_id === "") return [];
    return lodashValues(formats[format_id].sizes);
  }, [format_id, formats]);

  return (
    <Fragment>
      <h3 className="gkit-h3 mt-4 mb-2">Package format</h3>
      <CardGrid
        highlightPopular
        override={{
          xs: 12,
          sm: 6,
          md: 6,
          lg: 6,
          xl: 6
        }}
      >
        {(gift
          ? options.filter((option) => option.gifts.length > 0)
          : options
        ).map(({ id, presets, metadata }) => {
          let discounted;
          if (presets[0].item.discount) {
            discounted = presets[0].item.price - presets[0].item.discount;
          }
          return (
            <OptionCard
              className="mb-2"
              active={id === size_id}
              key={id}
              onClick={() => {
                blendRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start"
                });
                handleSelect({
                  size_id: id,
                  variant_id: "",
                  product_id: "",
                  mixAndMatchPick: null
                });
              }}
            >
              <PackageFormatCardDetail
                {...metadata}
                discounted={discounted}
                gift={gift}
              />
            </OptionCard>
          );
        })}
      </CardGrid>
    </Fragment>
  );
};

export default SizeSection;
