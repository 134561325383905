import React, { useState, useMemo } from "react";
import { Form, Formik } from "formik";
import Modal from "../Modal";
import InputField from "../Formik/InputField";
import Button from "../Button";
import ShippingAddressSchema from "shared/validationSchema/ShippingAddressSchema";

import { useDispatch, useSelector } from "react-redux";
import { updateAddress } from "app/account/actions";
import {
  updateSubscriptionAddress,
  updateSubscriptionBillingAddress
} from "app/subscriptions/actions";
import ErrorHandling from "shared/ErrorHandling";

import { getCountryName } from "shared/utility";
import ProvinceSelect from "shared/Formik/ProvinceSelect";

const UpdateAddressModal = ({
  isOpen,
  address,
  handleClose,
  subscriptionId,
  isBilling = false
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [successState, setSuccessState] = useState(null);
  const { countries } = useSelector(s => s.config);

  const countryName = useMemo(
    () =>
      getCountryName({
        countryCode: address.country_code,
        countries: countries
      }),
    [address.country_code, countries]
  );

  function handleCloseAndReset() {
    handleClose();
    setTimeout(() => {
      setError(null);
      setSuccessState(null);
    }, 1000);
  }

  const modalAction = useMemo(() => {
    if (isBilling) {
      return updateSubscriptionBillingAddress;
    } else if (address.id) {
      return updateAddress;
    } else {
      return updateSubscriptionAddress;
    }
  }, [address.id, isBilling]);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleCloseAndReset}
      title={`Edit ${isBilling ? "Invoice" : "Delivery"} Address`}
      subtitle={`Tell us where you want ${
        isBilling ? "the invoice to be sent" : "your orders to be delivered"
      }`}
      successState={successState}
    >
      <Formik
        initialValues={{
          first_name: address.first_name,
          last_name: address.last_name,
          phone: address.phone || "",
          address1: address.address1,
          address2: address.address2,
          city: address.city,
          zip: address.zip,
          country_code: address.country_code
        }}
        validationSchema={ShippingAddressSchema}
        onSubmit={values => {
          const payload = {
            first_name: values.first_name,
            last_name: values.last_name,
            phone: values.phone,
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            zip: values.zip,
            country_code: values.country_code
          };
          dispatch(
            modalAction(address.id ? address.id : subscriptionId, payload)
          )
            .then(() =>
              setSuccessState({
                title: `${
                  isBilling ? "Invoice" : "Delivery"
                } address successfully updated`
              })
            )
            .catch(err => setError(err));
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form className="mt-3">
            <div className="row mb-3">
              <div className="col-12 col-md-6">
                <InputField name="first_name" type="text" label="First name" />
              </div>
              <div className="col-12 col-md-6 mt-3 mt-md-0">
                <InputField name="last_name" type="text" label="Last name" />
              </div>
            </div>
            <InputField name="phone" label="Phone number" className="mb-3" />
            <InputField
              name="address1"
              type="text"
              label="Address Line 1"
              className="mb-3"
            />
            <InputField name="address2" type="text" label="Address Line 2" />
            <div className="row mt-3">
              <div className="col-12 col-md-6">
                <InputField name="city" type="text" label="City" />
              </div>
              <div className="col-12 col-md-6 mt-3 mt-md-0">
                <InputField name="zip" type="text" label="Postcode" />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-6">
                <InputField
                  name="country_name"
                  value={countryName}
                  type="text"
                  label="Country"
                  disabled
                />
              </div>
              <div className="col-12 col-md-6 mt-3 mt-md-0">
                <ProvinceSelect countryCode={address.country_code} />
              </div>
            </div>
            <ErrorHandling error={error} />
            <div className="d-flex mt-4">
              <Button
                type="submit"
                size="md"
                className="me-3"
                isLoading={isSubmitting}
                disabled={!dirty || !isValid}
              >
                Update
              </Button>
              <Button
                size="md"
                btnType="tertiary"
                onClick={handleCloseAndReset}
                type="reset"
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default UpdateAddressModal;
