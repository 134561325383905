import { createActions } from "redux-actions";
import {
  SET_SUBSCRIPTIONS,
  CLEAR_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_SUCCESS
} from "./actionTypes";
import api, { extractAPIErrorString } from "api";
import moment from "moment";
import { handleRequiresAction, requiresAction } from "app/account/actions";

const {
  setSubscriptions,
  clearSubscriptions,
  createSubscriptionSuccess,
  updateSubscriptionSuccess
} = createActions(
  SET_SUBSCRIPTIONS,
  CLEAR_SUBSCRIPTIONS,
  CREATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_SUCCESS
);

export const getSubscriptions = () => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .get("/shop/subscriptions")
      .then(({ data }) => {
        dispatch(setSubscriptions(data.data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const reactivateSubscription = (id, payload) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`/shop/subscriptions/${id}/reactivate`, {
        next_date: moment(payload.next_date).format("YYYY-MM-DD")
      })
      .then(({ data }) => {
        dispatch(updateSubscriptionSuccess(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const resumeSubscription = (id, payload) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`/shop/subscriptions/${id}/resume`, {
        start_date: moment(payload.next_date).format("YYYY-MM-DD")
      })
      .then(({ data }) => {
        dispatch(updateSubscriptionSuccess(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const pauseSubscription = (id, payload) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`/shop/subscriptions/${id}/pause`, {
        until: moment(payload.until).format("YYYY-MM-DD")
      })
      .then(({ data }) => {
        dispatch(updateSubscriptionSuccess(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const cancelSubscription = (id, payload) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`/shop/subscriptions/${id}/cancel`, payload)
      .then(({ data }) => {
        dispatch(updateSubscriptionSuccess(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export {
  clearSubscriptions,
  updateSubscriptionSuccess,
  createSubscriptionSuccess
};

export const getSubscription = id => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .get(`/shop/subscriptions/${id}`)
      .then(({ data }) => {
        dispatch(updateSubscriptionSuccess(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const getSubscriptionSchedule = id => () => {
  return new Promise((resolve, reject) => {
    api
      .get(`/shop/subscriptions/${id}/schedule`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const skipDelivery = (id, slot, skip) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .put(`/shop/subscriptions/${id}/schedule/${slot}/skip`, { skip: skip })
      .then(() => {
        dispatch(getSubscription(id));
        resolve();
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const updateFrequency = (id, payload) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .put(`/shop/subscriptions/${id}`, payload)
      .then(({ data }) => {
        dispatch(updateSubscriptionSuccess(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const rescheduleDelivery = (id, payload, stripe) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`/shop/subscriptions/${id}/reschedule`, {
        next_date: moment(payload.next_date).format("YYYY-MM-DD")
      })
      .then(({ data }) => {
        dispatch(updateSubscriptionSuccess(data));
        resolve(data);
      })
      .catch(err => {
        if (requiresAction(err)) {
          handleRequiresAction(err.response, stripe, id).then(
            data => {
              dispatch(updateSubscriptionSuccess(data));
              resolve(data);
            },
            () => reject(extractAPIErrorString(err))
          );
        } else {
          reject(extractAPIErrorString(err));
        }
      });
  });
};

export const updateDeliveryAddress = (id, slot, payload) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .put(`/shop/subscriptions/${id}/schedule/${slot}/address`, payload)
      .then(({ data }) => {
        dispatch(updateSubscriptionSuccess(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const updateSubscriptionProducts = (id, payload) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .put(`/shop/subscriptions/${id}/products`, payload)
      .then(({ data }) => {
        dispatch(updateSubscriptionSuccess(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const updateSubscriptionPaymentMethod =
  (id, payload, stripe = null) =>
  dispatch =>
    new Promise((resolve, reject) => {
      api.put(`/shop/subscriptions/${id}/payment-method`, payload).then(
        ({ data }) => {
          dispatch(updateSubscriptionSuccess(data));
          resolve(data);
        },
        err => {
          if (requiresAction(err)) {
            handleRequiresAction(err.response, stripe, id).then(
              data => {
                dispatch(updateSubscriptionSuccess(data));
                resolve(data);
              },
              () => reject(extractAPIErrorString(err))
            );
          } else {
            reject(extractAPIErrorString(err));
          }
        }
      );
    });

export const updateSubscriptionBillingAddress = (id, payload) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .put(`/shop/subscriptions/${id}/billing-address`, payload)
      .then(({ data }) => {
        dispatch(updateSubscriptionSuccess(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const updateSubscriptionAddress = (id, payload) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .put(`/shop/subscriptions/${id}/address`, payload)
      .then(({ data }) => {
        dispatch(updateSubscriptionSuccess(data));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};
