import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { useField, useFormikContext } from "formik";

import "./StripeStyling.scss";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: "ApercuPro, sans-serif",
      fontSize: "17px"
    }
  }
};

const StripeCardField = (props) => {
  const [, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  const onChange = (evt) => {
    setFieldValue(props.name, evt.complete);
  };

  return (
    <div className="form-group">
      <label className="gkit-input-label" htmlFor="card">
        Card details
      </label>
      <CardElement
        className={`${
          meta && meta.touched && meta.error ? "is-invalid" : ""
        } gkit-input-md gkit-stripe-input`}
        id="card"
        onChange={onChange}
        options={CARD_ELEMENT_OPTIONS}
      />
      {meta && meta.touched && meta.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};

export default StripeCardField;
