import { SET_ORDER_HISTORY } from "./actionTypes";
import { handleActions } from "redux-actions";

export const initialState = {
  orders: null,
};

export default handleActions(
  {
    [SET_ORDER_HISTORY]: (state, { payload }) => {
      return {
        ...state,
        orders: payload,
      };
    },
  },
  initialState
);
