import { createActions } from "redux-actions";
import { api, extractAPIErrorString, extractAPIErrorCode } from "api";

import {
  INITIATE_AUTH,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_LOGGED_OUT,
  SET_ADMIN,
  SET_USER
} from "./actionTypes";

import { fetchPlanConfig, getPaymentProviders } from "../config/actions";
import {
  fetchReferralData,
  fetchGiftData,
  getAddresses,
  getPaymentMethods
} from "app/account/actions";
import { loadLoyalty } from "app/loyalty/actions";
import { getSubscriptions } from "app/subscriptions/actions";
import axios from "axios";
import constants from "api/constants";

const { initiateAuth, loginSuccess, logout, setLoggedOut, setAdmin } =
  createActions(
    INITIATE_AUTH,
    LOGIN_SUCCESS,
    LOGOUT,
    SET_LOGGED_OUT,
    SET_ADMIN
  );

export const { setUser } = createActions(SET_USER);

export const initiateLogin = payload => dispatch => {
  dispatch(initiateAuth(payload));
  return new Promise(resolve => {
    api
      .get("/auth/email", {
        params: payload
      })
      .then(() => {
        resolve();
      })
      .catch(err => {
        resolve(extractAPIErrorCode(err));
      });
  });
};

const multipass = data => {
  if (data.data?.multipass_token) {
    axios.get(`${constants.multipassUrl}${data.data.multipass_token}`).then(
      () => {
        console.log("Successful Multipass Login");
      },
      e => {
        console.warn("Multipass Failed", e);
      }
    );
  }
};

export const login = payload => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post("/auth/login", payload)
      .then(data => {
        if (data.status === 204) {
          dispatch(initiateAuth(payload));
          resolve();
        } else if (data.status === 201) {
          multipass(data);

          resolve(dispatch(completeLogIn(data.data.token)));
        } else {
          console.log(data);
          reject("There was an unexpect issue with the login");
        }
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const verifyLogin = payload => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post("/auth/magic-login", payload)
      .then(({ data }) => {
        multipass(data);

        resolve(dispatch(completeLogIn(data.token)));
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const forgotPassword = payload => () => {
  return new Promise((resolve, reject) => {
    api
      .post("/auth/forgot-password", payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const resetPassword = payload => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post("/auth/reset-password", payload)
      .then(({ data }) => {
        localStorage.setItem("accessToken", data.token);
        resolve(dispatch(completeLogIn(data.token)));
        resolve(data);
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const registerUser = payload => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post("/auth/register", payload)
      .then(({ data }) => {
        localStorage.setItem("accessToken", data.token);
        multipass(data);
        resolve(dispatch(completeLogIn(data.token)));
      })
      .catch(err => {
        reject(extractAPIErrorString(err));
      });
  });
};

export const submitLogout = () => dispatch => {
  dispatch(logout(null));
  localStorage.removeItem("accessToken");
  api.post("/auth/logout").then(() => {
    window.location.href = "https://grind.co.uk";
  });
};

export const checkForExistingSession = () => dispatch => {
  const accessToken = localStorage.getItem("accessToken");
  const isAdmin = Boolean(localStorage.getItem("isAdmin"));

  if (!accessToken) {
    dispatch(setLoggedOut());
    return;
  }

  dispatch(completeLogIn(accessToken, isAdmin)).catch(({ response }) => {
    if (response && response.status === 401) {
      dispatch(setLoggedOut());
      localStorage.removeItem("accessToken");
      localStorage.removeItem("isAdmin");
    }
  });
};

export const completeLogIn =
  (token, isAdmin = false) =>
  dispatch => {
    localStorage.setItem("accessToken", token);
    if (isAdmin) {
      localStorage.setItem("isAdmin", "NotAsExcitingAsYouMayThink");
    } else {
      localStorage.removeItem("isAdmin");
    }
    // TODO: Update to get the user first and load the rest dependent on
    // user fetch succeeding.
    dispatch(loginSuccess(token));

    if (isAdmin) {
      dispatch(setAdmin());
    }

    // Get plan config for subscription creation & management
    dispatch(fetchPlanConfig());
    // Get referral scheme data, user's referral code
    dispatch(fetchReferralData());
    // Check for any claimed but not redeemed gift subscriptions
    dispatch(fetchGiftData());
    // Load saved addresses for the customer
    dispatch(getAddresses());
    // Load saved payment methods for the customer
    dispatch(getPaymentMethods());
    // Load payment providers
    dispatch(getPaymentProviders());
    // Get loyalty data
    dispatch(loadLoyalty());
    // Get subscriptions
    dispatch(getSubscriptions());

    // Get the user's profile data.
    return dispatch(fetchUser());
  };

export const fetchUser = () => dispatch =>
  new Promise((resolve, reject) => {
    api.get("/account/profile").then(({ data }) => {
      dispatch(setUser(data));
      resolve(data);
    }, reject);
  });
