import React from "react";
import { Quantity } from "./SubscriptionItems";
import SparklesBadge from "./SparklesBadge";
import Button from "shared/Button";

import styles from "./SubscriptionItems.module.scss";

const GiftSubscriptionItem = ({ format, item, size, handleSwap }) => {
  return (
    <div className="rounded p-3 mb-3 gkit-bg-gray-4">
      <div className={`d-flex`}>
        <div
          className={`position-relative d-none d-sm-block me-3 ${styles.imageContainer}`}
        >
          <img src={size.product_image} className="rounded" alt={format.name} />
          <Quantity quantity={1} />
        </div>
        <div className="d-flex flex-column flex-md-row flex-grow-1">
          <div className="d-flex flex-column flex-grow-1">
            <div className="d-flex flex-column flex-md-row">
              <p className="gkit-p-20 gkit-sm-p-17 fw-bold mb-2 mt-2 mt-md-0 order-2 order-md-1">
                {format.name}
              </p>
              <SparklesBadge text="Gifted" className="order-1 order-md-2" />
            </div>
            <p className="gkit-p-15 mt-0 mb-0">{item.title}</p>
          </div>
          <div className="d-flex flex-row flex-shrink-0 justify-content-center align-items-center">
            <Button className="ms-md-3 mt-2 mt-md-0" onClick={handleSwap}>
              Swap
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftSubscriptionItem;
