import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Logo from "assets/img/grind-logo.svg";
import ProfileIcon from "assets/icons/profile.svg";
import ChevronDown from "assets/icons/chevron-down.svg";
import ChevronUp from "assets/icons/chevron-up.svg";
import LogoutIcon from "assets/icons/logout.svg";
import HamburgerMenu from "assets/icons/hamburger.svg";

import styles from "./Header.module.scss";

import { Card } from "react-rainbow-components";
import { submitLogout } from "../auth/actions";
import Hamburger from "./Hamburger";

import cx from "classnames";

const UserMenu = ({ user }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  return (
    <div
      className={`d-none d-md-flex flex-row align-items-center cursor-pointer position-relative ${styles.user}`}
      onClick={() => setShowDropdown(!showDropdown)}
    >
      <img src={ProfileIcon} alt="Profile icon" />
      <p className="gkit-p-17 m-0 mx-3">
        {user.first_name} {user.last_name}
      </p>
      {showDropdown ? (
        <img src={ChevronUp} alt="Chevron up" width={16} height={16} />
      ) : (
        <img src={ChevronDown} alt="Chevron down" width={16} height={16} />
      )}
      {showDropdown && (
        <div className={styles.logoutDropdown}>
          <Card>
            <div
              className="d-flex m-3"
              onClick={() => dispatch(submitLogout())}
            >
              <img src={LogoutIcon} alt="Logout" width={24} height={24} />
              <p className="gkit-p-17 ms-2">Logout</p>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

const Header = () => {
  const { user, isAdmin, isLoggedIn } = useSelector(s => s.auth);
  const [hamburger, setHamburger] = useState(false);
  return (
    <nav
      className={cx(
        "header position-fixed w-100",
        styles.header,
        isLoggedIn && styles.pink
      )}
    >
      <div className="container h-100 d-flex align-items-center justify-content-center justify-content-md-between position-relative">
        {isLoggedIn && (
          <img
            src={HamburgerMenu}
            alt="Hamburger menu"
            className={`d-block d-md-none position-absolute ${styles.hamburger}`}
            width={32}
            height={32}
            onClick={() => setHamburger(true)}
          />
        )}
        <a href="https://grind.co.uk/">
          <img className="logo" src={Logo} alt="Grind Logo" />
        </a>
        {isAdmin && (
          <span className="gkit-badge badge gkit-pink ml-3">admin</span>
        )}
        {isLoggedIn && user && <UserMenu user={user} />}
        <Hamburger isOpen={hamburger} setIsOpen={setHamburger} user={user} />
      </div>
    </nav>
  );
};

export default Header;
