import React, { useState } from "react";
import Modal from "../Modal";
import { useDispatch } from "react-redux";
import Button from "../Button";
import {
  clearSubscriptions,
  skipDelivery,
  getSubscription
} from "app/subscriptions/actions";

import moment from "moment";

import ErrorHandling from "shared/ErrorHandling";

const UnskipDeliveryModal = ({
  isOpen,
  subscriptionId,
  deliveryId,
  handleClose,
  updateSchedule
}) => {
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [successState, setSuccessState] = useState(null);

  function handleCloseAndReset() {
    handleClose();
    setTimeout(() => {
      setError(null);
      setSuccessState(null);
    }, 1000);
  }

  function handleUnskip() {
    setSubmitting(true);
    new Promise(async resolve => {
      try {
        await dispatch(skipDelivery(subscriptionId, deliveryId, false)).then(
          () => {
            dispatch(getSubscription(subscriptionId)).then(data =>
              setSuccessState({
                title: "Selected delivery unskipped",
                subtitle: `Delivery for ${moment(
                  data.next_dispatch.order_date,
                  "YYYY-MM-DD"
                ).format("DD/MM/YYYY")} was successfully unskipped`
              })
            );
            updateSchedule();
            dispatch(clearSubscriptions());
            resolve();
          }
        );
      } catch (e) {
        setError(e);
      }
    }).finally(() => setSubmitting(false));
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleCloseAndReset}
      title="Restore your delivery"
      subtitle={`Do you want to unskip this delivery?`}
      successState={successState}
    >
      <ErrorHandling error={error} />
      <div className="mt-4">
        <Button
          className="me-3"
          onClick={handleUnskip}
          isLoading={isSubmitting}
        >
          Yes, restore this delivery
        </Button>
        <Button
          btnType="tertiary"
          onClick={handleCloseAndReset}
          className="mt-2 mt-md-0"
        >
          No, keep it as is
        </Button>
      </div>
    </Modal>
  );
};

export default UnskipDeliveryModal;
