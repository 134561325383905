import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import InitiateLogin from "./InitiateLogin";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordSent from "./ForgotPasswordSent";
import ResetPassword from "./ResetPassword";
import VerifyLogin from "./VerifyLogin";
import Register from "./Register";

const unAuthenticatedRoutes = [
  { exact: true, path: "/auth/forgot-password", component: ForgotPassword },
  {
    exact: true,
    path: "/auth/forgot-password-sent",
    component: ForgotPasswordSent
  },
  {
    exact: false,
    path: "/reset-password",
    component: ResetPassword
  },
  { exact: true, path: "/auth/register", component: Register },
  { exact: true, path: "/auth/verify-login", component: VerifyLogin },
  { exact: true, path: "/auth/login", component: Login },
  { exact: true, path: "/auth/passwordless", component: InitiateLogin },
  { exact: true, path: "/", component: Login },
  { exact: false, path: "/", redirect: "/" }
];

const Router = () => {
  return (
    <Switch>
      {unAuthenticatedRoutes.map(({ redirect, ...props }, index) =>
        redirect ? (
          <Route key={index} {...props}>
            <Redirect to={redirect} />
          </Route>
        ) : (
          <Route key={index} {...props} />
        )
      )}
    </Switch>
  );
};

export default Router;
