import React, { useMemo } from "react";
import moment from "moment";

const Greeting = ({ firstName }) => {
  const now = moment();
  const availableMessages = greetings.filter(greeting =>
    greeting.from > greeting.to
      ? now.hour() >= greeting.from || now.hour() < greeting.to
      : now.hour() >= greeting.from && now.hour() < greeting.to
  );

  const message = useMemo(
    () =>
      availableMessages[Math.floor(Math.random() * availableMessages.length)]
        .message,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="pt-3 pb-4 d-none d-lg-block mb-3">
      <h2 className="headline-40">
        {message.replace("{{firstName}}", firstName)}
      </h2>
    </div>
  );
};

export default Greeting;

const greetings = [
  {
    message: "Yawn, Hi {{firstName}}. 🌞",
    from: 4,
    to: 8
  },
  {
    message: "Still snoozing, ZzZzZz.",
    from: 4,
    to: 8
  },
  {
    message: "You're such an early bird, {{firstName}}.",
    from: 4,
    to: 8
  },
  {
    message: "It's way too early, {{firstName}}!",
    from: 4,
    to: 8
  },
  {
    message: "Good morning {{firstName}}.",
    from: 8,
    to: 12
  },
  {
    message: "Hey there, {{firstName}}. 👋",
    from: 8,
    to: 12
  },
  {
    message: "Quick coffee stop.",
    from: 8,
    to: 12
  },
  {
    message: "Fancy seeing you here {{firstName}}.",
    from: 12,
    to: 2
  },
  {
    message: "G'Day, {{firstName}}. 🐨",
    from: 12,
    to: 18
  },
  {
    message: "Good afternoon, {{firstName}}",
    from: 12,
    to: 18
  },
  {
    message: "See you at the Grind.",
    from: 12,
    to: 18
  },
  {
    message: "Cup of tea, {{firstName}}?",
    from: 18,
    to: 23
  },
  {
    message: "You're up late {{firstName}}.",
    from: 23,
    to: 4
  },
  {
    message: "Night Owl? 🦉",
    from: 23,
    to: 4
  },
  {
    message: "Deadlines to meet, coffee to drink.",
    from: 23,
    to: 4
  }
];
