import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { forgotPassword } from "./actions";
import { Redirect } from "react-router-dom";
import ErrorHandling from "shared/ErrorHandling";
import InputFieldLine from "shared/Formik/InputFieldLine";
import ButtonPrimary from "shared/ButtonPrimary";
import ButtonSecondary from "shared/ButtonSecondary";

import styles from "./ForgotPassword.module.scss";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [redirect, setRedirect] = useState();

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: redirect
        }}
      />
    );
  }

  return (
    <div className="pb-4">
      <h1 className="gkit-h1">Forgot your password?</h1>
      <p className="gkit-p-17">
        Enter your email address to reset your password.
      </p>
      <Formik
        initialValues={{
          email: ""
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Invalid email format")
            .required("Email address is required")
        })}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(forgotPassword(values)).then(
            () => setRedirect("/auth/forgot-password-sent"),
            err => {
              setError(err);
              setSubmitting(false);
            }
          );
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form className="mt-4">
            <InputFieldLine
              name="email"
              type="email"
              placeholder="Enter email address *"
            />
            <ErrorHandling error={error} />
            <div className={styles.buttonContainer}>
              <ButtonPrimary
                className="d-block w-100"
                type="submit"
                isLoading={isSubmitting}
                disabled={!dirty || !isValid}
              >
                Continue
              </ButtonPrimary>
              <ButtonSecondary className="mt-3 d-block w-100" to="/">
                Back to login
              </ButtonSecondary>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
